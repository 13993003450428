import PencilIcon from 'components/atoms/icons/PencilIcon';
import { EditRealEstateList } from 'components/molecules/RetirementMapModal/EditRealEstateList';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';

export const RealEstateList = ({
  patrimonies = {},
  reloadPatrimonies = () => {},
}) => {
  const getClassByStatus = status => {
    const statusClasses = {
      VENDER: 'red',
      MANTER: 'blue-lilac',
      ALUGAR: 'blue-midnight',
    };

    return statusClasses[status] || '';
  };

  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  return (
    <Card.Container className="spending-table no-footer">
      <Table.TitleTotal
        nameTable="Relação dos Imóveis"
        className=" patrimony font-regular "
        value={patrimonies.overview.total.formatted}
        percentage={patrimonies.overview.progress.formatted}
      />
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead className="retirement">
            <Table.Tr className="receipt-table-title receipts">
              {patrimonies.rentPatrimoniesHeader.map((item, index) => (
                <Table.Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border checkbox'}`}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {patrimonies.rentPatrimonies.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                <Table.Td
                  className={`receipt-table-body left`}
                >
                  {column.name}
                </Table.Td>
                <Table.Td className={`receipt-table-body`}>
                  {column.value.formatted}
                </Table.Td>
                <Table.Td className={`receipt-table-body`}>
                  {column.rent.formatted}
                </Table.Td>
                <Table.Td className={`receipt-table-body`}>
                  {column.monthly_expenses.formatted}
                </Table.Td>
                <Table.Td className={`receipt-table-body`}>
                  {column.net_revenue.formatted}
                </Table.Td>
                <Table.Td className={`receipt-table-body`}>
                  {column.profitability.formatted}
                </Table.Td>
                <Table.Td
                  className={`receipt-table-body ${getClassByStatus(
                    column.recommendation
                  )}`}
                >
                  {column.recommendation}
                </Table.Td>
                <Table.Td>
                  <PencilIcon
                    className="category-title"
                    onClick={() => {
                      setShowEdit(true);
                      setSelectedItem(column);
                    }}
                  />
                  {showEdit && (
                    <EditRealEstateList
                      showModal={showEdit}
                      setShowModal={setShowEdit}
                      selectedItem={selectedItem}
                      theme="edit"
                      reloadPatrimonies={reloadPatrimonies}
                    />
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
    </Card.Container>
  );
};
