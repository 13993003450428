import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: 27px;
        height: 27px;
    }
`;

export const Svg = styled.svg`
    &.asset-management {
        width: 27px;
        height: 27px;

        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${({ theme }) => theme.white};
            }
        }
    }
`;