import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
export const EditInvestmentModal = ({
  showModal = false,
  setShowModal = () => { },
  onCancel = () => { },
  onExclude = () => { },
  title,
  data,
  handleUpdate = () => { },
}) => {
  const [value, setValue] = useState(data?.value || 0);
  const [date, setDate] = useState(data?.date || '');

  return (
    <EditModal
      id={data?.id}
      value={value}
      setValue={setValue}
      date={date}
      setDate={setDate}
      showModal={showModal}
      setShowModal={setShowModal}
      onCancel={onCancel}
      onExclude={onExclude}
      onConfirm={handleUpdate}
    />
  );
};

const EditModal = ({
  showModal,
  setShowModal,
  date,
  setDate,
  value,
  setValue,
  onConfirm,
  id,
  onExclude,
}) => {
  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="extract-modal no-overflow"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Parcelado"
          onClose={() => setShowModal(false)}
          type="edit"
          className="extract-modal"
          theme="edit"
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer className="extract-modal">
        <Modal.InputCurrency
          className="extract-modal"
          label="Valor da Parcela"
          getInputValue={setValue}
          defaultValue={value}
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker full-width after"
          label="Data de início"
          getInputValue={setDate}
          defaultValue={date}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={() =>
          onConfirm(id, 'investments', setShowModal, {
            id,
            value,
            date_start: date,
          })
        }
        onExclude={() => onExclude(id)}
        className="extract-modal"
        cancelButtonText="Cancelar"
        confirmButtonText="Editar"
        buttonOrder={['exclude', 'confirm', 'cancel']}
      />
    </Modal.Container>
  );
};
