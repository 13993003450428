import React from 'react';
import { Modal } from 'components/templates/Modal';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const LoadingNewRealease = () => {
  return (
    <Modal.BodyContainer className="new-realease">
      {[...Array(4)].map((_, index) => (
        <GenericLoading key={index} className="new-realease" />
      ))}
    </Modal.BodyContainer>
  );
};
