import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 25px;
  height: 30px;


  &.selected path {
    fill: ${({ theme }) => theme.white};
  }
`;
