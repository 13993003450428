import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: max-content;
    background-color: ${props => props.theme.white};
    margin-bottom: 43px;
    padding-top: 45px;
    padding-left: 43px;
    padding-bottom: 45px;
    border-radius: 16px;
`;

export const GraphContainer = styled.div`
    margin-top: 32px;
    display: flex;
    gap: 32px;
    overflow: auto;
`;