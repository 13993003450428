import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getEmpytRetirementOverview } from 'utils/retirement';

const RetirementOverviewContext = createContext(null);

export const RetirementOverviewProvider = ({ children }) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [overview, setOverview] = useState(getEmpytRetirementOverview());
  const [loading, setLoading] = useState(true);

  const fetchItems = useCallback(async () => {
    setLoading(true);
    try {
      const { data: items } = await api.get(`retirement/overview/${userId}`);
      setOverview(items);
    } catch (error) {
      console.error('Error fetching retirement:', error);
      toast.error('Ocorreu um erro ao buscar overview.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const reloadRetirementOverview = useCallback(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <RetirementOverviewContext.Provider
      value={{
        overview,
        setOverview,
        loading,
        setLoading,
        reloadRetirementOverview,
      }}
    >
      {children}
    </RetirementOverviewContext.Provider>
  );
};

export const useRetirementOverview = () => {
  const context = useContext(RetirementOverviewContext);
  if (!context) {
    throw new Error(
      'useRetirementOverview deve ser usado dentro de um RetirementOverviewProvider'
    );
  }
  return context;
};
