import React from 'react';
import { StyledIcon } from './styles';

function ClipIcon({ onClick, className }) {
  return (
    <StyledIcon
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1 5.167v6.666c0 1.105.474 2.165 1.318 2.947C3.162 15.56 4.307 16 5.5 16c1.193 0 2.338-.439 3.182-1.22.844-.782 1.318-1.842 1.318-2.947V3.917c0-.774-.332-1.516-.923-2.063A3.282 3.282 0 006.85 1c-.835 0-1.637.307-2.227.854A2.812 2.812 0 003.7 3.917V11c0 .442.19.866.527 1.178.338.313.796.489 1.273.489.477 0 .935-.176 1.273-.489.337-.312.527-.736.527-1.178V5.167"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  );
}

export default ClipIcon;
