import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 355px;
  height: 40px;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const GroupFile = styled.div`
  display: flex;
  align-items: center;
  /* background-color: pink; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 10px
`;
