import React from 'react';
import Switch from 'components/atoms/Switch';

export const TableSwitch = ({ checked, onClick, getValue, ...props }) => {
  return (
    <Switch
      checked={checked}
      onClick={onClick}
      getValue={getValue}
      {...props}
    />
  );
};
