import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import InputMask from 'react-input-mask';

export const ModalInputMask = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = '',
  disabled = false,
  mask = '',
}) => {
  const [inputValue, setInputValue] = React.useState(defaultValue);

  React.useEffect(() => {
    getInputValue(inputValue);
  }, [getInputValue, inputValue]);

  const removeMask = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleChange = (e) => {
    const rawValue = removeMask(e.target.value);
    setInputValue(rawValue);
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      {mask === 'CNPJ' && (
        <InputMask
          mask="99.999.999/9999-99"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          className={`${className} ${disabled ? 'disabled' : ''}`}
          maskChar={null}
          alwaysShowMask={false}
        >
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>

      )}
    </Label>
  );
};