import { AllocationCard } from 'components/molecules/AllocationCard';
import React from 'react';
import { GraphSection } from './styles';
import { InvestmentPanel } from 'components/molecules/InvestmentPanel';

export const FinancialAssets = ({
    financialAssets,
    allocationReport,
    loading = true
}) => {

    return (
        <div>
            <InvestmentPanel data={financialAssets} loading={loading} />
            <GraphSection>
                <AllocationCard
                    titleCard='Alocação atual'
                    data={allocationReport?.current_values}
                    loading={loading}
                />
                <AllocationCard
                    titleCard='Alocação recomendada'
                    loading={loading}
                    data={allocationReport?.allocation_recommendation}
                />
            </GraphSection>
        </div>
    )
}
