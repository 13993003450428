import { EditSocialSecurity } from 'components/molecules/RetirementMapModal/EditSocialSecurity';
import { UploadSocialSecurityFile } from 'components/molecules/RetirementMapModal/UploadSocialSecurityFile';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

export const SocialSecurity = ({ items, reloadSocialSecurity }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleEditClick = index => {
    setShowEdit(true);
    const completeItem = items.socialSecurity[index];
    setSelectedItem(completeItem);
  };

  const handleFileUpload = index => {
    const completeItem = items.socialSecurity[index];
    if (completeItem?.id === -1) {
      toast.error('Cadastre a previdência primeiro');
      return;
    }
    setShowUploadModal(true);
    setSelectedItem(completeItem);
  };
  return (
    <Card.Container className="card-table no-footer no-margin-top">
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead className="retirement">
            <Table.Tr className="receipt-table-title receipts">
              {items.header.map((item, index) => {
                const isFirst = index === 0;

                const editContent = (
                  <Table.ContentIcon
                    item={item}
                    type="pencil-clip"
                    onEdit={() => handleEditClick(index)}
                    onClip={() => handleFileUpload(index)}
                  />
                );

                const content = isFirst ? item : editContent;
                return (
                  <Table.Th
                    className={`budget-table-title`}
                    first={isFirst}
                    key={index}
                  >
                    {content}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Object.keys(items.socialSecurity[0]).map((colKey, colIndex) => (
              <Table.Tr className="receipt-table-body" key={colIndex}>
                {items.header?.map((_, rowIndex) => (
                  <Table.Td
                    className={`receipt-table-body ${rowIndex === 0 &&
                      'first-default'}`}
                    key={rowIndex}
                  >
                    {items.socialSecurity[rowIndex][colKey]?.formatted ||
                      items.socialSecurity[rowIndex][colKey]}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>

      {showEdit && (
        <EditSocialSecurity
          reloadSocialSecurity={reloadSocialSecurity}
          showModal={showEdit}
          setShowModal={setShowEdit}
          selectedItem={selectedItem}
          theme="edit"
        />
      )}

      {showUploadModal && (
        <UploadSocialSecurityFile
          showModal={showUploadModal}
          setShowModal={setShowUploadModal}
          socialSecurityId={selectedItem.id}
        />
      )}
    </Card.Container>
  );
};
