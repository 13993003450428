import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { pensionActions, getPensionActionByLabel } from 'enums/privatePension';
import {
  useUpdatePrivatePension,
  usePrivatePensions,
} from 'hooks/usePrivatePensions';
export const EditRecom = ({
  showModal = false,
  setShowModal = () => {},
  privatePension = null,
  onCancel = () => {},
  theme = 'receipts',
}) => {
  const { updatePrivatePension } = useUpdatePrivatePension(
    privatePension?.private_pension_id
  );
  const [recommendation, setRecommendation] = useState(
    getPensionActionByLabel(privatePension?.recommendation)
  );
  const { reloadPrivatePensions } = usePrivatePensions();
  const confirm = async () => {
    await updatePrivatePension(
      privatePension?.private_pension_id,
      { recommendation: recommendation.value },
      setShowModal
    );
    reloadPrivatePensions();
  };
  const cancel = () => {
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Recomendação"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.Dropdown
          className="generic-dream-modal "
          selectOptions={pensionActions}
          label={'Recomendação'}
          setDropdownValue={setRecommendation}
          placeholderSelect={recommendation?.label}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        buttonOrder={['cancel', 'confirm']}
      />
    </Modal.Container>
  );
};
