import styled from "styled-components";

export const Container = styled.div`
    &.asset-management-doughnut-grafic-description {
        height: min-content;
    }
`;

export const Svg = styled.svg`
    &.asset-management-doughnut-grafic-description {
        width: 10px;
        height: 5px;
        
        path {
            fill: ${({ theme }) => theme.downIcon};
        }

        &.smaller {
            path {
                fill: ${({ theme }) => theme.redMedium};
            }
        }
    }
`;