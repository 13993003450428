import React, { useMemo } from 'react';
import { HorizontalBarChart } from 'components/atoms/HorizontalBarChart';
import { H4 } from 'components/atoms/Headings/H4';
import { GenericLoading } from 'components/atoms/GenericLoading';
import light from 'styles/themes/light';
import { Container, GraphContainer } from './styles';

export const ImmediateLiquidity = ({
  data,
  loading = true
}) => {
  const colors = [light.blueSky, light.blueRoyal];
  const labels = [
    'DO',
    'Até 30 dias',
    'De 1 à 6 meses',
    'De 6 à 12 meses',
    'De 12 à 24 meses',
    'Acima de 24 meses',
  ];

  const datasets = useMemo(() => {
    const getDataInOrder = (data) => {
      return labels.map(label => {
        const item = data.find(item => item.name === label);
        return item ? item.percentage.raw : 0;
      });
    };

    return [
      {
        label: 'Liquidez Atual',
        data: getDataInOrder(data.current_equity_liquidity),
        backgroundColor: colors[0],
        borderColor: colors[0],
        borderWidth: 1,
        barThickness: 20,
      },
      {
        label: 'Necessidade de Liquidez',
        data: getDataInOrder(data.need_for_equity_liquidity),
        backgroundColor: colors[1],
        borderColor: colors[1],
        borderWidth: 1,
        barThickness: 20,
      }
    ];
  }, [data, colors, labels]);

  const chartData = useMemo(() => ({
    labels,
    datasets,
  }), [datasets, labels]);

  const options = useMemo(() => ({
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        padding: 10,
      },
    },
    cornerRadius: 2,
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          return `${datasetLabel}: ${tooltipItem.xLabel}%`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: true,
          },
        },
      ],
      yAxes: [
        {
          barPercentage: 0.7,
          categoryPercentage: 0.8,
          gridLines: {
            lineWidth: 0,
          },
          ticks: {
            padding: 10,
            minRotation: 0,
            autoSkip: true,
            maxTicksLimit: 6,
          },
        },
      ],
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  }), []);

  if (loading) {
    return (
      <div>
        <GenericLoading className="immediate-liquidity" />
      </div>
    );
  }

  return (
    <Container>
      <H4 className="immediate-liquidity-title">
        Tabela de Liquidez Patrimonial
      </H4>
      <GraphContainer>
        <HorizontalBarChart
          className="immediate-liquidity"
          data={chartData}
          options={options}
          width={1522}
          height={677}
        />
      </GraphContainer>
    </Container>
  );
};
