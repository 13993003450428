export const getEmpytPrivatePensions = () => {
  return [
    {
      firstColumn: {
        header: 'Previdência',
        body: {
          fund_name: 'Nome do Fundo',
          cnpj: 'CNPJ',
          current_amount: 'Montante Atual',
          monthly_contribution: 'Aporte Mensal',
          cop: 'COP',
          pension_type: 'Tipo de Previdência',
          ir_benefit: 'Benefício IR',
          monthly_return: 'Rentabilidade Mensal',
        },
      },
      secondColumn: {
        header: 'Plano Atual',
        private_pension_id: -1,
        body: {
          fund_name: 'A definir',
          cnpj: {
            formatted: '00.000.000/0000-00',
            raw: '00000000000000',
          },
          current_amount: {
            raw: 0,
            formatted: 'R$ 0',
          },
          monthly_contribution: {
            raw: 0,
            formatted: 'R$ 0',
          },
          cop: {
            formatted: 'Não',
            raw: false,
          },
          pension_type: {
            formatted: 'Previdência Aberta (PGBL)',
            raw: 'open_pension_pgbl',
          },
          ir_benefit: {
            raw: 0,
            formatted: 'R$ 0',
          },
          monthly_return: {
            raw: 0,
            formatted: 'R$ 0',
          },
        },
      },
      thirdColumn: {
        header: 'Recomendação',
        private_pension_id: -1,
        body: {
          recommendation: 'A definir',
        },
      },
    },
    {
      firstColumn: {
        header: 'Previdência',
        body: {
          fund_name: 'Nome do Fundo',
          cnpj: 'CNPJ',
          current_amount: 'Montante Atual',
          monthly_contribution: 'Aporte Mensal',
          cop: 'COP',
          pension_type: 'Tipo de Previdência',
          ir_benefit: 'Benefício IR',
          monthly_return: 'Rentabilidade Mensal',
        },
      },
      secondColumn: {
        header: 'Plano Atual',
        private_pension_id: -1,
        body: {
          fund_name: 'A definir',
          cnpj: {
            formatted: '00.000.000/0000-00',
            raw: '00000000000000',
          },
          current_amount: {
            raw: 0,
            formatted: 'R$ 0',
          },
          monthly_contribution: {
            raw: 0,
            formatted: 'R$ 0',
          },
          cop: {
            formatted: 'Não',
            raw: false,
          },
          pension_type: {
            formatted: 'Previdência Aberta (PGBL)',
            raw: 'open_pension_pgbl',
          },
          ir_benefit: {
            raw: 0,
            formatted: 'R$ 0',
          },
          monthly_return: {
            raw: 0,
            formatted: 'R$ 0',
          },
        },
      },
      thirdColumn: {
        header: 'Recomendação',
        private_pension_id: -1,
        body: {
          recommendation: 'A definir',
        },
      },
    },
  ];
};
