import React, { useState } from 'react';
import { DreamCard } from 'components/templates/DreamCard';
import { FinancialIndependenceModal } from 'components/molecules/FinancialIndependenceModal';
import { CardWrapper } from './styles';
import { getImageGoals } from 'utils/goals';

export const FinancialIndependenceGoal = ({
  dreamProps,
  onToggleDreamStatus,
  onUpdateGoal,
  uploadFile,
  onCreateGoal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const realized = dreamProps?.done_at;

  const editModal = () => {
    setShowModal(prev => !prev);
  };

  const handleToggleDreamStatus = () => {
    onToggleDreamStatus(dreamProps?.id, dreamProps?.done_at);
  };

  const backGroundImage =
    dreamProps?.url || getImageGoals('financial-independence') || '';

  const body = {
    realized: (
      <DreamCard.RootContainer
        className="goal-panel realized-generic-dream"
        backgroundImage={backGroundImage}
      >
        <DreamCard.Icon
          icon="round-verified"
          className="realized-generic-dream"
        />
        <DreamCard.Text
          text={dreamProps?.name}
          className="goals-panel generic-dream-realized"
        />
        <DreamCard.GroupItens className="on-center">
          <DreamCard.Icon
            icon="pencil"
            className="financial-independence"
            onClick={editModal}
          />
          <DreamCard.Icon
            icon="verified"
            className="realized-dream"
            onClick={handleToggleDreamStatus}
          />
        </DreamCard.GroupItens>
      </DreamCard.RootContainer>
    ),
    unrealized: (
      <DreamCard.RootContainer
        className="goal-panel financial-independence"
        backgroundImage={backGroundImage}
      >
        <CardWrapper>
          <DreamCard.GroupItens className="spaced">
            <DreamCard.Text
              text={dreamProps?.name || 'Independência Financeira '}
              className="generic-dream-title mb-32"
            />
            <DreamCard.GroupItens>
              <DreamCard.Icon
                icon="pencil"
                className="financial-independence"
                onClick={editModal}
              />
              <DreamCard.Icon
                icon="verified"
                className="financial-independence"
                onClick={handleToggleDreamStatus}
              />
            </DreamCard.GroupItens>
          </DreamCard.GroupItens>
          <DreamCard.Text
            text={dreamProps?.when?.formatted || '0 meses'}
            className="generic-dream-mim time-to"
          />
          <DreamCard.GroupItens className="spaced">
            <DreamCard.Text text="M.I.M" className="generic-dream-mim mim" />
            <DreamCard.Text
              text={dreamProps.ifp.formatted}
              className="generic-dream-mim mim"
            />
          </DreamCard.GroupItens>
          <DreamCard.GroupItens className="column">
            <DreamCard.Text
              text="Base de Plena Liquidez (B.P.L.)"
              className="financial-independence-bpl"
            />
            <DreamCard.GroupItens className="spaced">
              <DreamCard.Text
                text={
                  dreamProps?.financial_active_value_bpl.formatted || 'R$ 0'
                }
                className="generic-dream-progress-information first"
              />
              <DreamCard.Text
                text={dreamProps?.bpl.formatted || 'R$ 0'}
                className="generic-dream-progress-information first"
              />
            </DreamCard.GroupItens>
            <DreamCard.ProgressBar
              className="goals-panel dream-panel"
              progress={dreamProps?.bpl_percentage_completed.raw || 0}
            />
            <DreamCard.GroupItens className="spaced">
              <DreamCard.Text
                text="Total investido"
                className="financial-independence-total-invested first"
              />
              <DreamCard.Text
                text={dreamProps?.bpl_percentage_completed.formatted || 'R$ 0'}
                className="generic-dream-progress-information first"
              />
            </DreamCard.GroupItens>
          </DreamCard.GroupItens>
          <DreamCard.GroupItens className="column">
            <DreamCard.Text
              text="Independência Financeira Total"
              className="financial-independence-bpl"
            />
            <DreamCard.GroupItens className="spaced">
              <DreamCard.Text
                text={dreamProps?.current_value.formatted || 'R$ 0'}
                className="generic-dream-progress-information first"
              />
              <DreamCard.Text
                text={dreamProps?.financial_freedom_assets.formatted || 'R$ 0'}
                className="generic-dream-progress-information first"
              />
            </DreamCard.GroupItens>
            <DreamCard.ProgressBar
              className="goals-panel dream-panel"
              progress={dreamProps?.total_invested_percentage.raw || 0}
            />
            <DreamCard.GroupItens className="spaced">
              <DreamCard.Text
                text="Total investido"
                className="financial-independence-total-invested"
              />
              <DreamCard.Text
                text={dreamProps?.total_invested_percentage.formatted || 'R$ 0'}
                className="generic-dream-progress-information first"
              />
            </DreamCard.GroupItens>
          </DreamCard.GroupItens>
        </CardWrapper>
      </DreamCard.RootContainer>
    ),
  };

  return (
    <>
      {showModal && (
        <FinancialIndependenceModal
          setShowModal={setShowModal}
          showModal={showModal}
          dreamProps={dreamProps}
          updateGoal={onUpdateGoal}
          uploadFile={uploadFile}
          onCreateGoal={onCreateGoal}
        />
      )}
      {body[realized ? 'realized' : 'unrealized']}
    </>
  );
};

export default FinancialIndependenceGoal;
