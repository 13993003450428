import styled from "styled-components";
import { NavigationCardBasicStyle } from "styles/components/NavigationCardBasicStyle";
import { CardAnimationIncreasing } from 'styles/animations/CardAnimationIncreasing';

export const RootContainer = styled.div`
    &.goal-panel,
    &.asset-management,
    &.asset-management-liquidity {
        ${NavigationCardBasicStyle}

        &.sealed {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid ${({ theme }) => theme.blueRoyal};
        }
    }

    &.asset-management {
        &:hover {
            background-color: ${({ theme }) => theme.blueRoyal};

            span, h5, h3, path, text {
                color: ${({ theme }) => theme.white};
                fill: ${({ theme }) => theme.white};
            }
        }
        &.hovered {
            background-color: ${({ theme }) => theme.blueRoyal};
            
            &:hover {
                ${CardAnimationIncreasing}

                span {
                    color: ${({ theme }) => theme.white};
                }
            }
        }
    }
`;

export const GroupItens = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.goal-panel {
        height: 36px;
    }
    
    &.patrimony-navigation-cards {
        flex-direction: column;
        align-items: start;
        height: max-content;
    }

    &.asset-management {
        width: 100%;
        height: 108px;
        justify-content: left;
        gap: 44px;
        margin-top: 20px;
    }

    &.asset-management-container {
        height: 108px;
        flex-direction: column;
    }

    &.asset-management-container-liquity {
        width: min-content;
        height: max-content;
        display: flex;
        align-items: center;
        padding: 1px 2.5px;   
    }
`;

export const AmountOfMoneyContainer = styled.div`

`;