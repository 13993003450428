import * as Yup from 'yup';

export const receiptSchema = Yup.object().shape({
  selectCategory: Yup.mixed()
    .test('is-array-or-object', 'Classificação é obrigatória', value => {
      if (!value) return false;
      return Array.isArray(value) || typeof value === 'object';
    })
    .transform(value => (value && !Array.isArray(value) ? [value] : value))
    .required('Classificação é obrigatória'),

  selectItem: Yup.mixed()
    .test('is-array-or-object', 'Nome do item é obrigatório', value => {
      if (!value) return false;
      return Array.isArray(value) || typeof value === 'object';
    })
    .transform(value => (value && !Array.isArray(value) ? [value] : value))
    .required('Nome do item é obrigatório'),

  value: Yup.number()
    .typeError('O valor deve ser um número')
    .min(0.1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),

  startDate: Yup.string()
    .nullable()
    .required('Data de início é obrigatória'),

  description: Yup.string()
    .trim()
    .nullable()
    .required('Descrição é obrigatória')
    .test('valid-description', 'Descrição inválida', value => {
      return !!value && /\w/.test(value);
    }),

  recurrent: Yup.boolean(),

  endDate: Yup.string()
    .nullable()
    .when('recurrent', {
      is: true,
      then: schema =>
        schema
          .required('Data de término é obrigatória quando recorrente')
          .test(
            'endDate-after-startDate',
            'Data de término deve ser maior que a data de início',
            function(value) {
              const { startDate } = this.parent;
              return (
                !startDate || !value || new Date(value) >= new Date(startDate)
              );
            }
          ),
    }),
});
export const spendingSchema = Yup.object().shape({
  selectCategory: Yup.mixed()
    .test('is-array-or-object', 'Classificação é obrigatória', value => {
      if (!value) return false;
      return Array.isArray(value) || typeof value === 'object';
    })
    .transform(value => (value && !Array.isArray(value) ? [value] : value))
    .required('Classificação é obrigatória'),

  selectItem: Yup.mixed()
    .test('is-array-or-object', 'Nome do item é obrigatório', value => {
      if (!value) return false;
      return Array.isArray(value) || typeof value === 'object';
    })
    .transform(value => (value && !Array.isArray(value) ? [value] : value))
    .required('Nome do item é obrigatório'),

  value: Yup.number()
    .typeError('O valor deve ser um número')
    .min(0.1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),

  startDate: Yup.string()
    .nullable()
    .required('Data de início é obrigatória'),

  description: Yup.string()
    .trim()
    .nullable()
    .required('Descrição é obrigatória')
    .test('valid-description', 'Descrição inválida', value => {
      return !!value && /\w/.test(value);
    }),

  recurrent: Yup.boolean(),

  endDate: Yup.string()
    .nullable()
    .when('recurrent', {
      is: true,
      then: schema =>
        schema
          .required('Data de término é obrigatória quando recorrente')
          .test(
            'endDate-after-startDate',
            'Data de término deve ser maior que a data de início',
            function(value) {
              const { startDate } = this.parent;
              return (
                !startDate || !value || new Date(value) >= new Date(startDate)
              );
            }
          ),
    }),
});

export const debtsSchema = Yup.object().shape({
  selectCategory: Yup.mixed()
    .test('is-array-or-object', 'Classificação é obrigatória', value => {
      if (!value) return false;
      return Array.isArray(value) || typeof value === 'object';
    })
    .transform(value => (value && !Array.isArray(value) ? [value] : value))
    .required('Classificação é obrigatória'),

  selectItem: Yup.mixed()
    .test('is-array-or-object', 'Nome do item é obrigatório', value => {
      if (!value) return false;
      return Array.isArray(value) || typeof value === 'object';
    })
    .transform(value => (value && !Array.isArray(value) ? [value] : value))
    .required('Nome do item é obrigatório'),

  value: Yup.number()
    .typeError('O valor deve ser um número')
    .min(0.1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  startDate: Yup.string()
    .nullable()
    .required('Data de início é obrigatória'),
  totalInstallment: Yup.number()
    .typeError('Parcelamento deve ser um número')
    .min(1, 'O parcelamento deve ser maior que zero')
    .required('Parcelamento é obrigatório'),
  description: Yup.string()
    .trim()
    .nullable()
    .required('Descrição é obrigatória')
    .test('valid-description', 'Descrição inválida', value => {
      return !!value && /\w/.test(value);
    }),
});

export const investmentSchema = Yup.object().shape({
  value: Yup.number()
    .typeError('O valor deve ser um número')
    .min(0.1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  startDate: Yup.string()
    .nullable()
    .required('Data é obrigatória'),
});

export const createInstallmentsSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  value: Yup.number()
    .typeError('O valor deve ser um número')
    .required('Valor é obrigatório'),
  item_id: Yup.number().required('É obrigatório selecionar um item!'),
  category_id: Yup.number().required('É obrigatório escolher uma categoria!'),
  currentInstallment: Yup.number()
    .required('Insira qual a parcela atual!')
    .test(
      'is-valid-installment',
      'A parcela atual deve ser maior que 0 e menor ou igual ao total de parcelas.',
      function(current_installment) {
        const { totalInstallments } = this.parent;
        return (
          current_installment > 0 && current_installment <= totalInstallments
        );
      }
    ),
  totalInstallments: Yup.number()
    .required('Insira o total de parcelas!')
    .positive('O total de parcelas deve ser maior que zero.')
    .integer('O total de parcelas deve ser um número inteiro.'),
});

export const updateRecorrenceSchema = async data => {
  const schema = Yup.object().shape({
    id: Yup.number().required(
      'Nenhum item foi selecionado para alterar a recorrência!'
    ),
    recurrent: Yup.boolean().required(
      'Nenhum item foi selecionado para alterar a recorrência!'
    ),
  });

  try {
    await schema.validate(data);
    return {
      success: true,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

const classificationOptions = [
  'Mensal Comprometido',
  'Eventual Comprometido',
  'Mensal Flexível',
  'Eventual Flexível',
];

export const createItemSchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 letras!')
      .required('Preencha o nome do item!'),
    classification: Yup.string()
      .oneOf(
        [
          'Mensal Comprometido',
          'Mensal Flexível',
          'Eventual Flexível',
          'Eventual Comprometido',
        ],
        'Classificação inválida! Escolha uma das classificações válidas.'
      )
      .required('Preencha a classificação do item!'),
    value: Yup.number().required('Preencha o valor do item!'),
    recurrent: Yup.boolean().default(false),
    when: Yup.number().nullable(),
  });

  try {
    await schema.validate(data);

    const needsWhen =
      data.classification === classificationOptions[0] ||
      data.classification === classificationOptions[1];
    if (needsWhen && !data.when) {
      throw new Error('O campo de vencimento deve ser preenchido!');
    }
    let formatedData = { ...data };
    delete formatedData.maturity;

    return {
      success: true,
      data: formatedData,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

export const updateItemSchema = async data => {
  const schema = Yup.object().shape({
    id: Yup.number().required('Nenhum item foi selecionado para alterar!'),
    name: Yup.string()
      .min(3, 'O nome deve ter pelo menos 3 letras!')
      .required('Preencha o nome do item!'),
    classification: Yup.string(),
    value: Yup.number(),
    recurrent: Yup.boolean(),
    credit_card: Yup.boolean(),
    when: Yup.number().nullable(),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    if (!classificationOptions.includes(validatedData.classification)) {
      throw new Error(
        'Classificação inválida! Escolha uma das classificações válidas.'
      );
    }

    const needsWhen =
      data.classification === classificationOptions[0] ||
      data.classification === classificationOptions[1];
    if (needsWhen && !data.when) {
      throw new Error('O campo de vencimento deve ser preenchido!');
    }

    return {
      success: true,
      data: validatedData,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export const createCategorySchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string().required('Preencha o nome da categoria!'),
    classification: Yup.string().required(
      'Preencha a classificação da categoria!'
    ),
    type: Yup.string().required('Preencha o tipo da categoria!'),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: true,
    });
    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};

export const updateCategorySchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string().required('Preencha o nome da categoria!'),
    classification: Yup.string().required(
      'Preencha a classificação da categoria!'
    ),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });
    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  }
};
export const entryValidationSchema = async data => {
  const schema = Yup.object().shape({
    item_id: Yup.number().required('O item é obrigatório.'),
    description: Yup.string()
      .required('A descrição é obrigatória.')
      .typeError('A descrição deve ser um texto válido.'),
    start: Yup.date()
      .required('A data inicial é obrigatória.')
      .typeError('A data inicial deve ser uma data válida.'),
    end: Yup.date()
      .nullable()
      .typeError('A data final deve ser uma data válida.'),
    value: Yup.number()
      .required('O valor é obrigatório.')
      .typeError('O valor deve ser um número válido.'),
    recurrent: Yup.boolean().typeError(
      'O campo de recorrência deve ser verdadeiro ou falso.'
    ),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};
export const installmentValidationSchema = async data => {
  const schema = Yup.object()
    .shape({
      name: Yup.string()
        .required('O nome é obrigatório.')
        .typeError('O nome deve ser um texto válido.'),
      value: Yup.number()
        .required('O valor é obrigatório.')
        .typeError('O valor deve ser um número válido.'),
      category_id: Yup.number()
        .integer('O ID da categoria deve ser um número inteiro.')
        .required('O ID da categoria é obrigatório.')
        .typeError('O ID da categoria deve ser um número.'),
      item_id: Yup.number()
        .integer('O ID do item deve ser um número inteiro.')
        .required('O ID do item é obrigatório.')
        .typeError('O ID do item deve ser um número.'),
      currentInstallment: Yup.number()
        .integer('A parcela atual deve ser um número inteiro.')
        .required('A parcela atual é obrigatória.')
        .typeError('A parcela atual deve ser um número.'),
      totalInstallments: Yup.number()
        .integer('O total de parcelas deve ser um número inteiro.')
        .required('O total de parcelas é obrigatório.')
        .typeError('O total de parcelas deve ser um número.'),
    })
    .test(
      'installment-check',
      'A parcela atual deve ser menor ou igual ao total das parcelas',
      function(values) {
        const { currentInstallment, totalInstallments } = values;
        if (currentInstallment > totalInstallments) {
          return this.createError({
            path: 'currentInstallment',
            message:
              'A parcela atual deve ser menor ou igual ao total das parcelas',
          });
        }
        return true;
      }
    )
    .test(
      'greater-than-zero',
      'A parcela atual e o total de parcelas devem ser maiores que zero',
      function(values) {
        const { currentInstallment, totalInstallments } = values;
        if (currentInstallment === 0 || totalInstallments === 0) {
          return this.createError({
            path: 'currentInstallment',
            message:
              'A parcela atual e o total de parcelas devem ser maiores que zero',
          });
        }
        return true;
      }
    );

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};

export const genericDreamSchema = Yup.object().shape({
  name: Yup.string().required('Preencha o nome do sonho'),
  description: Yup.string()
    .required('Insira uma descrição do sonho')
    .min(3, 'Insira pelo menos 3 letras na descrição'),
  deadline: Yup.date().required('Data de realização é obrigatória'),
});

export const financialIndependenceDreamSchema = Yup.object().shape({
  name: Yup.string().required('Preencha o nome do sonho'),
  description: Yup.string()
    .required('Insira uma descrição do sonho')
    .min(3, 'Insira pelo menos 3 letras na descrição'),
  deadline: Yup.date().required('Data de realização é obrigatória'),
  totalFullLiquidityBase: Yup.number()
    .min(
      0,
      'Insira um valor valido para o campo BPL - Base de Plena Liquidez Atual'
    )
    .required('Preencha o campo BPL - Base de Plena Liquidez Atual'),
  currentFixedAssets: Yup.number()
    .min(
      0,
      'Insira um valor valido para o campo PLF - Patrimônio Imobilizado atual'
    )
    .required('Preencha o campo PLF - Patrimônio Imobilizado atual'),
  currentFinancialAssets: Yup.number()
    .min(
      0,
      'Insira um valor valido para o campo PLF - Patrimônio Financeiro atual'
    )
    .required('Preencha o campo PLF - Patrimônio Financeiro atual'),
});
export const investmentValidationSchema = async data => {
  const schema = Yup.object().shape({
    id: Yup.number().required('O id é obrigatório.'),
    date_start: Yup.date()
      .required('A data é obrigatória.')
      .typeError('A data deve ser uma data válida.'),
    value: Yup.number()
      .required('O valor é obrigatório.')
      .typeError('O valor deve ser um número válido.'),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};
export const activePatrimonialSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  installmentValue: Yup.string().required('Valor é obrigatório'),
  classification: Yup.string().required('Selecione um classificação'),
});
export const financialPatrimonialSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  installmentValue: Yup.string().required('Valor é obrigatório'),
  categorization: Yup.string().required('Selecione uma categoria'),
  liquidity: Yup.string().required('Selecione um tipo de liquidez'),
});
export const passivePatrimonialSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  institution: Yup.string().required('Instituição é obrigatória'),
  installment: Yup.number()
    .typeError('O valor da parcela deve ser um número')
    .required('Valor da parcela é obrigatório'),
  remainingInstallments: Yup.number()
    .typeError('O número de parcelas restantes deve ser um número')
    .required('Número de parcelas restantes é obrigatório'),
  inCash: Yup.number()
    .typeError('O valor à vista deve ser um número')
    .required('Valor à vista é obrigatório'),
  remainingValue: Yup.number()
    .typeError('O valor restante deve ser um número')
    .required('Valor restante é obrigatório'),
  discount: Yup.number()
    .typeError('O desconto deve ser um número')
    .required('Desconto é obrigatório'),
});

export const propertiesRentValidationSchema = async data => {
  const schema = Yup.object().shape({
    value: Yup.number()
      .typeError('Valor de Mercado deve ser um número')
      .min(0, 'Valor de Mercado deve ser maior ou igual a 0')
      .default(0.0)
      .nullable(),

    rent: Yup.number()
      .typeError('Renda (Aluguel) deve ser um número')
      .min(0, 'Renda (Aluguel) deve ser maior ou igual a 0')
      .default(0.0)
      .nullable(),

    monthly_expenses: Yup.number()
      .typeError('Despesas mensais deve ser um número')
      .min(0, 'Despesas mensais deve ser maior ou igual a 0')
      .default(0.0)
      .nullable(),

    recommendation: Yup.string()
      .default('A definir')
      .nullable(),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};

export const createSocialSecurityValidationSchema = async data => {
  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),

    analysis_date: Yup.date()
      .required('A data de análise é obrigatória')
      .typeError('A data de análise deve ser válida'),

    expected_retirement_date: Yup.date()
      .required('A data esperada de aposentadoria é obrigatória')
      .typeError('A data esperada de aposentadoria deve ser válida'),

    investment_value: Yup.number()
      .default(0.0)
      .required('O valor do investimento é obrigatório')
      .typeError('O valor do investimento deve ser um número'),

    monthly_contribution: Yup.number()
      .default(0.0)
      .required('A contribuição mensal é obrigatória')
      .typeError('A contribuição mensal deve ser um número'),

    initial_current_monthly_income: Yup.number()
      .default(0.0)
      .required('A renda mensal inicial é obrigatória')
      .typeError('A renda mensal inicial deve ser um número'),

    retirement_income: Yup.number()
      .default(0.0)
      .required('A renda na aposentadoria é obrigatória')
      .typeError('A renda na aposentadoria deve ser um número'),

    position: Yup.number()
      .integer('A posição deve ser um número inteiro')
      .default(0)
      .required('A posição é obrigatória')
      .typeError('A posição deve ser um número'),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};

export const socialSecurityValidationSchema = async data => {
  const schema = Yup.object().shape({
    socialSecurityId: Yup.string().required('O identificador é necessário'),
    file: Yup.mixed().required('O arquivo é obrigatório'),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};

export const privatePensionFileValidationSchema = async data => {
  const schema = Yup.object().shape({
    privatePensionId: Yup.string().required('O identificador é necessário'),
    file: Yup.mixed().required('O arquivo é obrigatório'),
  });

  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};
export const validateReportItem = async data => {
  try {
    const UpdateReportItemSchema = Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(1, 'O nome do item deve ter pelo menos 1 caractere!')
        .required('Preencha o nome do item!'),
      classification: Yup.string()
        .oneOf(
          [
            'Mensal Comprometido',
            'Mensal Flexível',
            'Eventual Flexível',
            'Eventual Comprometido',
          ],
          'Classificação de item inválida!'
        )
        .required('Preencha a classificação do item!'),
      when: Yup.mixed().when('classification', {
        is: classification =>
          ['Mensal Comprometido', 'Eventual Comprometido'].includes(
            classification
          ),
        then: Yup.number()
          .min(1, 'Insira um mês/dia do mês válido!')
          .required('Preencha o campo "when" para itens comprometidos!')
          .typeError('Insira um mês/dia do mês válido!'),
        otherwise: Yup.number().nullable(),
      }),
      value: Yup.number().required('Preencha o valor do item!'),
    });

    await UpdateReportItemSchema.validate(data);

    return {
      success: true,
      data,
    };
  } catch (err) {
    return {
      success: false,
      error: err.message,
    };
  }
};

export const updatePrivatePensionValidationSchema = async data => {
  const schema = Yup.object().shape({
    fund_name: Yup.string().required('Nome do Fundo é obrigatório'),
    cnpj: Yup.string()
      .required('CNPJ é obrigatório')
      .matches(/^\d{14}$/, 'CNPJ deve ter 14 dígitos'),
    current_amount: Yup.number('O valor atual deve ser um número')
      .default(0)
      .nullable(),
    monthly_contribution: Yup.number('A contribuição mensal deve ser um número')
      .default(0)
      .nullable(),
    cop: Yup.boolean().default(false),
    pension_type: Yup.string('O tipo de previdência é obrigatório').nullable(),
    ir_benefit: Yup.boolean('O benefício IR deve ser sim ou não').default(
      false
    ),
    monthly_return: Yup.number('O retorno mensal deve ser um número')
      .default(0)
      .nullable(),
  });
  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};

export const updateRecommendationPrivatePensionValidationSchema = async data => {
  const validPensionActions = [
    'A definir',
    'contract',
    'redeem',
    'portability',
    'keep_without_contributions',
    'keep',
    'increase_contributions',
  ];
  const schema = Yup.object().shape({
    recommendation: Yup.string()
      .required('A recomendação é obrigatória')
      .oneOf(validPensionActions, 'Recomendação de previdência inválida'),
  });
  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};

export const createPrivatePensionValidationSchema = async data => {
  const schema = Yup.object().shape({
    fund_name: Yup.string().required('Nome do Fundo é obrigatório'),
    cnpj: Yup.string()
      .required('CNPJ é obrigatório')
      .matches(/^\d{14}$/, 'CNPJ deve ter 14 dígitos'),
    current_amount: Yup.number('O valor atual deve ser um número')
      .default(0)
      .nullable(),
    monthly_contribution: Yup.number('A contribuição mensal deve ser um número')
      .default(0)
      .nullable(),
    cop: Yup.boolean().default(false),
    pension_type: Yup.string('O tipo de previdência é obrigatório').nullable(),
    ir_benefit: Yup.boolean('O benefício IR deve ser sim ou não')
      .default(false)
      .nullable(),
    monthly_return: Yup.number('O retorno mensal deve ser um número')
      .default(0)
      .nullable(),
    recommendation: Yup.string('A recomendação deve ser string').nullable(),
  });
  try {
    const validatedData = await schema.validate(data, {
      stripUnknown: true,
      abortEarly: false,
    });

    return {
      success: true,
      data: validatedData,
    };
  } catch (err) {
    if (err.inner) {
      const errorMessages = err.inner.map(e => ({
        path: e.path,
        message: e.message,
      }));

      return {
        success: false,
        error: errorMessages,
      };
    }

    return {
      success: false,
      error: [err.message],
    };
  }
};
export const validateSuitabilityAnswers = async answers => {
  try {
    const parsedAnswers = answers.map(answer => ({
      questionId: Number(answer.questionId),
      answerId: Number(answer.answerId),
    }));

    const validationSchema = Yup.array().of(
      Yup.object().shape({
        questionId: Yup.number()
          .typeError('questionId deve ser um número')
          .required('questionId é obrigatório')
          .positive('questionId deve ser positivo')
          .integer('questionId deve ser um número inteiro'),
        answerId: Yup.number()
          .typeError('answerId deve ser um número')
          .required('answerId é obrigatório')
          .positive('answerId deve ser positivo')
          .integer('answerId deve ser um número inteiro'),
      })
    );

    await validationSchema.validate(parsedAnswers, { abortEarly: false });
    return { isValid: true, errors: [] };
  } catch (err) {
    console.log(err);
    return { isValid: false, errors: err.errors };
  }
};
