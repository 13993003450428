import { TableContainerBasic } from './Container';
import { ContentIcon } from './ContentIcon';
import { GroupIconsBasic } from './GroupIcons';
import { TableBasic } from './TableBasic';
import { TableOverflow } from './TableOverflow';
import { TableProgress } from './TableProgress';
import { TableSwitch } from './TableSwitch';
import { TableTbodyBasic } from './Tbody';
import { TableTdBasic } from './Td';
import { TableThBasic } from './Th';
import { TableTheadBasic } from './Thead/Thead';
import { TableTitleButton } from './TitleButton';
import { TableTitleDate } from './TitleDate';
import { TableTitleDropdown } from './TitleDropdown';
import { TitleInformationTotal } from './TitleInformationTotal';
import { TableTitleSet } from './TitleSet';
import { TitleTotal } from './TitleTotal';
import { TableTrBasic } from './Tr';

export const Table = {
  Title: TableTitleDate,
  Container: TableContainerBasic,
  Thead: TableTheadBasic,
  Tr: TableTrBasic,
  Th: TableThBasic,
  Td: TableTdBasic,
  TableBasic: TableBasic,
  Tbody: TableTbodyBasic,
  Switch: TableSwitch,
  Progress: TableProgress,
  TitleInformation: TitleInformationTotal,
  GroupIcons: GroupIconsBasic,
  TitleTotal: TitleTotal,
  TitleSet: TableTitleSet,
  ContentIcon: ContentIcon,
  TitleButton: TableTitleButton,
  TitleDropdown: TableTitleDropdown,
  Overflow: TableOverflow,
};
