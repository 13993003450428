import React, { useState } from "react";
import { Container, TitleContainer, ScrollContainer, Wrapper, IntroductionContainer } from "./styles";
import { Span } from "components/atoms/Span";
import { H4 } from "components/atoms/Headings/H4";
import { Button } from "components/atoms/Button";
import { QuestionPager } from "components/atoms/QuestionPager";
import { SuitabilityQuiz } from "../../../molecules/SuitabilityQuiz";
import { getSuitabilityQuestions } from "development/suitability";
import toast from "react-hot-toast";
import ValidateRoles from "components/organisms/ValidateRoles";

export const EmptySuitabilityProfile = ({
    changeProfile = () => { },
    data = [],
    initialPage = "starting",
    initialQuestion = 0,
    goBackToProfile,
}) => {
    const status = ["starting", "doing"];
    const [currentPage, setCurrentPage] = useState(status.includes(initialPage) ? initialPage : status[0]);
    const [currentQuestion, setCurrentQuestion] = useState(initialQuestion >= 0 && initialQuestion < data.length ? initialQuestion : 0);
    const [answersGiven, setAnswersGiven] = useState([]);
    const suitabilityQuestions = getSuitabilityQuestions();
    const questionsQuantity = data?.length;

    const changeQuestionByPager = (number) => {
        setCurrentQuestion(number);
    };

    const handleNextQuestion = () => {
        if (currentQuestion < questionsQuantity - 1) {
            setCurrentQuestion((prev) => prev + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion((prev) => prev - 1);
        }
    };

    const returnToInvestorProfile = () => {
        setAnswersGiven([]);
        setCurrentPage(status[0]);
        goBackToProfile();
    };

    const endTest = async () => {
        if (answersGiven.length !== questionsQuantity) {
            toast.error("Responda todas as perguntas antes de finalizar o teste!");
            return
        }
        const response = await changeProfile({ answersGiven });
        if (response) {
            returnToInvestorProfile();
        }
    };

    const pages = {
        starting: (
            <>
                <ScrollContainer>
                    <Wrapper>
                        <IntroductionContainer>
                            <H4 className="empty-suitability-questionnaire">
                                Bem-vindo(a) ao Questionário de Suitability!
                            </H4>
                            <Span className="empty-suitability-questionnaire-description">
                                Visando cumprir as exigências regulatórias, a Yuno ON possui um procedimento de Suitability para definir o perfil de investimento de seus clientes. Para continuar realizando suas operações, precisamos que preencha o questionário a seguir.
                            </Span>
                            <Span className="empty-suitability-questionnaire-warning">
                                É necessário responder todas as perguntas para que seu perfil seja gerado e os dados computados.
                            </Span>
                        </IntroductionContainer>
                    </Wrapper>
                </ScrollContainer>
                <Wrapper className="button">
                    <ValidateRoles role={'only-client'}>
                        <Button className="empty-suitability-questionnaire" onClick={() => setCurrentPage(status[1])}>
                            Iniciar questionário
                        </Button>
                    </ValidateRoles>
                </Wrapper>
            </>
        ),
        doing: (
            <>
                <ScrollContainer>
                    <Wrapper>
                        <QuestionPager
                            className="empty-suitability-questionnaire"
                            total={questionsQuantity}
                            currentQuestion={currentQuestion + 1}
                            onChange={changeQuestionByPager}
                        />
                    </Wrapper>
                    <Wrapper>
                        <SuitabilityQuiz
                            currentQuestion={suitabilityQuestions[currentQuestion]}
                            answersGiven={answersGiven}
                            setAnswersGiven={setAnswersGiven}
                            data={data[currentQuestion]}
                        />
                    </Wrapper>
                </ScrollContainer>
                <Wrapper className="button">
                    {currentQuestion === 0 ? (
                        <Button className="empty-suitability-questionnaire" onClick={returnToInvestorProfile}>
                            Retornar ao Perfil Investidor
                        </Button>
                    ) : (
                        <Button className="empty-suitability-questionnaire" onClick={handlePreviousQuestion}>
                            Voltar
                        </Button>
                    )}
                    {currentQuestion === questionsQuantity - 1 ? (
                        <Button className="empty-suitability-questionnaire suitability-form" onClick={endTest}>
                            Finalizar
                        </Button>
                    ) : (
                        <Button className="empty-suitability-questionnaire suitability-form" onClick={handleNextQuestion}>
                            Continuar
                        </Button>
                    )}
                </Wrapper>
            </>
        ),
    };

    return (
        <Container className="empty-suitability-questionnaire">
            <TitleContainer>
                <Span className="empty-suitability-questionnaire">Questionário de Suitability</Span>
            </TitleContainer>
            {pages[currentPage]}
        </Container>
    );
};
