import styled from 'styled-components';

export const ModalBody = styled.div`
  &.scrollable {
    overflow-y: auto;
    max-height: 55vh;
    position: relative;
  }

  font-size: 0.9rem;
  text-align: center;
  margin: 38px 0;

  &.full-height {
    height: 100%;
  }

  &.modal {
    display: flex;
  }

  &.generic-dream {
    margin: 0;
    display: flex;
    gap: 48px;
  }

  &.gallery-modal {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    width: 349px;
    height: 172px;
    overflow: auto;
    margin: 18px 0 0 0;
  }

  &.extract-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &.new-realease {
    z-index: 998;
    justify-content: center;
    gap: 8px;
    height: max-content;
    max-height: 400px;
    overflow-y: auto;
    margin: 5px 0 0 0;
    position: relative;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }
  }
`;
