import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: 18px;
        height: 27px;
    }
`;

export const Icon = styled.svg`
    &.asset-management {
        width: 18px;
        height: 27px;

        path {
            fill: ${props => props.theme.blueRoyal};
        }

        &.hovered {
            path {
                fill: ${props => props.theme.white};
            }
        }
    }
`;