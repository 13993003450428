import React from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { MaterialAssets } from './MaterialAssets';
import { FinancialAssets } from './FinancialAssets';
import { EquityPassive } from './EquityPassive';
import { NotEquityPassive } from './NotEquityPassive';

export const PatrimonyMapTable = ({
  currentPage,
  materialAssets,
  financialAssets,
  equityPassive,
  noEquityPassive,
  textMaterial,
  textFinancial,
  textEquity,
  textNotEquity,
  loading,
  reloadPage,
  isProcessing,
  privateProcessing,
  onCreateActive = () => {},
  onUpdateActive = () => {},
  handleToggleStatus = () => {},
  handleDeleteActive = () => {},
  onCreatePassive = () => {},
  onUpdatePassive = () => {},
  handleToggleStatusPension = () => {},
  handleDeletePassive = () => {},
  onQuit = () => {},
}) => {
  const pages = {
    active: (
      <>
        <MaterialAssets
          materialAssets={materialAssets}
          tooltipText={textMaterial}
          reloadPage={reloadPage}
          onCreate={onCreateActive}
          onUpdate={onUpdateActive}
          handleToggleStatus={handleToggleStatus}
          handleDeleteActive={handleDeleteActive}
          isProcessing={isProcessing}
        />
        <FinancialAssets
          financialAssets={financialAssets}
          tooltipText={textFinancial}
          onCreate={onCreateActive}
          onUpdate={onUpdateActive}
          handleToggleStatus={handleToggleStatus}
          handleToggleStatusPension={handleToggleStatusPension}
          handleDeleteActive={handleDeleteActive}
          isProcessing={isProcessing}
          privateProcessing={privateProcessing}
        />
      </>
    ),
    passive: (
      <>
        <EquityPassive
          equityPassive={equityPassive}
          tooltipText={textEquity}
          onCreate={onCreatePassive}
          onUpdate={onUpdatePassive}
          handleDeleteActive={handleDeletePassive}
          onQuit={onQuit}
        />
        <NotEquityPassive
          passiveFinancial={noEquityPassive}
          tooltipText={textNotEquity}
          onCreate={onCreatePassive}
          onUpdate={onUpdatePassive}
          handleDeleteActive={handleDeletePassive}
          onQuit={onQuit}
        />
      </>
    ),
  };

  if (loading) {
    return <LoadingTable />;
  }
  return pages[currentPage] || <></>;
};
