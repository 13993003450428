import React from 'react';
import { StyledIcon } from './styles';

function PlusIcon({ className }) {
  return (
    <StyledIcon
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="26"
        height="26"
        rx="13"
        fill="#6C6C6C"
        fillOpacity="0.25"
      />
      <path
        d="M19.7578 12.4474H14.0536V6.74311C14.0536 6.43896 13.8051 6.19048 13.5009 6.19048C13.1968 6.19048 12.9483 6.43896 12.9483 6.74311V12.4474H7.24404C6.93989 12.4474 6.69141 12.6958 6.69141 13C6.69141 13.3042 6.93989 13.5526 7.24404 13.5526H12.9483V19.2569C12.9483 19.561 13.1968 19.8095 13.5009 19.8095C13.8051 19.8095 14.0536 19.561 14.0536 19.2569V13.5526H19.7578C20.062 13.5526 20.3105 13.3042 20.3105 13C20.3105 12.6958 20.062 12.4474 19.7578 12.4474Z"
        fill="white"
      />
    </StyledIcon>
  );
}

export default PlusIcon;
