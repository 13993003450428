import styled from "styled-components";

export const GraphSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: auto;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 34px;
    margin-bottom: 35px;
`;