import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import {
  CanvasWrapper,
  ChartWrapper,
  Container,
  StyledCanvas,
  Title,
} from './styles';
import { formatOutputMoney } from 'utils/numbers';
import light from 'styles/themes/light';

export const LineGrafic = ({ labels = [], data = [] }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (!chartContainer.current) return;

    const formattedData = data.map(item => item.raw);

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Patrimônio',
          data: formattedData,
          fill: true,
          backgroundColor: light.lilacBackground,
          borderColor: light.blueLight,
          borderWidth: 1,
          tension: 0.4,
          pointRadius: 3,
          pointHitRadius: 10,
          hoverRadius: 5,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      legend: { display: false },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: tooltipItem => formatOutputMoney(tooltipItem.value, 0),
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: true,
              color: light.gradeColor,
              borderDash: [3, 3],
            },
            ticks: {
              fontSize: 12,
              fontColor: light.grayShadow,
              fontStyle: 'normal',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              color: light.gradeColor,
              borderDash: [3, 3],
            },
            ticks: {
              display: true,
              fontSize: 12,
              fontColor: light.grayShadow,
              fontStyle: 'normal',
              padding: 10,
              min: Math.min(...formattedData),
              max: Math.max(...formattedData),
              stepSize: Math.max(...formattedData) / labels.length,
              callback: value => formatOutputMoney(value, 0),
            },
          },
        ],
      },
      plugins: {
        datalabels: { display: false },
      },
    };

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(chartContainer.current, {
      type: 'line',
      data: chartData,
      options,
    });
  }, [chartContainer, labels, data]);

  return (
    <ChartWrapper>
      <Title>Patrimônio</Title>
      <Container>
        <CanvasWrapper>
          <StyledCanvas ref={chartContainer} />
        </CanvasWrapper>
        <Title className="year">Anos</Title>
      </Container>
    </ChartWrapper>
  );
};
