import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-width: 400px;
`;

export const DivContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 5px;
`;

export const ContainerOverlay = styled.div`
  position: relative;
`;
