import React, { useState, useRef } from 'react';
import { Modal } from 'components/templates/Modal';
import { InputFile } from '../InputFile';
import { LineFile } from '../fileLine';
import { ContainerScroll } from './styles';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { useAttachSocialSecurityFile } from 'hooks/useSocialSecurity';
import { useSocialSecurityFiles } from 'hooks/useSocialSecurity';
import { SpendingCard } from 'components/organisms/RetirementManagement/styles';
import { EmptyCardRow } from 'components/atoms/EmptyCardRow';
export const UploadSocialSecurityFile = ({
  showModal,
  setShowModal,
  loading = false,
  socialSecurityId,
}) => {
  const [file, setFile] = useState();
  const fileRef = useRef(null);
  const {
    attachSocialSecurityFile,
    loading: loadingAttach,
  } = useAttachSocialSecurityFile();
  const {
    files,
    loading: loadingFiles,
    reloadSocialSecurityFiles,
  } = useSocialSecurityFiles(socialSecurityId);

  const Content = () => {
    if (!files.length) {
      return (
        <SpendingCard className="empty center">
          <EmptyCardRow
            className="summary-card custom"
            height="100px"
            width="100%"
            minHeight="100px"
          >
            Ainda não há arquivos cadastrados
          </EmptyCardRow>
        </SpendingCard>
      );
    }
    return (
      <>
        {files?.map(file => (
          <LineFile key={file.id} file={file}  />
        ))}
      </>
    );
  };
  const handleSendFile = async () => {
    await attachSocialSecurityFile({ socialSecurityId, file });
    reloadSocialSecurityFiles(socialSecurityId);
  };
  if (loading || loadingAttach || loadingFiles) {
    return (
      <Modal.Container
        showModal={showModal}
        setShowModal={setShowModal}
        className="simple-modal delete"
      >
        <Modal.TitleContainer>
          <GenericLoading
            className="custom-card"
            height="22px"
            width="100%"
            borderRadius="8px"
            marginBottom="10px"
          />
        </Modal.TitleContainer>
        <Modal.BodyContainer>
          <GenericLoading
            className="custom-card"
            height="100px"
            width="100%"
            borderRadius="8px"
            marginBottom="10px"
          />
          {Array.from({ length: 6 }, () => (
            <GenericLoading
              className="custom-card"
              height="38px"
              width="100%"
              borderRadius="8px"
              marginBottom="10px"
              key={Math.random()}
            />
          ))}
        </Modal.BodyContainer>
      </Modal.Container>
    );
  }
  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal upload"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Arquivos"
          onClose={() => setShowModal(false)}
          type="file"
          className="simple-modal"
          theme={'investments'}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer className={'full-height'}>
        <InputFile
          getFile={fileRef}
          setFile={setFile}
          handleSendFile={handleSendFile}
        />
        <ContainerScroll>
          <Content />
        </ContainerScroll>
      </Modal.BodyContainer>
    </Modal.Container>
  );
};
