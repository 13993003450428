import React, { useEffect } from 'react';
import { Modal } from 'components/templates/Modal';
import { GalleryModal } from 'components/organisms/GalleryModal';
import { calculateIFP, getImageGoals } from 'utils/goals';
import { financialIndependenceDreamSchema } from 'utils/validator';
import toast from 'react-hot-toast';

export const FinancialIndependenceModal = ({
  showModal = '',
  setShowModal = () => {},
  dreamProps,
  updateGoal = () => {},
  uploadFile = () => {},
  onCreateGoal = () => {},
}) => {
  const name = 'Independência Financeira';
  const type = 'financial-independence';
  const [meaning, setMeaning] = React.useState(dreamProps?.description || '');
  const [deadline, setDeadline] = React.useState(
    dreamProps?.end ? new Date(dreamProps?.end) : new Date()
  );
  const [totalFinancialIndependenceValue] = React.useState(
    dreamProps?.value?.raw || ''
  );

  const [totalFullLiquidityBase, setTotalFullLiquidityBase] = React.useState(
    dreamProps?.bpl?.raw || 0
  );

  const [
    currentFullLiquidityBase,
    setCurrentFullLiquidityBase,
  ] = React.useState(dreamProps?.financial_active_value_bpl?.raw || '');
  const [currentFixedAssets, setCurrentFixedAssets] = React.useState(
    dreamProps?.patrimonial_active_value?.raw || 0
  );
  const [currentFinancialAssets, setCurrentFinancialAssets] = React.useState(
    dreamProps?.financial_active_value?.raw || 0
  );
  const [profitability, setProfitability] = React.useState(
    dreamProps?.profitability || ''
  );
  const [allocationStrategyBPL] = React.useState(
    dreamProps?.strategic_allocation_bpl || ''
  );
  const [allocationStrategyPLF] = React.useState(
    dreamProps?.strategic_allocation || ''
  );
  const [savedMoney, setSavedMoney] = React.useState(
    dreamProps?.saved_money?.raw || ''
  );
  const [, setCurrentPlfValue] = React.useState(
    dreamProps?.current_plf_value?.raw || 0
  );
  const [passiveIncomeGoal, setPassiveIncomeGoal] = React.useState(
    dreamProps?.passive_income_goal?.raw || 0
  );
  const [ifp, setIfp] = React.useState(dreamProps?.ifp?.raw || '');
  const rentalRentIncome = dreamProps?.rental_rent_income?.raw || 0;

  const socialSecurityRent = dreamProps?.social_security_rent?.raw || 0;

  const [financialAssetsIncome, setFinancialAssetsIncome] = React.useState(
    passiveIncomeGoal - rentalRentIncome - socialSecurityRent
  );
  const [financialFreedomAssets, setFinancialFreedomAssets] = React.useState(
    (financialAssetsIncome || 0) * 200
  );
  useEffect(() => {
    setFinancialAssetsIncome(
      passiveIncomeGoal - rentalRentIncome - socialSecurityRent
    );
    setFinancialFreedomAssets(
      (passiveIncomeGoal - rentalRentIncome - socialSecurityRent || 0) * 200
    );
  }, [passiveIncomeGoal, rentalRentIncome, socialSecurityRent]);

  const [image, setImage] = React.useState({
    backgroundImage: dreamProps?.url || getImageGoals(type) || '',
    externalUrl: undefined,
    file: undefined,
  });
  const [showGaleryModal, setShowGaleryModal] = React.useState(false);
  const handleUpdateDream = async () => {
    const savedMoney =
      totalFullLiquidityBase + currentFixedAssets + currentFinancialAssets;

    let fileId = undefined;

    if (image?.file) {
      const updatedImage = await uploadFile(image?.file);
      fileId = updatedImage?.id;
    }

    const getRightValue = (current, last) => {
      if (current === last) return undefined;
      return current;
    };

    const data = {
      name: undefined,
      description: getRightValue(meaning, dreamProps?.description),
      value:
        getRightValue(
          totalFinancialIndependenceValue,
          dreamProps?.value?.raw
        ) || 0,
      profitability: getRightValue(profitability, dreamProps?.profitability),
      end: getRightValue(deadline, dreamProps?.end),
      done_at: undefined,
      file_id: fileId,
      saved_money: getRightValue(savedMoney, dreamProps?.saved_money?.raw),
      strategic_allocation: getRightValue(
        allocationStrategyPLF,
        dreamProps?.strategic_allocation
      ),
      strategic_allocation_bpl: getRightValue(
        allocationStrategyBPL,
        dreamProps?.strategic_allocation_bpl
      ),
      patrimonial_active_value: getRightValue(
        currentFixedAssets,
        dreamProps?.patrimonial_active_value?.raw
      ),
      financial_active_value_bpl: getRightValue(
        currentFullLiquidityBase,
        dreamProps?.financial_active_value_bpl?.raw
      ),
      financial_active_value: getRightValue(
        currentFinancialAssets,
        dreamProps?.financial_active_value?.raw
      ),
      liquidity_bpl: undefined,
      liquidity: undefined,
      bpl: getRightValue(totalFullLiquidityBase, dreamProps?.bpl?.raw),
      plf: undefined,
      type: undefined,
      external_url: image?.externalUrl,
      passive_income_goal: passiveIncomeGoal,
    };
    const res = await updateGoal(dreamProps.id, data);

    if (res) {
      setShowModal(false);
    }
  };

  const handleCreateDream = async () => {
    const validateFields = () => {
      let errors = {};

      try {
        financialIndependenceDreamSchema.validateSync({
          name,
          description: meaning,
          deadline,
          totalFullLiquidityBase,
          currentFixedAssets,
          currentFinancialAssets,
        });
      } catch (error) {
        errors = { ...errors, [error.path]: error.message };
      }

      return errors;
    };

    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        toast.error(error);
      });
      return;
    }

    let fileId = undefined;

    if (image?.file) {
      const updatedImage = await uploadFile(image?.file);
      fileId = updatedImage?.id;
    }

    const data = {
      name: name,
      description: meaning,
      value: totalFinancialIndependenceValue || 0,
      profitability: profitability,
      end: deadline,
      done_at: undefined,
      file_id: fileId,
      saved_money: savedMoney,
      strategic_allocation: allocationStrategyPLF,
      strategic_allocation_bpl: allocationStrategyBPL,
      patrimonial_active_value: currentFixedAssets,
      financial_active_value_bpl: totalFullLiquidityBase,
      financial_active_value: currentFinancialAssets,
      liquidity_bpl: undefined,
      liquidity: undefined,
      bpl: currentFullLiquidityBase,
      plf: undefined,
      type,
      external_url: image?.externalUrl,
      passive_income_goal: passiveIncomeGoal,
    };

    const res = await onCreateGoal(data);
    if (res) {
      setShowModal(false);
    }
  };

  const handleSave = () => {
    if (dreamProps?.id > 0) {
      handleUpdateDream();
    } else {
      handleCreateDream();
    }
  };

  useEffect(() => {
    const savedMoney =
      currentFullLiquidityBase + currentFixedAssets + currentFinancialAssets;
    setSavedMoney(savedMoney);

    const ifp = calculateIFP({
      end: new Date(deadline),
      profitability,
      value: financialFreedomAssets || 0,
      patrimonialActiveValue: currentFixedAssets,
      financialActiveValue: currentFinancialAssets,
      financialActiveValueBpl: currentFullLiquidityBase,
    });
    setIfp(ifp);
  }, [
    totalFullLiquidityBase,
    currentFixedAssets,
    currentFinancialAssets,
    totalFinancialIndependenceValue,
    deadline,
    profitability,
    currentFullLiquidityBase,
    financialFreedomAssets,
  ]);

  useEffect(() => {
    setCurrentPlfValue(currentFixedAssets + currentFinancialAssets);
  }, [currentFixedAssets, currentFinancialAssets]);

  return (
    <>
      {showGaleryModal ? (
        <GalleryModal
          showModal={showGaleryModal}
          setShowModal={setShowGaleryModal}
          onChange={setImage}
        />
      ) : (
        <Modal.Container
          showModal={showModal}
          setShowModal={setShowModal}
          className="generic-dream"
        >
          <Modal.TitleContainer>
            <Modal.SimpleTitleContent
              title="Editar sonho"
              onClose={() => setShowModal(false)}
              type="edit"
              className="generic-dream edit mb-32"
            />
          </Modal.TitleContainer>
          <Modal.BodyContainer className="generic-dream scrollable next">
            <Modal.Section>
              <Modal.InputText
                label="O que significa pra você?"
                getInputValue={setMeaning}
                className="generic-dream-modal"
                defaultValue={meaning}
              />
              <Modal.InputImage
                className="generic-dream"
                onClick={() => setShowGaleryModal(true)}
                backgroundImage={
                  image?.backgroundImage || getImageGoals(type) || ''
                }
              />
              <Modal.GroupInputs
                show={true}
                className="generic-dream-modal column-at-end gap"
              >
                <Modal.DatePicker
                  className="generic-dream-modal generic-dream-modal-datepicker half margin-top large"
                  label="Prazo"
                  getInputValue={setDeadline}
                  placeholder={deadline}
                  defaultValue={deadline}
                />

                <Modal.InputCurrency
                  label="Renda Passiva Almejada"
                  getInputValue={setPassiveIncomeGoal}
                  className="generic-dream-modal after"
                  defaultValue={passiveIncomeGoal}
                />
              </Modal.GroupInputs>
              <Modal.GroupInputs
                show={true}
                className="generic-dream-modal column-at-end gap"
              >
                <Modal.InputCurrency
                  label="Renda de Aluguel"
                  className="generic-dream-modal after divider"
                  defaultValue={rentalRentIncome}
                  disabled
                />
                <Modal.InputCurrency
                  label="Renda de Previdência Social"
                  className="generic-dream-modal after divider"
                  defaultValue={socialSecurityRent}
                  disabled
                />
              </Modal.GroupInputs>
              <Modal.InputCurrency
                label="Renda do Patrimônio Financeiro"
                className="generic-dream-modal after"
                defaultValue={financialAssetsIncome}
                disabled
              />
            </Modal.Section>
            <Modal.Section className="separate width medium">
              <Modal.InputCurrency
                label="Patrimônio Financeiro da Liberdade Financeira"
                className="generic-dream-modal"
                defaultValue={financialFreedomAssets}
                disabled
              />
              <Modal.GroupInputs
                show={true}
                className="generic-dream-modal column-at-end gap"
              >
                <Modal.InputCurrency
                  label="BPL - Base de Plena Liquidez Atual"
                  className="generic-dream-modal after divider"
                  getInputValue={setCurrentFullLiquidityBase}
                  defaultValue={currentFullLiquidityBase}
                />

                <Modal.InputCurrency
                  label="BPL - Base de Plena Liquidez Total"
                  className="generic-dream-modal after divider"
                  getInputValue={setTotalFullLiquidityBase}
                  defaultValue={totalFullLiquidityBase}
                />
              </Modal.GroupInputs>
              <Modal.GroupInputs
                show={true}
                className="generic-dream-modal column-at-end gap"
              >
                <Modal.InputCurrency
                  label="PLF - Patrimônio Imobilizado atual"
                  getInputValue={setCurrentFixedAssets}
                  className="generic-dream-modal after divider"
                  defaultValue={currentFixedAssets}
                />
                <Modal.InputCurrency
                  label="PLF - Patrimônio Financeiro atual"
                  getInputValue={setCurrentFinancialAssets}
                  className="generic-dream-modal after divider"
                  defaultValue={currentFinancialAssets}
                />
              </Modal.GroupInputs>
              <Modal.GroupInputs
                show={true}
                className="generic-dream-modal column-at-end gap"
              >
                <Modal.InputCurrency
                  label="Valor atual do sonho"
                  getInputValue={() => {}}
                  className="generic-dream-modal after divider"
                  defaultValue={savedMoney}
                  disabled
                />
                <Modal.PercentageField
                  label="Rentabilidade"
                  getInputValue={setProfitability}
                  className="generic-dream-modal after divider"
                  defaultValue={profitability}
                />
              </Modal.GroupInputs>

              <Modal.InputCurrency
                label="Investimento mensal"
                getInputValue={() => {}}
                className="generic-dream-modal after"
                defaultValue={ifp}
                disabled
              />
            </Modal.Section>
          </Modal.BodyContainer>
          <Modal.FooterWithOneButton
            text="Confirmar"
            className="generic-dream-modal"
            onClick={handleSave}
          />
        </Modal.Container>
      )}
    </>
  );
};
