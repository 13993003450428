import styled from "styled-components";

export const Container = styled.div`
    &.asset-management-doughnut-grafic-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: min-content;
        height: min-content;
        gap: 2px;
    }
`;