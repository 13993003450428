import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Modal } from 'components/templates/Modal';
import { getMonthNumber, getMonths, monthNumberToName } from 'utils/date';
import { getItemsClassificationOptions } from 'utils/data';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const EditSpendingItemModal = ({
  showModal = false,
  setShowModal = () => { },
  title = '',
  icon = 'edit',
  onCancel = () => { },
  onConfirm = () => { },
  theme,
  selectedItem,
  loading = true,
}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [classification, setClassification] = useState(
    selectedItem?.classification
  );
  const [maturity, setMaturity] = useState(selectedItem?.maturity);
  const [value, setValue] = useState(selectedItem?.value || 0);

  useEffect(() => {
    setName(selectedItem?.name || '');
    setClassification(selectedItem?.classification || '');
    setMaturity(selectedItem?.when || '');
    setValue(selectedItem?.value || '');
  }, [selectedItem]);

  const updateClassification = useCallback((classification) => {
    setClassification(classification);
    setMaturity('');
  }, []);

  const additionalSettings = useMemo(() => ({
    'mensal comprometido': (
      <>
        <Modal.InputNumber
          className="simple-modal after"
          label="Dia do mês"
          getInputValue={setMaturity}
          defaultValue={maturity}
          dayOfMonth
        />
        <Modal.InputCurrency
          className="simple-modal after only-it"
          label="Valor mensal"
          getInputValue={setValue}
          defaultValue={value}
        />
      </>
    ),
    'mensal flexível': (
      <Modal.InputCurrency
        className="simple-modal after only-it"
        label="Valor mensal"
        getInputValue={setValue}
        defaultValue={value}
      />
    ),
    'eventual comprometido': (
      <>
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={getMonths()}
          getDropdownValue={setMaturity}
          label="Mês do ano"
          placeholderSelect={typeof maturity === 'number' ? monthNumberToName(maturity) : maturity}
        />
        <Modal.InputCurrency
          className="simple-modal after"
          label="Valor anual"
          getInputValue={setValue}
          defaultValue={value}
        />
      </>
    ),
    'eventual flexível': (
      <Modal.InputCurrency
        className="simple-modal after only-it"
        label="Valor anual"
        getInputValue={setValue}
        defaultValue={value}
      />
    )
  }), [maturity, value]);

  const returnAdditionalSettings = () => {
    let base = String(classification).toLocaleLowerCase();
    return additionalSettings[base] || <></>;
  };

  const cancel = useCallback(() => {
    let when = classification === 'Eventual Comprometido'
      ? (typeof maturity === 'number' ? maturity : getMonthNumber(maturity))
      : Number(maturity);

    if (['Eventual Flexível', 'Mensal Flexível'].includes(classification)) {
      when = null;
    }

    onCancel({
      name,
      classification,
      when,
      value: value || 0
    });
  }, [classification, maturity, name, value, onCancel]);

  const confirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  if (loading) {
    return (
      <Modal.Container
        showModal={showModal}
        setShowModal={setShowModal}
        className="simple-modal edit"
      >
        <Modal.TitleContainer>
          <div style={{ display: 'flex', width: '100%' }}>
            <GenericLoading className='report-table-modal-icon' />
            <GenericLoading className='report-table-modal-title' />
          </div>
          <GenericLoading className='report-table-modal-icon' />
        </Modal.TitleContainer>
        <Modal.BodyContainer>
          <GenericLoading className='report-table-modal-body' />
          <GenericLoading className='report-table-modal-body after' />
        </Modal.BodyContainer>
        <Modal.SimpleFooter
          loading={true}
          loadingClassname='report-table-modal-footer'
        />
      </Modal.Container>
    );
  }

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome do item"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={getItemsClassificationOptions()}
          getDropdownValue={updateClassification}
          label="Classificação"
          placeholderSelect={classification}
        />
        {returnAdditionalSettings()}
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onCancel={() => cancel()}
        onConfirm={() => confirm()}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir item"
        cancelButtonText="Concluído"
        buttonOrder={['confirm', 'cancel']}
        confirmButtonColor='red'
        cancelButtonColor='blue'
      />
    </Modal.Container>
  );
};
