import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  createPrivatePensionValidationSchema,
  privatePensionFileValidationSchema,
  updatePrivatePensionValidationSchema,
  updateRecommendationPrivatePensionValidationSchema,
} from 'utils/validator';
import { getEmpytRetirementAssets } from 'utils/patrimonies';
import { getEmpytPrivatePensions } from 'utils/privatePensions';
const RetirementFinancialAssetsContext = createContext(null);
export const RetirementFinancialAssetsProvider = ({ children }) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [retirementFinancialAssets, setRetirementFinancialAssets] = useState(
    getEmpytRetirementAssets()
  );
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `private_pension/retirement-financial-assets/${userId}`
      );
      setRetirementFinancialAssets(data);
    } catch (error) {
      console.error('Error fetching private pensions financial assets:', error);
      toast.error('Ocorreu um erro ao buscar as previdencias privadas.');
    } finally {
      setLoading(false);
    }
  }, [userId]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  const reloadRetirementFinancialAssets = useCallback(() => {
    fetch();
  }, [fetch]);
  return (
    <RetirementFinancialAssetsContext.Provider
      value={{
        retirementFinancialAssets,
        setRetirementFinancialAssets,
        loading,
        setLoading,
        fetch,
        reloadRetirementFinancialAssets,
      }}
    >
      {children}
    </RetirementFinancialAssetsContext.Provider>
  );
};

export const useRetirementFinancialAssets = () => {
  const context = useContext(RetirementFinancialAssetsContext);
  if (!context) {
    throw new Error(
      'useRetirementFinancialAssets deve ser usado dentro de um RetirementOverviewProvider'
    );
  }
  return context;
};

const PrivatePensionsContext = createContext(null);

export const PrivatePensionsProvider = ({ children }) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [privatePensions, setPrivatePensions] = useState(
    getEmpytPrivatePensions()
  );
  const [loading, setLoading] = useState(true);

  const fetchPrivatePensions = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`private_pension/${userId}`);
      setPrivatePensions(data);
    } catch (error) {
      console.error('Error fetching private pensions:', error);
      toast.error('Ocorreu um erro ao buscar as previdências privadas.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchPrivatePensions();
  }, [fetchPrivatePensions]);

  const reloadPrivatePensions = useCallback(() => {
    fetchPrivatePensions();
  }, [fetchPrivatePensions]);

  return (
    <PrivatePensionsContext.Provider
      value={{
        privatePensions,
        setPrivatePensions,
        loading,
        setLoading,
        fetchPrivatePensions,
        reloadPrivatePensions,
      }}
    >
      {children}
    </PrivatePensionsContext.Provider>
  );
};

export const usePrivatePensions = () => {
  const context = useContext(PrivatePensionsContext);
  if (!context) {
    throw new Error(
      'usePrivatePensions deve ser usado dentro de um PrivatePensionsProvider'
    );
  }
  return context;
};
const useCreatePrivatePension = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const createPrivatePension = useCallback(
    async (data, setShowEditItemModal) => {
      const validate = await createPrivatePensionValidationSchema(data);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      setLoading(true);
      setError(null);
      const createPromise = api
        .post(`private_pension/${userId}`, data)
        .then(response => {
          // setShowEditItemModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao cadastrar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });

      await toast.promise(createPromise, {
        loading: 'Criando item...',
        success: <b>Item criado com sucesso!</b>,
        error: <b>Erro ao criar o item.</b>,
      });

      return createPromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { createPrivatePension, loading, error };
};
const useUpdatePrivatePension = privatePensionId => {
  const [loading, setLoading] = useState(true);

  const validation = data => {
    if (data.hasOwnProperty('recommendation')) {
      return updateRecommendationPrivatePensionValidationSchema(data);
    }
    if (Object.keys(data).length === 1 && data.hasOwnProperty('cop')) {
      return { success: true };
    }
    if (Object.keys(data).length === 1 && data.hasOwnProperty('ir_benefit')) {
      return { success: true };
    }
    return updatePrivatePensionValidationSchema(data);
  };

  const updatePrivatePension = useCallback(
    async (privatePensionId, data, setShowEditItemModal) => {
      setLoading(true);
      let validate = await validation(data);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      try {
        const updatePromise = api.put(
          `private_pension/${privatePensionId}/`,
          data
        );

        await toast.promise(updatePromise, {
          loading: 'Atualizando previdencia privada...',
          success: <b>Previdencia privada atualizada com sucesso!</b>,
          error: <b>Erro ao atualizar a previdencia privada.</b>,
        });
      } catch (error) {
        console.error('Error updating private pension:', error);
        toast.error('Ocorreu um erro ao atualizar a previdencia privada.');
      } finally {
        setLoading(false);
        if (setShowEditItemModal) setShowEditItemModal(false);
      }
    },
    []
  );

  return {
    loading,
    setLoading,
    updatePrivatePension,
  };
};
const usePrivatePensionFiles = privatePensionId => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchItems = useCallback(async privatePensionId => {
    setLoading(true);
    try {
      const { data: items } = await api.get(
        `private_pension/${privatePensionId}/files`
      );
      setFiles(items);
    } catch (error) {
      console.error('Error fetching private pensions:', error);
      toast.error(
        'Ocorreu um erro ao buscar os arquivos de previdencias privadas.'
      );
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchItems(privatePensionId);
  }, [fetchItems, privatePensionId]);
  const reloadPrivatePensionFiles = useCallback(() => {
    fetchItems(privatePensionId);
  }, [fetchItems, privatePensionId]);

  return {
    files,
    setFiles,
    loading,
    setLoading,
    fetchItems,
    reloadPrivatePensionFiles,
  };
};

const useAttachPrivatePensionFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const attachPrivatePensionFile = useCallback(
    async ({ file, privatePensionId }) => {
      const validate = await privatePensionFileValidationSchema({
        file,
        privatePensionId,
      });

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      setLoading(true);
      setError(null);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('privatePensionId', privatePensionId);
      const createPromise = api
        .post(`private_pension/${privatePensionId}/files`, formData)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao adicionar o arquivo:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });

      await toast.promise(createPromise, {
        loading: 'Enviando arquivo...',
        success: <b>Arquivo enviado com sucesso!</b>,
        error: <b>Erro ao enviar o arquivo.</b>,
      });

      return createPromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { attachPrivatePensionFile, loading, error };
};

export {
  useCreatePrivatePension,
  useUpdatePrivatePension,
  usePrivatePensionFiles,
  useAttachPrivatePensionFile,
};
