import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React from 'react';
import { CardContainer, Container } from './styles';
import { generateClassName } from 'utils/text';

export const SimpleTableWithCheckboxAtTheEnd = ({
  body,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  showEditIconOnTitle,
  typeCard,
  url,
}) => {
  const header = [
    'Passivo',
    'Valor da Parcela',
    'Número de Parcelas',
    'Valor total',
    'Quitado',
  ];

  const columnOrder = [
    'name',
    'installment',
    'remaining_installments',
    'in_cash',
  ];

  return (
    <CardContainer className="no-footer">
      <BudgetTableTitle
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        showEditIcon={showEditIconOnTitle}
        showGoldIcon
        className={typeCard}
        url={url}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title debts">
              {header.map((item, index) => (
                <Th
                  className={generateClassName({
                    'budget-table-title debts': true,
                    'first-table-border': index === 0,
                    first: index === 0,
                  })}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title debts empty last-table-border final" />
            </Tr>
          </thead>
          <tbody>
            {body.map((row, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  if (['installment', 'in_cash'].includes(item)) {
                    return (
                      <Td
                        className={generateClassName({
                          'receipt-table-body': true,
                          first: index === 0,
                        })}
                        key={index}
                      >
                        {row[item]?.formatted || 'R$ 0'}
                      </Td>
                    );
                  }
                  return (
                    <Td
                      className={generateClassName({
                        'receipt-table-body': true,
                        first: index === 0,
                      })}
                      key={index}
                    >
                      {row[item]}
                    </Td>
                  );
                })}
                <Td className="receipt-table-body">
                  <Checkbox
                    checked={row?.settled_at}
                    className="circular default-cursor"
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </CardContainer>
  );
};
