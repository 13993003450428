/* eslint-disable */
import React from 'react';
import { Page } from 'components/templates/Page';
import RetirementMapCard from 'components/organisms/RetirementMapCard';
import { RetirementMapTable } from 'components/organisms/RetirementMapTable';
import DirecionalButton from 'components/organisms/Direcional';
import { RetirementOverviewProvider } from 'hooks/useRetirement';
import {
  PrivatePensionsProvider,
  RetirementFinancialAssetsProvider,
} from 'hooks/usePrivatePensions';

export default function MapRetirement() {
  const [currentPage, setCurrentPage] = React.useState('socialSecurity');

  return (
    <RetirementOverviewProvider>
      <RetirementFinancialAssetsProvider>
        <PrivatePensionsProvider>
          <Page.RootContainer className="detail">
            <RetirementMapCard
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <RetirementMapTable currentPage={currentPage} />
            <DirecionalButton />
          </Page.RootContainer>
        </PrivatePensionsProvider>
      </RetirementFinancialAssetsProvider>
    </RetirementOverviewProvider>
  );
}
