import React, { useRef, useState } from 'react';
import { Container, Header } from './style';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import PlusIcon from 'components/atoms/icons/PlusIcon';
import { Button } from 'components/atoms/Button';

export const InputFile = ({ getFile, setFile, onClick, handleSendFile }) => {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState(
    'Arraste ou faça upload do computador'
  );
  const [file, setLocalFile] = useState(null);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setLocalFile(selectedFile);
      if (setFile) {
        setFile(selectedFile);
      }
      if (getFile !== undefined) {
        getFile.current = selectedFile;
      }
    }
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = event => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragging(false);

    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setLocalFile(selectedFile);
      if (setFile) {
        setFile(selectedFile);
      }
      if (getFile !== undefined) {
        getFile.current = selectedFile;
      }
    }
  };

  const handleClearFile = () => {
    setFileName('Arraste ou faça upload do computador');
    setLocalFile(null);
    if (setFile) {
      setFile(null);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Container
      onClick={file ? null : handleContainerClick}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDragging={isDragging}
    >
      <Header>
        <Span className="modal-upload header">Enviar arquivos</Span>
        <Span className="modal-upload body">{fileName}</Span>
      </Header>

      {file ? (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={handleSendFile}
            className="primary border-gray-silver text-color-gray-silver font-size-12"
          >
            Enviar
          </Button>
          <Button
            onClick={handleClearFile}
            className="primary border-red text-color-gray-silver font-size-12"
          >
            Limpar
          </Button>
        </div>
      ) : (
        <PlusIcon />
      )}

      <Input
        id="file-input"
        type="file"
        className="input-file"
        ref={fileInputRef}
        onClick={onClick}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </Container>
  );
};
