import React from "react";
import { Container } from "./styles";
import { EnlargeArrow } from "components/atoms/icons/EnlargeArrow";
import { DecreaseArrow } from "components/atoms/icons/DecreaseArrow";
import { generateClassName } from "utils/text";

export const UpAndDownSelector = ({
    className = '',
    greaterLiquidity = ''
}) => {
    return (
        <Container className={className}>
            <EnlargeArrow className={generateClassName({
                [className]: true,
                'greater': greaterLiquidity === 'current_liquidity'
            })}
            />
            <DecreaseArrow className={generateClassName({
                [className]: true,
                'smaller': greaterLiquidity === 'need_for_liquidity'
            })}
            />
        </Container>
    )
}