import React, { useState } from "react";
import { AssetManagementNavigationCard } from "components/molecules/AssetManagementNavigationCard";
import { Suitability } from "components/organisms/Suitability";
import { FinancialAssets } from "components/organisms/FinancialAssets";
import { ImmediateLiquidity } from "components/organisms/ImmediateLiquidity";
import { usePlanningAssets } from "hooks/usePlanningAssets";
import { validateSuitabilityAnswers } from "utils/validator";
import toast from "react-hot-toast";
import { convertKeysToSnakeCase } from "utils/text";
import { PageContainer } from "components/atoms/PageContainer";

export default function PlanningAssets() {
    const {
        currentPage,
        setCurrentPage,
        loading,
        navigationCards,
        financialAssets,
        allocationReport,
        equityLiquidity,
        suitability,
        updateSuitability,
        toastMessage
    } = usePlanningAssets();

    const [showedMessage, setShowedMessage] = useState(false);

    const updateSuitabilityProfile = async ({ answersGiven }) => {
        const validatedObject = await validateSuitabilityAnswers(answersGiven);
        if (!validatedObject.isValid) {
            toast.error('Respostas de suitability inválidas!');
            return;
        }
        const formattedAnswers = convertKeysToSnakeCase(answersGiven);
        const { success } = await updateSuitability(formattedAnswers);
        if (!success) {
            toast.error('Não foi possivel atualizar o perfil suitability');
            return false;
        }
        toast.success('Perfil suitability atualizado com sucesso!');
        return true;
    }

    const page = {
        'financial-assets': (
            <FinancialAssets
                financialAssets={financialAssets}
                allocationReport={allocationReport}
                loading={loading}
            />
        ),
        'suitability': (
            <Suitability
                data={suitability}
                updateSuitabilityProfile={updateSuitabilityProfile}
                loading={loading}
            />
        ),
        'liquidity': <ImmediateLiquidity data={equityLiquidity} loading={loading} />,
    }

    if (
        !loading &&
        navigationCards?.suitability_profile === 'Indefinido' &&
        !showedMessage
    ) {
        setShowedMessage(true);
        toast("Perfil de suitability do usuário não definido!", {
            icon: "⚠️",
            style: {
                background: "#fbbf24",
                color: "#000",
            },
        });
    }

    React.useEffect(() => {
        if (toastMessage.show) {
            toast[toastMessage.type](toastMessage.message);
        }
    }, [toastMessage]);

    return (
        <PageContainer>
            <AssetManagementNavigationCard
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                valueOfAvailableFinancialEquity={navigationCards?.financial_assets?.formatted}
                currentInvestorProfile={navigationCards?.suitability_profile}
                liquidityNeedPercentage={navigationCards?.liquidity?.need_for_liquidity?.raw}
                currentLiquidityPercentage={navigationCards?.liquidity?.current_liquidity?.raw}
                greaterLiquidity={navigationCards?.liquidity?.greater_liquidity}
                nonCompliancePercentage={navigationCards?.mismatching_percentage?.formatted}
                loading={loading}
            />
            {page[currentPage] || <></>}
        </PageContainer>
    )
}