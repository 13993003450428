import styled from 'styled-components';

export const ModalDatePickerContainer = styled.div`
  &.generic-dream-modal-datepicker {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: left;
    border-bottom: 1px solid ${props => props.theme.grayShadow};
    height: 61px;
    margin-top: 14px;

    &.after {
      margin-top: 38px;
    }

    &.full-width {
      width: 100% !important;
    }

    &.midi-width {
      width: 45%;
      margin-left: auto;
    }

    &.half {
      width: 50%;
    }

    &.no-margin {
      margin: unset;
    }

    &.margin-top {
      &.medium {
        margin-top: 16px;
      }

      &.large {
        margin-top: 32px;
      }
    }
  }
`;

export const ModalDateContainer = styled.div`
  &.generic-dream-modal-datepicker {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 8.5px;
    height: 33px;
    margin-top: 14px;
  }
`;

export const ModalDatePickerContent = styled.div`
  &.generic-dream-modal-datepicker {
    display: flex;
    padding: 8.5px 5px 8.5px 8px;
    width: 100%;
    height: 33px;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 400;
    color: ${props => props.theme.graySilver};
  }
`;
