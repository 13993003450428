import styled from "styled-components";

export const GraphContainer = styled.div`
    &.financial-assets {
        width: 48%;
        height: max-content;
        border-radius: 16px;
        padding: 48px;
        background-color: ${props => props.theme.white};

        @media (max-width: 1440px) {
            width: 100%;
        }
    }
`;

export const GraphWrapper = styled.div`
    &.financial-assets {
        height: max-content;
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;

export const LabelContainer = styled.div`
    &.financial-assets {
       margin-left: auto;
       margin-top: 228px;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    padding: 0 12px;
    width: 162px;
    height: 17px;
    border-radius: 32px;
    background-color: ${props => props.backgroundColor};
    margin-top: 4px;

    &.first {
        margin-top: 0;
    }
`;