import React, { useMemo } from 'react';
import light from 'styles/themes/light';
import { Span } from 'components/atoms/Span';
import { DoughnutChart } from 'components/atoms/DoughnutChart';
import { GenericLoading } from 'components/atoms/GenericLoading';
import {
  GraphContainer,
  LabelContainer,
  GraphWrapper,
  DescriptionContainer,
} from './styles';

export const AllocationCard = ({
  titleCard = '',
  emptyMessage = 'Sem alocação',
  data = [],
  loading,
}) => {
  const colors = useMemo(
    () => ({
      'R. Fixa Pós': light.blueMidnight,
      'R. Fixa Pré': light.blueRoyal,
      'R. Fixa IPCA': light.blueNavy,
      Multimercado: light.blueDeep,
      FIIs: light.blueSky,
      'Ações BR': light.blueLilac,
      'R.V. Exterior': light.bluePale,
      Alternativo: light.blueLight,
    }),
    []
  );

  const datasetValues = useMemo(() => data.map(item => item.percentage.raw), [
    data,
  ]);
  const datasetLabels = useMemo(() => data.map(item => item.fund), [data]);
  const datasetColors = useMemo(
    () => datasetLabels.map(label => colors[label] || light.grayPale),
    [datasetLabels, colors]
  );

  const areAllValuesZero = useMemo(
    () => datasetValues.every(value => value === 0),
    [datasetValues]
  );

  const chartData = useMemo(
    () => ({
      labels: areAllValuesZero ? [emptyMessage] : datasetLabels,
      datasets: [
        {
          data: areAllValuesZero ? [1] : datasetValues,
          backgroundColor: areAllValuesZero ? [light.grayPale] : datasetColors,
          borderColor: areAllValuesZero ? [light.grayPale] : datasetColors,
          borderWidth: 1,
        },
      ],
    }),
    [
      datasetValues,
      datasetLabels,
      datasetColors,
      areAllValuesZero,
      emptyMessage,
    ]
  );

  const options = useMemo(
    () => ({
      tooltips: {
        enabled: true,
        callbacks: {
          label: tooltipItem => {
            const label = chartData.labels[tooltipItem.index];
            if (label === emptyMessage) return emptyMessage;
            return `${label}: ${datasetValues[tooltipItem.index]}%`;
          },
        },
      },
      plugins: { datalabels: { display: false } },
      legend: { display: false },
      hover: { mode: true },
    }),
    [chartData, datasetValues, emptyMessage]
  );

  if (loading) return <GenericLoading className="allocation-card" />;

  return (
    <GraphContainer className="financial-assets">
      <Span className="financial-assets">{titleCard}</Span>
      <GraphWrapper className="financial-assets">
        <DoughnutChart
          data={chartData}
          options={options}
          className="financial-assets"
        />
        <LabelContainer className="financial-assets">
          {data.map(({ fund, percentage }, index) => (
            <DescriptionContainer
              className={`financial-assets ${index === 0 ? 'first' : ''}`}
              backgroundColor={colors[fund] || light.grayPale}
              key={fund}
            >
              <Span className="allocation-card percentage">
                {percentage?.formatted}
              </Span>
              <Span className="allocation-card name">{fund}</Span>
            </DescriptionContainer>
          ))}
        </LabelContainer>
      </GraphWrapper>
    </GraphContainer>
  );
};
