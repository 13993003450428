import styled from 'styled-components';

export const Label = styled.label`
  &.divider {
    width: 50%;
  }
  &.input-file {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  &.simple-modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 5px;
    margin: 0;

    &.min-size {
      width: min-content;
      height: min-content;
    }

    &.after {
      margin-top: 38px;
    }

    &.at-end {
      align-self: end;
    }

    &.only-it {
      width: 100%;
    }

    &.min-width {
      width: min-content;
      white-space: nowrap;
    }
  }

  &.generic-dream-modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0;
    padding: 0;
    height: min-content;

    &.term {
      margin-left: 32px;
    }

    &.first {
      margin-top: 38px;
    }

    &.after {
      margin-top: 32px;
    }

    &.half {
      width: 163px;

      &.last {
        margin-left: 32px;
      }
    }
  }

  &.normalizing-line-size {
    height: 76px;
    justify-content: space-between;
  }

  &.extract-modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 0.5%;
    width: 100%;
    height: fit-content;
    gap: 14px;
    margin: 0;
    @media (max-width: 1440px) {
      gap: 8px;
    }
    @media (max-width: 1024px) {
      gap: 4px;
    }
  }
`;
