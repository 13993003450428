import React, { useState } from 'react';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { FilledSuitabilityProfile } from './FilledSuitabilityProfile';
import { EmptySuitabilityProfile } from './EmptySuitabilityProfile';
import { getSuitabilityImage } from 'utils/patrimony';

export const Suitability = ({
  updateSuitabilityProfile,
  data,
  loading = true
}) => {
  const [restartTest, setRestartTest] = useState(false);

  const updateProfile = () => {
    setRestartTest(true);
  };

  const goBackToProfile = () => {
    setRestartTest(false);
  };

  if (loading) {
    return <GenericLoading className="empty-suitability-questionnaire" />
  }

  if (data?.profile !== 'Indefinido' && !restartTest) {
    return (
      <div style={{ width: '100%' }}>
        <FilledSuitabilityProfile
          updateProfile={updateProfile}
          profileName={data?.profile}
          profileDescription={data?.description}
          profileImage={getSuitabilityImage(data?.profile)}
        />
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <EmptySuitabilityProfile
        changeProfile={updateSuitabilityProfile}
        data={data?.questions}
        initialPage={!restartTest ? 'starting' : 'doing'}
        goBackToProfile={goBackToProfile}
      />
    </div>
  );
};
