import React from 'react';
import { DatePicker } from 'components/organisms/DatePicker';

export const ModalDatePicker = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = new Date(),
  left,
}) => {
  return (
    <DatePicker
      className={className}
      getInputValue={getInputValue}
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      left={left}
    />
  );
};
