import styled from 'styled-components';
import React from 'react';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

const StyledH3 = ({
  color,
  fontSize,
  loading,
  text,
  textAlign,
  width,
  height,
  margin,
}) => {
  if (loading) {
    return <></>;
  }

  const config = {
    color,
    fontSize,
    loading,
    textAlign,
    width,
    height,
    margin,
  };

  return <h3 {...config}>{text}</h3>;
};
export default StyledH3;

export const H3 = styled.h3`
  align-self: center;
  &.retirement-card {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    color: ${({ theme }) => theme.blueRoyal};
    @media (max-width: 1650px) {
      font-size: 32px;
      line-height: 32px;
    }
    @media (max-width: 1440px) {
      font-size: 26px;
    }
    &.selected {
      font-size: 40px;
      line-height: 40px;
      font-weight: 600;
      @media (max-width: 1650px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
    &.selected {
      color: ${({ theme }) => theme.white};
    }
  }
  &.home {
    color: ${({ theme }) => theme.blueMidnight};
    font-weight: 450;
    line-height: 1.5;
    font-size: 1.25rem;

    @media (max-width: 1650px) {
      font-size: 1.2rem;
    }
    @media (max-width: 1500px) {
      font-size: 1rem;
    }
    @media (max-width: 1280px) {
      font-size: 0.8rem;
    }
  }

  &.title-card {
    color: ${({ theme }) => theme.blueMidnight};
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-size: 20px;
    font-weight: 450;
    line-height: 1.5;
  }

  &.home-loading {
    width: 217px;
    height: 17px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 50px;
    color: transparent;
  }

  &.receipts,
  &.spending,
  &.investments,
  &.debts,
  &.selected {
    font-size: 28px;
    font-family: ${({ theme }) => theme.fonts.PoppinsBold.fontFamily},
      sans-serif;
    align-self: self-start;
  }

  &.selected {
    color: ${({ theme }) => theme.white};
  }

  &.receipts {
    color: ${({ theme }) => theme.blueNavy};
  }
  &.spending {
    color: ${({ theme }) => theme.blueSky};
  }
  &.investments {
    color: ${({ theme }) => theme.greenDark};
  }

  &.debts {
    color: ${({ theme }) => theme.redMedium};
  }

  &.passive {
    margin-top: 0.6vh;
    color: ${({ theme }) => theme.redMedium};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-size: 2.5rem;

    @media (max-width: 1650px) {
      font-size: 2rem;
    }

    @media (max-width: 1500px) {
      font-size: 1.5rem;
    }

    @media (max-width: 1280px) {
      font-size: 1rem;
    }

    &.blue {
      color: ${({ theme }) => theme.blueRoyal};
    }

    @media (max-width: 1023px) {
      margin-top: 2px;
      font-size: 21px;
      line-height: 21px;
    }
  }
  &.selected-patrimony {
    margin-top: 0.6vh;
    color: ${({ theme }) => theme.white};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-size: 2.5rem;

    @media (max-width: 1650px) {
      font-size: 2rem;
    }

    @media (max-width: 1500px) {
      font-size: 1.5rem;
    }

    @media (max-width: 1280px) {
      font-size: 1rem;
    }

    @media (max-width: 1023px) {
      margin-top: 2px;
      font-size: 21px;
      line-height: 21px;
    }
  }

  &.goal-panel {
    margin-top: 6px;
    color: ${({ theme }) => theme.blueRoyal};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
    font-size: 36px;

    &.hovered {
      color: ${({ theme }) => theme.white};
    }

    @media (max-width: 1600px) {
      font-size: 26px;
    }
  }
`;
