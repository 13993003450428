import React from 'react';
import { Container, Info, GroupFile } from './style';
import { Span } from 'components/atoms/Span';
import FileIcon from 'components/atoms/icons/FileIcon';
import { Button } from 'components/atoms/Button';
import toast from 'react-hot-toast';

export const LineFile = ({ file }) => {
  const handleDownloadFile = () => {
    if (!file?.url) {
      toast.error("URL do arquivo não disponível.");
      return;
    }

    const link = document.createElement("a");
    link.href = file.url;

    if (file.url.startsWith(window.location.origin)) {
      link.download = file.name || "download";
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      <GroupFile>
        <FileIcon className={'modal-upload line-icon'} />
        <Info>
          <Span className={'modal-upload line-header'}>
            {file?.created_at.formatted || 'dd/mm/aaaaa'}
          </Span>
          <Span className={'modal-upload line-body'}>
            {file?.name || 'Example.pdf'}
          </Span>
        </Info>
      </GroupFile>
      <Button
        className="primary border-gray-silver text-color-gray-silver font-size-12"
        onClick={handleDownloadFile}
      >
        Baixar arquivo
      </Button>
    </Container>
  );
};
