import React from 'react';
import { ModalFooter } from './styles';
import { Button } from 'components/atoms/Button';
import { GenericLoading } from 'components/atoms/GenericLoading';

const BUTTON_COLOR_CLASSES = {
  blue: 'blue-button',
  red: 'red-button',
  transparent: 'transparent-button',
  blue_sky: 'blue-sky-button',
};

export const SimpleModalFooter = ({
  className = '',
  onCancel,
  onConfirm,
  onExclude,
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Concluído',
  excludeButtonText = 'Excluir',
  loading = false,
  loadingClassname = 'loading',
  buttonOrder = ['confirm', 'exclude', 'cancel'],
  confirmButtonColor = 'blue',
  excludeButtonColor = 'red',
  cancelButtonColor = 'transparent',
}) => {
  if (loading) {
    return (
      <ModalFooter>
        {[...Array(2)].map((_, i) => (
          <GenericLoading key={i} className={`${loadingClassname} ${i === 0 ? 'first' : 'second'}`} />
        ))}
      </ModalFooter>
    );
  }

  const renderButton = (type, text, onClick, color) =>
    onClick && (
      <Button className={`${className} ${BUTTON_COLOR_CLASSES[color] || ''}`} onClick={onClick}>
        {text}
      </Button>
    );

  const buttonConfig = {
    confirm: { text: confirmButtonText, onClick: onConfirm, color: confirmButtonColor },
    exclude: { text: excludeButtonText, onClick: onExclude, color: excludeButtonColor },
    cancel: { text: cancelButtonText, onClick: onCancel, color: cancelButtonColor },
  };

  return (
    <ModalFooter>
      {buttonOrder.map((type) => renderButton(type, buttonConfig[type]?.text, buttonConfig[type]?.onClick, buttonConfig[type]?.color))}
    </ModalFooter>
  );
};
