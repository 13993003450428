import React, { useEffect, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import {
  useDeleteActivePatrimony,
  useUpdateActivePatrimony,
} from 'hooks/useActivePatrimony';
import { useRetirementOverview } from 'hooks/useRetirement';
export const EditRealEstateList = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  theme = 'receipts',
  reloadPatrimonies = () => {},
}) => {
  const { updateActivePatrimony } = useUpdateActivePatrimony();
  const { deleteActivePatrimony } = useDeleteActivePatrimony();
  const { reloadRetirementOverview } = useRetirementOverview();
  const [name, setName] = useState(selectedItem?.name);
  const [value, setValue] = useState(selectedItem?.value.raw);
  const [netRevenue, setNetRevenue] = useState(selectedItem?.net_revenue.raw);
  const [rent, setRent] = useState(selectedItem?.rent.raw);
  const [monthlyExpenses, setMonthlyExpenses] = useState(
    selectedItem?.monthly_expenses.raw
  );
  const [profitability, setProfitability] = useState(
    selectedItem?.profitability.raw
  );
  const [recommendation, setRecommendation] = useState(
    selectedItem?.recommendation
  );
  const onDelete = async () => {
    await deleteActivePatrimony(selectedItem.id);
    reloadPatrimonies();
    reloadRetirementOverview();
  };

  const confirm = async () => {
    const activePatrimonyRent = {
      name,
      rent,
      monthly_expenses: monthlyExpenses,
      recommendation,
      value,
    };

    await updateActivePatrimony(
      selectedItem.id,
      activePatrimonyRent,
      setShowModal
    );
    reloadPatrimonies();
    reloadRetirementOverview();
  };

  const options = [
    { label: 'Vender', value: 'vender' },
    { label: 'Alugar', value: 'Alugar' },
    { label: 'Manter', value: 'Manter' },
  ];

  useEffect(() => {
    setProfitability(((rent - monthlyExpenses) / value) * 100);
    setNetRevenue(rent - monthlyExpenses);
  }, [rent, monthlyExpenses, value]);

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Imóvel"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="generic-dream-modal"
          label="Nome do imóvel"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.GroupInputs show={true} className="generic-dream-modal">
          <Modal.InputCurrency
            className="generic-dream-modal first half "
            label="Valor de mercado "
            getInputValue={setValue}
            defaultValue={value}
          />
          <Modal.InputCurrency
            className="generic-dream-modal first half "
            label="Renda (aluguel) "
            getInputValue={setRent}
            defaultValue={rent}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show={true} className="generic-dream-modal">
          <Modal.InputCurrency
            className="generic-dream-modal first half  "
            label="Receita líquida"
            getInputValue={setNetRevenue}
            disabled
            defaultValue={netRevenue}
          />
          <Modal.InputCurrency
            className="generic-dream-modal first half last  "
            label="Despesas mensais"
            getInputValue={setMonthlyExpenses}
            defaultValue={monthlyExpenses}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs
          show={true}
          className="generic-dream-modal column-at-end"
        >
          <Modal.PercentageField
            className="generic-dream-modal first half "
            label="Rentabilidade "
            getInputValue={setProfitability}
            defaultValue={profitability}
            disabled
          />
          <Modal.Dropdown
            label="Recomendação"
            className="generic-dream-modal after half last second-section"
            selectOptions={options}
            getDropdownValue={setRecommendation}
            placeholderSelect={recommendation}
          />
        </Modal.GroupInputs>
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={onDelete}
        className={`simple-modal ${theme}`}
        confirmButtonText="Confirmar"
        cancelButtonText="Excluir imóvel"
        cancelButtonColor = 'red'
        buttonOrder={['cancel', 'confirm', 'exclude']}
      />
    </Modal.Container>
  );
};
