import styled from "styled-components";

export const Container = styled.div`
    &.asset-management {
        width: 25px;
        height: 27px;
    }
`;

export const Icon = styled.svg`
    &.passive {
        width: 25px;
        height: 25px;

    }

    &.selected-patrimony {
        width: 25px;
        height: 25px;

        path{
            stroke: ${({ theme }) => theme.white};
        }
    }

    &.asset-management {
        width: 25px;
        height: 27px;

        path {
            fill: ${({ theme }) => theme.blueRoyal};
        }
    }
`;