import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 32px;
    gap: 24px;
    max-width: 50%;
`;

export const QuestionOptionsContainer = styled.div`
    border-bottom: 1px solid ${props => props.theme.grayShadow};
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;

    &:hover, &.selected {
        background-color: ${props => props.theme.suitabilityOptionHover};

        span {
            color: ${props => props.theme.grayShadow};
        }
    }
`;