import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: ${({ isDragging, theme }) =>
    isDragging
      ? ` 2px dashed ${theme.blueMidnight}`
      : `1.5px solid ${theme.grayPale}`};
  background-color: ${({ isDragging }) =>
    isDragging ? '#f0f8ff' : 'transparent'};
  width: 355px;
  height: 100px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
