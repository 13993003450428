import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Container, SidebarIconContainer, Row } from './styles';
import SmallerLogo from 'components/atoms/icons/SmallLogo';
import BiggerLogo from 'components/atoms/icons/BiggerLogo';
import DashboardIcon from 'components/atoms/icons/DashboardIcon';
import CloudIcon from 'components/atoms/icons/Cloud';
import DividerIcon from 'components/atoms/icons/DividerIcon';
import BudgetManagementIcon from 'components/atoms/icons/BudgetManagement';
import RetirementPlanningIcon from 'components/atoms/icons/RetirementPlanningIcon';
import RiskManagementIcon from 'components/atoms/icons/RiskManagementIcon';
import AssetManagementIcon from 'components/atoms/icons/AssetManagementIcon';
import TaxPlanningIcon from 'components/atoms/icons/TaxPlanningIcon';
import SuccessionPlanningIcon from 'components/atoms/icons/SuccessionPlanningIcon';
import NewReleaseIcon from 'components/atoms/icons/NewReleaseIcon';
import ProfileIcon from 'components/atoms/icons/ProfileIcon';
import ExitIcon from 'components/atoms/icons/ExitIcon';
import CloseIcon from 'components/atoms/icons/CloseIcon';
import ExpandMenuIcon from 'components/atoms/icons/ExpandMenuIcon';
import { validatePathname } from 'utils/navigation';
import { Span } from 'components/atoms/Span';
import { AddRealease } from 'components/molecules/NewRealeaseModal';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/modules/auth/actions';
import { useHistory } from 'react-router-dom';

export default function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const assetManagementUrls = [
    '/finances/patrimony/act',
    '/finances/patrimony/map',
    '/finances/patrimony/planning',
  ];

  const budgetManagementUrls = [
    '/finances/budget',
    '/management/spending',
    '/management/report',
    '/management/detail',
  ];

  const RetirementUrls = [
    '/retirement/map',
    '/retirement/planning',
    '/retirement/act',
  ];

  const icons = [
    {
      Icon: expanded ? CloseIcon : ExpandMenuIcon,
      onClick: () => {
        setExpanded(!expanded);
      },
      className: 'sidebar',
    },
    {
      Icon: DashboardIcon,
      description: 'Dashboard',
      className: `sidebar ${validatePathname('/home') &&
        'in-page'} dashboard-icon`,
      onClick: () => history.push('/home'),
    },
    {
      Icon: CloudIcon,
      description: 'Plano de vida',
      className: `sidebar ${validatePathname('/goals/goalPanel') && 'in-page'}`,
      onClick: () => history.push('/goals/goalPanel'),
    },
    {
      Icon: DividerIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
    },
    {
      Icon: BudgetManagementIcon,
      description: 'Gestão Orçamentária',
      className: `sidebar ${validatePathname(budgetManagementUrls) &&
        'in-page'}`,
      onClick: () => history.push('/finances/budget'),
    },
    {
      Icon: RetirementPlanningIcon,
      description: 'Plano de Aposentadoria',
      className: `sidebar ${validatePathname(RetirementUrls) && 'in-page'}`,
      onClick: () => history.push('/retirement/map'),
    },
    {
      Icon: RiskManagementIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
      description: 'Gestão de Risco',
      onClick: () => history.push('/under-construction'),
    },
    {
      Icon: DividerIcon,
      className: `sidebar`,
    },
    {
      Icon: AssetManagementIcon,
      description: 'Gestão Patrimonial',
      className: `sidebar ${validatePathname(assetManagementUrls) &&
        'in-page'}`,
      onClick: () => history.push('/finances/patrimony/map'),
    },
    {
      Icon: TaxPlanningIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
      description: 'Planejamento Tributário',
      onClick: () => history.push('/under-construction'),
    },
    {
      Icon: SuccessionPlanningIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
      description: 'Planejamento Sucessório',
      onClick: () => history.push('/under-construction'),
    },
    {
      Icon: DividerIcon,
      className: `sidebar`,
    },
    {
      Icon: NewReleaseIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
      description: 'Novo Lançamento',
      onClick: () => setShowModal(true),
    },
    {
      Icon: ProfileIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
      description: 'Perfil',
      onClick: () => history.push('/under-construction'),
    },
    {
      Icon: ExitIcon,
      className: `sidebar ${validatePathname('/') && 'in-page'}`,
      description: 'Sair',
      onClick: () => dispatch(signOut()),
    },
  ];

  return (
    <Container expanded={expanded}>
      <div className="logo">
        {expanded ? (
          <BiggerLogo className={'sidebar'} />
        ) : (
          <SmallerLogo className={'sidebar'} />
        )}
      </div>
      {icons.map((icon, index) => (
        <Row key={index}>
          <SidebarIconContainer
            onClick={icon.onClick}
            key={index}
            className={icon.className}
            data-tip={!expanded ? icon.description : ''}
          >
            <icon.Icon className={icon.className} onClick={icon.onClick} />
          </SidebarIconContainer>
          {expanded && icon.description && (
            <Span className={icon.className} onClick={icon.onClick}>
              {icon.description}
            </Span>
          )}
        </Row>
      ))}
      <ReactTooltip
        place="right"
        backgroundColor="#697BFF"
        effect="solid"
        delayShow={200}
      />
      {showModal && (
        <AddRealease showModal={showModal} setShowModal={setShowModal} />
      )}
    </Container>
  );
}
