import React from 'react';
import { Header } from 'components/templates/Header';

export const AssetManagementHeader = ({
  toMap = '/finances/patrimony/map',
  toPlanning = '/finances/patrimony/planning',
  toAct = '/finances/patrimony/act',
}) => {
  return (
    <Header.RootContainer className="full">
      <Header.GroupItens className="budget-table-client-side">
        <Header.Title
          className="map-header gray-silver"
          loadingClassName="map-header-loading"
          text="Gestão Patrimonial"
        />
      </Header.GroupItens>
      <Header.GroupItens
        loadingClassName="map-loading"
        className="budget-table-list"
      >
        <Header.BudgetMapListWithoutDropdown
          toAct={toAct}
          toMap={toMap}
          toPlanning={toPlanning}
        />
      </Header.GroupItens>
      <Header.GroupItens className="input-side" loadingClassName="loading">
        <Header.SearchBar
          className="header"
          loadingClassName="invisible"
          placeholder="Pesquisar..."
        />
      </Header.GroupItens>
    </Header.RootContainer>
  );
};
