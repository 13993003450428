import React from 'react';
import { StyledIcon } from './styles';

function FileIcon({ className }) {
  return (
    <StyledIcon
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.6 0.5V6.38C13.6 7.77176 14.7282 8.9 16.12 8.9H22M1 4.7V17.3C1 19.6196 2.8804 21.5 5.2 21.5H17.8C20.1196 21.5 22 19.6196 22 17.3V8.51818C22 7.30517 21.4756 6.1514 20.5616 5.3538L16.1866 1.53562C15.4216 0.867908 14.4405 0.5 13.425 0.5H5.2C2.88041 0.5 1 2.3804 1 4.7Z"
        stroke="#140361"
      />
    </StyledIcon>
  );
}

export default FileIcon;
