import styled from 'styled-components';

export const P = styled.p`
  margin: 0;
  &.start-center {
    justify-content: center;
  }

  &.summary-card {
    width: 100%;
    color: ${({ theme }) => theme.grayShadow};
    display: flex;
    font-size: small;
    font-weight: 250;
    align-items: center;
    height: 100%;
    text-align: center;
    margin: 0;
  }

  &.dream-panel {
    color: ${({ theme }) => theme.white};
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily};
  }

  &.time-to-achieve-dream {
    padding: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 350;
  }

  &.fully-invested-dream {
    font-size: 12px;
    font-weight: 350;
  }

  &.simple-modal {
    height: min-content;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.1rem;

    &.debts,
    &.delete {
      color: ${({ theme }) => theme.redMedium};
    }

    &.receipt {
      color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    &.edit {
      color: ${({ theme }) => theme.blueMidnight};
    }

    &.spendings {
      color: ${({ theme }) => theme.blueSky};
    }
  }

  &.generic-dream {
    font-size: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme.blueMidnight};
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 10px;
    align-self: center;
  }

  &.filled-suitability-questionnaire {
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.grayShadow};
    max-width: 564px;
    text-align: justify;
    padding-right: 8px;

    b {
      font-weight: 700;
      color: ${({ theme }) => theme.grayDark};
    }
  }

  &.extract-modal {
    height: min-content;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.1rem;
    color: ${({ theme }) => theme.blueMidnight};
  }

  &.page-under-construction {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.grayShadow};
  }

  &.page-under-construction.first {
    margin-top: 2.4vw;

    @media (min-width: 1920px) {
      margin-top: 46px;
    }
  }

  &.page-under-construction.second {
    margin-top: 1.6vw;

    @media (min-width: 1920px) {
      margin-top: 31px;
    }
  }
`;
