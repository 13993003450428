import React, { useState } from "react";
import { Modal } from "components/templates/Modal";

export const SimpleAddModal = ({
    showModal = false,
    setShowModal = () => { },
    icon = 'add',
    title = "Adicionar categoria",
    inputPlaceholder = '',
    inputLabel = 'Nome do item',
    dropdownPlaceholder = '',
    classificationOptions = [],
    dropdownLabel = 'Classificação',
    confirmButtonText = 'Adicionar categoria',
    cancelButtonText = 'Concluído',
    onConfirm = () => { },
    onCancel = () => { },
    theme,
    receipts = false,
}) => {

    const [name, setName] = useState('');
    const [classification, setClassification] = useState('');

    return (
        <Modal.Container
            showModal={showModal}
            setShowModal={setShowModal}
            className="simple-modal edit"
        >
            <Modal.TitleContainer>
                <Modal.SimpleTitleContent
                    title={title}
                    onClose={() => setShowModal(false)}
                    type={icon}
                    className="simple-modal"
                    theme={theme}
                />
            </Modal.TitleContainer>
            <Modal.BodyContainer>
                <Modal.InputText
                    className="simple-modal"
                    placeholder={""}
                    label={inputLabel}
                    getInputValue={setName}
                    defaultValue={name}
                />
                <Modal.Dropdown
                    className="simple-modal after"
                    placeholderSelect={receipts ? "Recebimento" : ""}
                    selectOptions={classificationOptions}
                    getDropdownValue={setClassification}
                    label={dropdownLabel}
                />
            </Modal.BodyContainer>
            <Modal.SimpleFooter
                onConfirm={() => {
                    onConfirm({
                        name: name,
                        classification: classification
                    });
                }}
                onCancel={() => {
                    setShowModal(false);
                }}
                className={`simple-modal ${theme}`}
                confirmButtonText={confirmButtonText}
                cancelButtonText={cancelButtonText}
                buttonOrder={['exclude', 'cancel', 'confirm']}
            />
        </Modal.Container>
    );
}