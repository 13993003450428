import styled from 'styled-components';

export const ModalSectionContainer = styled.div`
  &.separate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.width {
    &.medium {
      width: 400px;
    }
  }
`;
