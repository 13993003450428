import React from 'react';
import { Modal } from 'components/templates/Modal';

export const EditPEC = ({
  showModal = false,
  setShowModal = () => { },
  id = null,
  value = null,
  onConfirm = () => { },
  onCancel = () => { },
  theme,
}) => {
  const [pecValue, setPecValue] = React.useState(value || 0);

  const confirm = async () => {
    const updatedPecValue = await onConfirm({
      id,
      value: pecValue
    });

    if (updatedPecValue) {
      setShowModal(false);
    }
  };

  const cancel = () => {
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar PEC"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputCurrency
          className="simple-modal "
          label="Valor atual da provisão de eventuais comprometidos"
          getInputValue={setPecValue}
          defaultValue={pecValue}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Concluído"
        cancelButtonText="Cancelar"
        buttonOrder={['exclude', 'cancel', 'confirm']}
        confirmButtonColor='blue_sky'
      />
    </Modal.Container>
  );
};
