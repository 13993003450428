import React from 'react';
import { useSelector } from 'react-redux';

const ValidateRoles = ({
    children,
    role = '',
}) => {
    const user = useSelector(state => state.auth);
    const child = React.Children.only(children);
    const { onClick, onChange, className, ...restProps } = child.props;

    const combinedClassName = `${className} not-allowed`;

    const modifiedComponent = React.cloneElement(child, {
        ...restProps,
        onClick: undefined,
        onChange: undefined,
        className: combinedClassName,
    });

    const naturalComponent = (
        <>{children}</>
    )

    if (role === 'only-client') {
        const roles = user?.roles || [];

        if (!roles || roles?.length !== 1) {
            return modifiedComponent;
        }

        if (roles[0]?.slug !== 'client') {
            return modifiedComponent;
        }

        return naturalComponent
    }

    return naturalComponent;
};

export default ValidateRoles;
