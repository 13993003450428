import React from 'react';
import { Modal } from 'components/templates/Modal';

export const DeleteFinancialAssets = ({
  showModal = false,
  setShowModal = () => { },
  selectedItem = null,
  onConfirm = () => { },
  onCancel = () => { },
  theme,
}) => {
  const confirm = () => {
    if (selectedItem) {
      onConfirm();
    }
    setShowModal(false);
  };

  const cancel = () => {
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Excluir ativo"
          onClose={() => setShowModal(false)}
          type="delete"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.HighlightedBodyContent
          text="Excluir ativo? "
          highlightedText="Esta ação é permanente e não poderá ser desfeita"
          className="simple-modal body"
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir Ativo"
        cancelButtonText="Cancelar"
        buttonOrder={['cancel', 'confirm']}
      />
    </Modal.Container>
  );
};
