export const pensionTypes = [
  { value: 'open_pension_pgbl', label: 'Previdência Aberta (PGBL)' },
  { value: 'open_pension_vgbl', label: 'Previdência Aberta (VGBL)' },
  {
    value: 'closed_pension_fund',
    label: 'Previdência Fechada (Fundo de Pensão)',
  },
];

export const copOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const irBenefitOptions = copOptions;

export const getCopByValue = value => {
  return copOptions.find(cop => cop.value === value);
};

export const getIrBenefitByValue = value => {
  return irBenefitOptions.find(irBenefit => irBenefit.value === value);
}

export const getPensionTypeByValue = value => {
  return pensionTypes.find(pensionType => pensionType.value === value) || null;
};

export const getPensionTypeByLabel = label => {
  return pensionTypes.find(pensionType => pensionType.label === label);
};

export const pensionActions = [
  { value: 'A definir', label: 'A definir' },
  { value: 'contract', label: 'Contratar' },
  { value: 'redeem', label: 'Resgatar' },
  { value: 'portability', label: 'Portabilidade' },
  { value: 'keep_without_contributions', label: 'Manter sem aportes' },
  { value: 'keep', label: 'Manter' },
  { value: 'increase_contributions', label: 'Aumentar aportes' },
];

export const getPensionActionByValue = value => {
  return (
    pensionActions.find(
      action =>
        action.value === value ||
        action.value.toLowerCase() === value.toLowerCase()
    ) || null
  );
};

export const getPensionActionByLabel = label => {
  return pensionActions.find(action => action.label === label) || null;
};
