import { RootModalContainer } from './Container';
import { ModalTitleContainer } from './Title';
import { SimpleModalTitleContent } from './SimpleModalTitleContent';
import { RootModalBody } from './ModalBody';
import { HighlightedBodyContent } from './HighlightedBodyContent';
import { SimpleModalFooter } from './SimpleModalFooter';
import { ModalInputText } from './ModalInputText';
import { ModalDropdown } from './ModalDropdown';
import { InputContainer } from './InputContainer';
import { ModalSwitch } from './ModalSwitch';
import { ModalInputNumber } from './ModalInputNumber';
import { ModalInputImage } from './ModalInputImage';
import { ModalDatePicker } from './ModalDatePicker';
import { ModalSection } from './ModalSection';
import { ModalFooterWithOneButton } from './ModalFooterWithOneButton';
import { ModalGalleryImage } from './ModalGalleryImage';
import { ModalEmptySpace } from './EmptySpace';
import { ModalInputCurrency } from './ModalInputCurrency';
import { ModalPercentageField } from './ModalPercentageField';
import { ModalInputMask } from './ModalInputMask';

export const Modal = {
  Container: RootModalContainer,
  TitleContainer: ModalTitleContainer,
  SimpleTitleContent: SimpleModalTitleContent,
  BodyContainer: RootModalBody,
  HighlightedBodyContent: HighlightedBodyContent,
  SimpleFooter: SimpleModalFooter,
  InputText: ModalInputText,
  InputNumber: ModalInputNumber,
  InputMask: ModalInputMask,
  Dropdown: ModalDropdown,
  GroupInputs: InputContainer,
  Switch: ModalSwitch,
  InputImage: ModalInputImage,
  DatePicker: ModalDatePicker,
  Section: ModalSection,
  FooterWithOneButton: ModalFooterWithOneButton,
  GalleryImage: ModalGalleryImage,
  EmptySpace: ModalEmptySpace,
  InputCurrency: ModalInputCurrency,
  PercentageField: ModalPercentageField,
};
