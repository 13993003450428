import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Page } from 'components/templates/Page';
import DirecionalButton from 'components/organisms/Direcional';
import { PotDetail } from 'components/organisms/PotDetail';
import usePotDetail from 'hooks/usePotDetail';
import { monthNumberToName, getMonthNumber } from 'utils/date';
import debounce from 'lodash.debounce';

export default function Detail() {
  const {
    loading,
    setDate,
    budgetActual,
    budgetEstimated,
    rawBudgetActual,
    rawBudgetEstimated,
    fetchData,
    date,
    created,
  } = usePotDetail();

  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [prevDate, setPrevDate] = useState(date);

  const day = new Date(date).getDate();

  useEffect(() => {
    if (date instanceof Date && !isNaN(date)) {
      setSelectedYear(String(date.getFullYear()));
      setSelectedMonth(monthNumberToName(date.getMonth() + 1));
    }
  }, [date]);

  const updateDate = useCallback(
    debounce((year, month) => {
      if (year && month) {
        const monthNumber = getMonthNumber(month);
        const formattedDate = `${year}-${String(monthNumber).padStart(
          2,
          '0'
        )}-${day}`;
        if (formattedDate !== date) {
          setDate(formattedDate);
        }
      }
    }, 100),
    [setDate, date]
  );

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      updateDate(selectedYear, selectedMonth);
    }
  }, [selectedYear, selectedMonth, updateDate]);

  useEffect(() => {
    if (date !== prevDate) {
      fetchData(date);
      setPrevDate(date);
    }
  }, [date, prevDate, fetchData]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const startYear = created ? new Date(created).getFullYear() : currentYear;
    return Array.from({ length: currentYear - startYear + 2 }, (_, i) =>
      (startYear + i).toString()
    );
  }, [created]);

  return (
    <Page.RootContainer className="detail">
      <PotDetail
        budgetActual={budgetActual}
        loading={loading}
        budgetEstimated={budgetEstimated}
        rawBudgetEstimated={rawBudgetEstimated}
        rawBudgetActual={rawBudgetActual}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        yearOptions={yearOptions}
        date={date}
        created={created}
      />
      <DirecionalButton />
    </Page.RootContainer>
  );
}
