import styled from 'styled-components';

export const Input = styled.input`
  &.input-file {
    display: none;
  }
  &.header {
    width: 100%;
    height: min-content;
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.inputText};
    outline: none;
    background: transparent;
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};

    ::placeholder {
      color: ${({ theme }) => theme.inputText};
      font-style: italic;
    }

    @media (min-width: 2260px) {
      ::placeholder {
        font-size: 18px;
      }
    }

    @media (max-width: 1920px) {
      ::placeholder {
        font-size: 16px;
        padding: 10px 0 0 0;
      }
    }

    @media (max-width: 1650px) {
      ::placeholder {
        font-size: 14px;
      }
    }

    @media (max-width: 1280px) {
      ::placeholder {
        font-size: 12px;
      }
    }

    @media (max-width: 1024px) {
      ::placeholder {
        font-size: 10px;
      }
    }
  }

  &.budget-table-title {
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.grayPale};
    border-radius: 5px;
    padding: 0;
    margin: 0;

    @media (max-width: 1280px) {
      width: 14px;
      height: 14px;
    }
  }

  &.simple-modal {
    width: 100%;
    height: min-content;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
    font-family: ${props => props.theme.fonts.PoppinsRegular};
    color: ${({ theme }) => theme.grayInputSelect};
    outline: none;
    background: transparent;
    vertical-align: middle;
    font-size: 12px;
    margin-top: 8px;
    padding-bottom: 2px;
    padding-left: 8px;

    ::placeholder {
      color: ${({ theme }) => theme.grayShadow};
      font-style: italic;
      font-size: 12px;
    }

    &.lowest-input {
      width: 35px;
    }

    &.eventual-committed-maturity {
      width: 102px;
    }

    &.eventual-committed-annual-value {
      width: 153px;
    }
  }

  &.generic-dream-modal {
    width: 100%;
    height: min-content;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
    font-size: 13px;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    line-height: 13px;
    color: ${({ theme }) => theme.grayInputSelect};
    outline: none;
    background: transparent;
    vertical-align: middle;
    margin-top: 16px;
    padding-bottom: 2px;
    padding-left: 8px;

    ::placeholder {
      color: ${({ theme }) => theme.grayShadow};
      font-size: 13px;
      line-height: 13px;
    }

    &.second-section {
      margin-top: 16px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.disabled};
  }

  &.generic-dream-modal-datepicker {
    border: none;
    outline: 0;
    width: 90%;
    height: 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    color: ${({ theme }) => theme.grayInputSelect};
    padding-left: 8px;

    &::placeholder {
      color: ${({ theme }) => theme.grayInputSelect};
      font-size: 13px;
      line-height: 13px;
    }
  }

  &.extract-modal {
    width: 100%;
    height: min-content;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    color: ${({ theme }) => theme.grayInputSelect};
    outline: none;
    background: transparent;
    vertical-align: middle;
    font-size: 0.7rem;
    padding-bottom: 2px;
    padding-left: 8px;
    ::placeholder {
      color: ${({ theme }) => theme.grayShadow};
      font-style: italic;
      font-size: 0.6rem;
    }
  }

  &.extract-menu-header {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    font: inherit;
    color: inherit;
    width: min-content;
    text-align: right;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-style: italic;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    @media (max-width: 1440px) {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }
`;
