export const extractNumbers = (inputString) => {
    const numbersOnly = inputString.replace(/[^\d.]/g, '');
    return parseFloat(numbersOnly);
}

const isValidDate = (date) => {
    return !isNaN(Date.parse(date))
}

export const convertKeysToSnakeCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((item) => convertKeysToSnakeCase(item))
    } else if (obj !== null && typeof obj === 'object' && !isValidDate(obj)) {
        return Object.keys(obj).reduce((acc, key) => {
            acc[camelToSnake(key)] = convertKeysToSnakeCase(obj[key])
            return acc
        }, {})
    }
    return obj
}

export const camelToSnake = (str) => {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateClassName = (classes) => {
    return Object.entries(classes)
        .filter(([_, condition]) => condition)
        .map(([className]) => className)
        .join(' ');
}