import styled from 'styled-components';

export const CenteredDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 18px;
`;

export const ContainerRecommendation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ customHeight }) => customHeight}px;
`;
