import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import {
  useCreatePrivatePension,
  useUpdatePrivatePension,
  usePrivatePensions,
} from 'hooks/usePrivatePensions';
import {
  copOptions,
  irBenefitOptions,
  pensionTypes,
} from 'enums/privatePension';
import { useRetirementOverview } from 'hooks/useRetirement';

export const EditFinancialPatrimony = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  theme = 'receipts',
}) => {
  const [fundName, setFundName] = useState(selectedItem?.fund_name);
  const [cnpj, setCnpj] = useState(selectedItem?.cnpj.raw || '00000000000');
  const [currentAmount, setCurrentAmount] = useState(
    selectedItem?.current_amount.raw
  );
  const [monthlyContribution, setMonthlyContribution] = useState(
    selectedItem?.monthly_contribution.raw
  );
  const [cop, setCop] = useState(selectedItem?.cop);
  const [pensionType, setPensionType] = useState(selectedItem?.pension_type);
  const [irBenefit, setIrBenefit] = useState(selectedItem?.ir_benefit);

  const [monthlyReturn, setMonthlyReturn] = useState(
    selectedItem?.monthly_return.raw
  );
  const { createPrivatePension } = useCreatePrivatePension();
  const { updatePrivatePension } = useUpdatePrivatePension();
  const { reloadRetirementOverview } = useRetirementOverview();
  const { reloadPrivatePensions } = usePrivatePensions();

  const confirm = async () => {
    const privatePension = {
      fund_name: fundName,
      cnpj,
      cop: cop.value,
      current_amount: currentAmount,
      monthly_contribution: monthlyContribution,
      pension_type: pensionType.value,
      ir_benefit: irBenefit.value,
      monthly_return: monthlyReturn,
    };

    if (selectedItem.private_pension_id === -1) {
      await createPrivatePension(privatePension, setShowModal);
      reloadPrivatePensions();
      reloadRetirementOverview();
      return;
    }
    await updatePrivatePension(
      selectedItem.private_pension_id,
      privatePension,
      setShowModal
    );
    reloadPrivatePensions();
    reloadRetirementOverview();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Previdência Privada"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="generic-dream-modal"
          label="Nome do plano"
          getInputValue={setFundName}
          defaultValue={fundName}
        />
        <Modal.InputMask
          className="generic-dream-modal after"
          label="CNPJ"
          mask="CNPJ"
          getInputValue={setCnpj}
          defaultValue={cnpj}
        />
        <Modal.GroupInputs show className="generic-dream-modal">
          <Modal.InputCurrency
            className="generic-dream-modal first half "
            label="Montante Atual "
            getInputValue={setCurrentAmount}
            defaultValue={currentAmount}
          />
          <Modal.InputCurrency
            className="generic-dream-modal first half last  "
            label="Contingente Mensal"
            getInputValue={setMonthlyContribution}
            defaultValue={monthlyContribution}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show className="generic-dream-modal">
          <Modal.Dropdown
            className="generic-dream-modal first half"
            selectOptions={copOptions}
            setDropdownValue={setCop}
            label="COP"
            placeholderSelect={cop?.label}
          />
          <Modal.Dropdown
            className="generic-dream-modal no-overflow first half last"
            selectOptions={pensionTypes}
            setDropdownValue={setPensionType}
            label="Tipo de Previdência"
            placeholderSelect={pensionType?.label}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show className="generic-dream-modal column-at-end">
          <Modal.Dropdown
            className="generic-dream-modal first half"
            selectOptions={irBenefitOptions}
            label="Benefício IR "
            setDropdownValue={setIrBenefit}
            placeholderSelect={irBenefit?.label}
          />
          <Modal.PercentageField
            label="Rentabilidade Mens."
            className="generic-dream-modal after half last second-section"
            getInputValue={setMonthlyReturn}
            defaultValue={monthlyReturn}
          />
        </Modal.GroupInputs>
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        className={`simple-modal ${theme}`}
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        
      />
    </Modal.Container>
  );
};
