import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const GoIconContainer = styled.svg`
  &.hide-go-icon {
    display: none;

    path:first-of-type {
      fill: none;
    }

    path:nth-of-type(2) {
      fill: none;
    }
  }

  &.title-card {
    display: flex;
    align-items: flex-start;
    width: 16px;
    height: 16px;

    @media (max-width: 1440px) {
      width: 13px;
      height: 13px;

      path:first-of-type {
        fill: ${({ theme }) => theme.grayMedium};
      }

      path:nth-of-type(2) {
        fill: ${({ theme }) => theme.grayMedium};
      }
    }
  }

  &.budget-table {
    width: 0.83vw;
    height: 1.61vh;
    margin-left: 1.04vw;
    align-self: flex-start;
    cursor: pointer;
    fill: ${({ theme }) => theme.grayMedium};

    @media (max-width: 1023px) {
      width: 8.5px;
      height: 10.9px;
      margin-left: 10.7px;
    }
  }

  &.goal-panel {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &.at-top {
      align-self: start;
    }

    path:first-of-type {
      fill: ${({ theme }) => theme.grayMedium};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.grayMedium};
    }
  }

  &.investment-panel-title {
    width: 14px;
    height: 14px;
    fill: ${({ theme }) => theme.grayMedium};
    margin-left: 20px;
    align-self: flex-start;
    cursor: pointer;
  }
`;

export const LoadingIcon = styled.div`
  width: 32px;
  height: 32px;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  border-radius: 5px;

  &.hide-go-icon {
    display: none;
  }
`;
