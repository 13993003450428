import React, { useState } from 'react';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { CalendarIcon } from 'components/atoms/icons/CalendarIcon';
import { Calendar } from 'components/molecules/Calendar';
import { formatDate, isDatableString, stringToDate } from 'utils/date';
import { ModalDatePickerContainer, ModalDateContainer } from './styles';

export const DatePicker = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = new Date(),
  left,
}) => {
  const [value, setValue] = useState(formatDate(defaultValue) || '');
  const [showModal, setShowModal] = React.useState(false);
  const buttonRef = React.useRef(null);

  const handleChange = e => {
    setShowModal(false);
    let inputValue = e.target.value.replace(/\D/g, '');

    if (inputValue.length > 8) {
      inputValue = inputValue.slice(0, 8);
    }

    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    }
    if (inputValue.length > 5) {
      inputValue = `${inputValue.slice(0, 5)}/${inputValue.slice(5)}`;
    }

    setValue(inputValue);
    getInputValue(stringToDate(inputValue));
  };

  const getCalendarValue = selectedDate => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');

    const formattedDate = `${day}/${month}/${year}`;
    setValue(formattedDate);
    getInputValue(new Date(selectedDate));
  };

  return (
    <ModalDatePickerContainer className={className} ref={buttonRef}>
      <Span className={className}>{label}</Span>

      <ModalDateContainer className={className}>
        <Input
          className={className}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          maxLength={10}
        />
        <CalendarIcon
          className="generic-dream-modal"
          onClick={() => setShowModal(prev => !prev)}
        />
      </ModalDateContainer>
      {showModal && (
        <Calendar
          top={className.includes('top')}
          left={left}
          buttonRef={buttonRef}
          showCalendar={showModal}
          setShowCalendar={setShowModal}
          getValue={getCalendarValue}
          defaultValue={
            isDatableString(value) ? stringToDate(value) : new Date()
          }
        />
      )}
    </ModalDatePickerContainer>
  );
};
