import styled from 'styled-components';

export const StyledContainer = styled.div`
  &.category-title {
    width: min-content;
    height: min-content;
    display: inline-block;
  }

  &.financial-independence {
    width: 23px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
    }
  }

  &.simple-modal,
  &.generic-dream,
  &.extract-modal {
    height: min-content;
    width: min-content;
    display: flex;
    align-items: center;
  }

  &.extract-item {
    width: 23px;
    height: 24px;
    border-radius: 4px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1280px) {
      width: 15.7px;
      height: 16.4px;
      border-radius: 2.1px;
      margin-left: 2.7px;
    }
  }

  &.detailed-extract-item {
    width: 23px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: 18px;

    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.sideBarHover};

      path {
        fill: ${props => props.theme.white};
      }
    }
  }
`;

export const PencilIconContainer = styled.svg`
  &.th {
    cursor: pointer;
    width: 12px;
    height: 14px;
    margin-left: 12px;
    min-width: 10px;
    min-height: 10px;

    path {
      fill: ${({ theme }) => theme.white};
    }
  }
  &.category-title {
    width: 12px;
    height: 14px;
    margin-left: 12px;
    cursor: pointer;
  }

  &.simple-modal {
    width: 19px;
    height: 21px;
    cursor: pointer;

    fill: ${({ theme }) => theme.blueMidnight};

    &.debts {
      fill: ${({ theme }) => theme.redMedium};

      path {
        fill: ${({ theme }) => theme.redMedium};
      }
    }

    &.spendings {
      fill: ${({ theme }) => theme.blueSky};

      path {
        fill: ${({ theme }) => theme.blueSky};
      }
    }

    &.receipt {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};

      path {
        fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      }
    }
    &.patrimony {
      fill: ${({ theme }) => theme.blueMidnight};

      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }
    }
  }

  &.extract-modal {
    width: 19px;
    height: 21px;
    cursor: pointer;
    fill: ${({ theme }) => theme.blueMidnight};

    @media (max-width: 1440px) {
      width: 14px;
      height: 16px;
    }

    @media (max-width: 1024px) {
      width: 10.13px;
      height: 11.2px;
    }
  }

  &.financial-independence {
    width: 12px;
    height: 14px;
    margin-top: 5px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.white};
    }
  }

  &.generic-dream {
    width: 19px;
    height: 21px;
    cursor: pointer;

    &.edit {
      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }
    }
  }

  &.extract--category-item {
    width: 12px;
    height: 14px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.grayShadow};
    }

    @media (max-width: 1440px) {
      width: 9px;
      height: 10.5px;
    }

    &.disable {
      cursor: not-allowed;

      path {
        opacity: 0.4;
      }
    }
  }

  &.detailed-extract-item {
    width: 12px;
    height: 14px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.grayShadow};
    }

    @media (max-width: 1440px) {
      width: 9px;
      height: 10.5px;
    }
    &.disable {
      cursor: not-allowed;
      path {
        opacity: 0.4;
      }
    }
  }
`;
