import styled from 'styled-components';

export const H6 = styled.h6`
  &.retirement-card {
    color: ${({ theme }) => theme.blueRoyal};
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily},
      sans-serif;
    &.selected {
      color: ${({ theme }) => theme.white};
    }
  }

  &.table-progress {
    display: flex;
    justify-content: flex-start;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily},
      sans-serif;
    font-size: 12px;
    color: ${({ theme }) => theme.grayMedium};

    @media (max-width: 1440px) {
      font-size: 10px;
    }
  }
  &.apertura-medium {
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium.fontFamily},
      sans-serif;
    font-size: 24px;
  }

  &.up-button {
    font-size: 8px;
    color: ${({ theme }) => theme.blueMidnightOpacity};
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily},
      sans-serif;
  }

  &.avatar {
    font-size: 20px;
    font-weight: 450;
    line-height: 20px;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.selected {
    color: ${({ theme }) => theme.white};
  }
  &.spending {
    color: ${({ theme }) => theme.blueSky};
  }
  &.spending,
  &.selected {
    font-size: 14px;

    @media (max-width: 1600px) {
      font-size: 10px;
    }
  }
  &.passive {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 14px;
  }
  &.blue {
    color: ${({ theme }) => theme.blueMidnight};
    font-size: 10px;
    @media (max-width: 1023px) {
      font-size: 8px;
      line-height: 12.8px;
    }
  }

  &.selected-patrimony {
    color: ${({ theme }) => theme.white};
    font-size: 14px;
    @media (max-width: 1023px) {
      font-size: 8px;
      line-height: 12.8px;
    }
  }

  &.legend-table {
    color: ${({ theme }) => theme.blueSky};
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular.fontFamily},
      sans-serif;

    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }
`;
