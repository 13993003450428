import api from 'services/api';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    defaultNavigationCards,
    defaultFinancialAssets,
    defaultAllocationReport,
    defaultEquityLiquidity,
    defaultSuitability
} from 'constants/assets';

export const usePlanningAssets = () => {
    const { id: userId } = useSelector(state => state.user.profile);
    const [loading, setLoading] = useState(true);
    const [navigationCards, setNavigationCards] = useState(defaultNavigationCards);
    const [financialAssets, setFinancialAssets] = useState(defaultFinancialAssets);
    const [allocationReport, setAllocationDefault] = useState(defaultAllocationReport);
    const [equityLiquidity, setEquityLiquidity] = useState(defaultEquityLiquidity);
    const [suitability, setSuitability] = useState(defaultSuitability);
    const [currentPage, setCurrentPage] = useState('financial-assets');
    const [firstTime, setFirstTime] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [toastMessage, setToastMessage] = useState({
        type: '',
        message: '',
        show: false
    });

    const pages = useMemo(() => ({
        'financial-assets': 'financial_assets',
        'suitability': 'suitability',
        'liquidity': 'immediate_liquidity'
    }), []);

    const changePage = useCallback(async () => {
        try {
            if (isFetching) return;

            setIsFetching(true);
            setLoading(true);

            if (currentPage === 'liquidity' && equityLiquidity !== defaultEquityLiquidity) {
                return
            }

            if (currentPage === 'suitability' && suitability !== defaultSuitability) {
                return
            }

            if (currentPage === 'financial-assets' && (financialAssets !== defaultFinancialAssets || allocationReport !== defaultAllocationReport)) {
                return
            }

            const { data } = await api.get(`/patrimony/v2/report/${userId}`, {
                params: {
                    formatted_value: true,
                    navigation_cards: navigationCards === defaultNavigationCards,
                    [pages[currentPage]]: true
                },
            });

            if (data?.financial_assets) {
                const { allocation_report, investment_panel } = data?.financial_assets;
                setFinancialAssets(investment_panel);
                setAllocationDefault(allocation_report);
            }

            if (data?.equity_liquidity) {
                setEquityLiquidity(data?.equity_liquidity)
            }

            if (data?.suitability) {
                setSuitability(data?.suitability)
            }

            if (data?.navigation_cards) {
                setNavigationCards(data?.navigation_cards);
            }
            return { data };
        } catch (error) {
            setToastMessage({
                show: true,
                type: 'error',
                message: 'Não foi possível buscar os dados da página!'
            })
            return { error };
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }, [
        currentPage,
        userId,
        pages,
        navigationCards,
        financialAssets,
        allocationReport,
        equityLiquidity,
        suitability,
        isFetching
    ]);

    const updateSuitability = useCallback(async (answers) => {
        try {
            setLoading(true);
            const { data } = await api.post(
                `/patrimony/suitability`,
                {
                    answers,
                    user_id: userId
                }
            );

            const res = await api.get(`/patrimony/v2/report/${userId}`, {
                params: {
                    formatted_value: true,
                    navigation_cards: true,
                    [pages[currentPage]]: true
                },
            });

            setNavigationCards(res?.data?.navigation_cards || defaultNavigationCards);
            setSuitability(res?.data?.suitability || defaultSuitability);

            return {
                success: true,
                message: data?.message
            }
        } catch (error) {
            console.error(error);
            setToastMessage({
                show: true,
                type: 'error',
                message: 'Não foi possível atualizar o perfil de suitability!'
            })
            return {
                success: false,
                message: error?.response?.data?.message
            };
        } finally {
            setLoading(false);
        }
    }, [userId, pages, currentPage]);

    useEffect(() => {
        if (toastMessage.show) return

        if ((loading && firstTime) || (!loading && !firstTime)) {
            changePage();
            setFirstTime(false);
        }
    }, [changePage, currentPage, loading, firstTime, toastMessage]);

    return {
        currentPage,
        setCurrentPage,
        loading,
        setLoading,
        navigationCards,
        fetchData: changePage,
        financialAssets,
        allocationReport,
        equityLiquidity,
        suitability,
        updateSuitability,
        toastMessage
    };
};
