import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  &.primary {
    width: 106px;
    height: 28px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.graySilver};
  }
  &.border-gray-silver {
    border: 1px solid ${props => props.theme.graySilver};
  }
  &.border-red {
    border: 1px solid ${props => props.theme.redMedium};
  }
  &.text-color-gray-silver {
    color: ${props => props.theme.graySilver};
  }
  &.font-size-12 {
    font-size: 0.75rem;
  }
  &.simple-modal {
    font-size: 0.9rem;
    border-radius: 4px;
    padding: 0 8px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.calendar-button {
    background-color: ${props => props.theme.blueRoyal};
    padding: 4px 10px;
    border-radius: 14px;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.7rem;
    font-weight: 400;
    border: none;
    color: ${props => props.theme.whiteWithSlowOpacity};
    height: min-content;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &.generic-dream-modal {
    width: 163px;
    min-width: 100px;
    background-color: ${props => props.theme.blueRoyalWithSlowOpacity};
    padding: 5px;
    border-radius: 4px;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 18px;
    font-weight: 400;
    border: none;
    color: ${props => props.theme.white};
    height: min-content;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.5;
    }
    &.new-realease {
      font-size: 1rem;
    }
  }

  &.empty-suitability-questionnaire,
  &.filled-suitability-questionnaire {
    height: 50px;
    border-radius: 8px;
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    align-self: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    padding: 16px;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.5;
    }

    &.suitability-form {
      margin-left: 32px;
    }

    @media (max-width: 1440px) {
      height: 40px;
      font-size: 14px;
      line-height: 14px;
      padding: 10px;
    }
  }
  &.filled-suitability-questionnaire {
    margin-bottom: 32px;
  }
  &.retirement-table {
    width: min(100%, 280px);
    height: min(100%, 68px);
    min-width: 100px;
    min-height: 30px;
    font-size: 15px;
    font-weight: 500;

    padding: 5% 10%;
    background-color: ${props => props.theme.grayOpacity};
    color: ${props => props.theme.white};
    border: none;
    border-radius: 6px;
  }
  &.grafic-table {
    width: 60px;
    height: 24px;
    font-size: 14px;
    min-width: 50px;
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    border: none;
    border-radius: 4px;

    &:focus {
      outline: 0;
    }
  }
  &.pot-detail {
    width: 120px;
    height: 24px;
    font-size: 14px;
    min-width: 50px;
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    border: none;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
  &.report-spending {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.blueSky};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.blueSky};
    }

    &:hover {
      background-color: ${props => props.theme.blueSky};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-receipt {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.blueNavy};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.blueNavy};
    }

    &:hover {
      background-color: ${props => props.theme.blueNavy};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-investment {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.greenDark};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.greenDark};
    }

    &:hover {
      background-color: ${props => props.theme.greenDark};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-debts {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.redMedium};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.selected {
      background-color: ${props => props.theme.redMedium};
    }

    &:hover {
      background-color: ${props => props.theme.redMedium};
    }
    &:focus {
      outline: 0;
    }
  }

  &.extract-modal {
    font-size: 0.75rem;
    border-radius: 4px;
    padding: 0 8px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: 0;
    }
    @media (max-width: 1024px) {
      font-size: 0.6rem;
    }
  }

  &.blue-button {
    background-color: ${props => props.theme.blueRoyal};
    border: none;
    color: ${props => props.theme.white};

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 0;
    }
  }

  &.transparent-button {
    background: transparent;
    border: 1px solid ${props => props.theme.graySilver};
    color: ${props => props.theme.graySilver};

    &:hover {
      background: ${props => props.theme.graySilver};
      color: ${props => props.theme.white};
    }

    &:focus {
      outline: 0;
    }
  }

  &.red-button {
    background-color: ${props => props.theme.redMedium};
    color: ${props => props.theme.white};
    border: none;
    
    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 0;
    }
  }

  &.blue-sky-button {
    background-color: ${props => props.theme.blueSky};
    color: ${props => props.theme.white};
    border: none;

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: 0;
    }
  }
`;
