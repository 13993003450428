import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DropdownTrigger = styled.div`
  height: 30px;
  background-color: transparent;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayShadow};

  &.simple-modal {
    margin-top: 11px;

    &.min-size {
      margin-top: 0;
    }
  }

  &.hide-border {
    border: none;
  }

  &.generic-dream-modal {
    height: 30px;
    margin-top: 11px;
    padding: 8px;
    border-bottom: 1px solid ${props => props.theme.grayShadow};
  }

  &.normalizing-line-size {
    margin-top: 0;
  }
`;

export const DropdownTriggerLabel = styled.span`
  font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
  font-size: 0.7rem;
  line-height: 0.7rem;
  color: ${({ theme }) => theme.graySelectPlaceholder};
  font-weight: 400;

  &.generic-dream-modal {
    font-size: 14px;
    line-height: 14px;

    &.pot-detail {
      font-size: 0.8rem;
      font-weight: 200;
    }
    &.no-overflow {
      text-overflow: ellipsis;
      text-wrap-mode: nowrap;
      overflow: hidden;
    }
  }
`;

export const DropdownTriggerIcon = styled.span`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};
`;

export const DropdownOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.grayPale};
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  max-height: 160px;
  overflow: auto;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;

  &.generic-dream-modal {
    border-radius: 4px;
  }
`;

export const DropdownOptionItem = styled.li`
  width: max-content;
  min-width: 100%;
  padding: 10px 16px;
  cursor: pointer;

  &.selected {
    background-color: ${props => props.theme.selectHover};

    span {
      color: ${props => props.theme.white};
    }
  }

  &:hover {
    background-color: ${props => props.theme.selectHover};

    span {
      color: ${props => props.theme.white};
    }
  }

  &.margin {
    padding-left: 10px;
  }

  &.generic-dream-modal {
    padding: 10px 16px;
    width: 100%;
  }
`;

export const DropdownOptionItemLabel = styled.span`
  font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-weight: 400;
  color: ${props => props.theme.graySilver};
  width: max-content;
  min-width: 100%;
`;
