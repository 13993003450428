export const defaultNavigationCards = {
    financial_assets: {
        raw: 0,
        formatted: "R$ 0"
    },
    suitability_profile: "",
    liquidity: {
        current_liquidity: {
            raw: 0,
            formatted: "0%"
        },
        need_for_liquidity: {
            raw: 0,
            formatted: "0%"
        },
        greater_liquidity: ''
    },
    mismatching_percentage: {
        raw: 0,
        formatted: "0%"
    }
};

export const defaultFinancialAssets = [
    {
        fund_id: null,
        fund: "R. Fixa Pós",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "R. Fixa Pré",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "R. Fixa IPCA",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "Multimercado",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "FIIs",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "Ações BR",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "R.V. Exterior",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    },
    {
        fund_id: null,
        fund: "Alternativo",
        current_value: { raw: 0, formatted: "R$ 0" },
        current_participation_percentage: { raw: 0, formatted: "0%" },
        defined_elp_percentage: { raw: 0, formatted: "0%" },
        final_value: { raw: 0, formatted: "R$ 0" },
        full_allocation_percentage: { raw: 0, formatted: "0%" },
        next_movement_value: { raw: 0, formatted: "R$ 0" }
    }
];

export const defaultAllocationReport = {
    current_values: [
        { fund: 'R. Fixa Pós', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'R. Fixa Pré', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'R. Fixa IPCA', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'Multimercado', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'FIIs', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'Ações BR', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'R.V. Exterior', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'Alternativo', percentage: { raw: 0, formatted: '0%' } }
    ],
    allocation_recommendation: [
        { fund: 'R. Fixa Pós', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'R. Fixa Pré', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'R. Fixa IPCA', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'Multimercado', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'FIIs', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'Ações BR', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'R.V. Exterior', percentage: { raw: 0, formatted: '0%' } },
        { fund: 'Alternativo', percentage: { raw: 0, formatted: '0%' } }
    ]
}
export const defaultEquityLiquidity = {
    current_equity_liquidity: [
        {
            name: "DO",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "Até 30 dias",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "De 1 à 6 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "De 6 à 12 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "De 12 à 24 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "Acima de 24 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        }
    ],
    need_for_equity_liquidity: [
        {
            name: "DO",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "Até 30 dias",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "De 1 à 6 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "De 6 à 12 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "De 12 à 24 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        },
        {
            name: "Acima de 24 meses",
            percentage: {
                raw: 0,
                formatted: "0%"
            }
        }
    ]
}

export const defaultSuitability = {
    profile: "Indefinido",
    description: "",
    questions: [
        {
            id: null,
            description: "",
            possible_answers: [
                {
                    id: null,
                    description: ""
                }
            ]
        }
    ]
};
