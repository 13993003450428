import React, { useState, useCallback, useEffect } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  createSocialSecurityValidationSchema,
  socialSecurityValidationSchema,
} from 'utils/validator';
import { getEmpytSocialSecurity } from 'utils/socialSecurity';

const useSocialSecurity = () => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [items, setItems] = useState(getEmpytSocialSecurity());
  const [loading, setLoading] = useState(true);

  const fetchItems = useCallback(async () => {
    setLoading(true);
    try {
      const { data: items } = await api.get(`social_security/${userId}`);
      setItems(items);
    } catch (error) {
      console.error('Error fetching social security:', error);
      toast.error('Ocorreu um erro ao buscar as previdencias social.');
    } finally {
      setLoading(false);
    }
  }, [userId]);
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);
  const reloadSocialSecurity = useCallback(() => {
    fetchItems();
  }, [fetchItems]);

  return {
    items,
    setItems,
    loading,
    setLoading,
    fetchItems,
    reloadSocialSecurity,
  };
};

const useCreateSocialSecurity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const createSocialSecurity = useCallback(
    async (data, setShowEditItemModal) => {
      const validate = await createSocialSecurityValidationSchema(data);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      setLoading(true);
      setError(null);
      const createPromise = api
        .post(`social_security/${userId}`, data)
        .then(response => {
          setShowEditItemModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao cadastrar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });

      await toast.promise(createPromise, {
        loading: 'Criando item...',
        success: <b>Item atualizado com sucesso!</b>,
        error: <b>Erro ao atualizar o item.</b>,
      });

      return createPromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { createSocialSecurity, loading, error };
};

const useSocialSecurityFiles = socialSecurityId => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchItems = useCallback(async socialSecurityId => {
    setLoading(true);
    try {
      const { data: items } = await api.get(
        `social_security/${socialSecurityId}/files`
      );
      setFiles(items);
    } catch (error) {
      console.error('Error fetching social security:', error);
      toast.error('Ocorreu um erro ao buscar os arquivos de previdencia social');
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchItems(socialSecurityId);
  }, [fetchItems, socialSecurityId]);
  const reloadSocialSecurityFiles = useCallback(() => {
    fetchItems(socialSecurityId);
  }, [fetchItems, socialSecurityId]);

  return {
    files,
    setFiles,
    loading,
    setLoading,
    fetchItems,
    reloadSocialSecurityFiles,
  };
};

const useAttachSocialSecurityFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const attachSocialSecurityFile = useCallback(
    async ({ file, socialSecurityId }) => {
      const validate = await socialSecurityValidationSchema({
        file,
        socialSecurityId,
      });

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      setLoading(true);
      setError(null);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('socialSecurityId', socialSecurityId);
      const createPromise = api
        .post(`social_security/${socialSecurityId}/files`, formData)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao adicionar o arquivo:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });

      await toast.promise(createPromise, {
        loading: 'Enviando arquivo...',
        success: <b>Arquivo enviado com sucesso!</b>,
        error: <b>Erro ao enviar o arquivo.</b>,
      });

      return createPromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { attachSocialSecurityFile, loading, error };
};
const useUpdateSocialSecurity = socialSecurityId => {
  const [loading, setLoading] = useState(true);

  const updateSocialSecurity = useCallback(async (socialSecurityId, data) => {
    setLoading(true);

    try {
      const updatePromise = api.put(
        `social_security/${socialSecurityId}/`,
        data
      );

      await toast.promise(updatePromise, {
        loading: 'Atualizando previdencia privada...',
        success: <b>Previdencia privada atualizada com sucesso!</b>,
        error: <b>Erro ao atualizar a previdencia privada.</b>,
      });
    } catch (error) {
      console.error('Error updating social security:', error);
      toast.error('Ocorreu um erro ao atualizar a previdencia privada.');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    setLoading,
    updateSocialSecurity,
  };
};

export {
  useSocialSecurity,
  useCreateSocialSecurity,
  useAttachSocialSecurityFile,
  useSocialSecurityFiles,
  useUpdateSocialSecurity,
  // useUpdateActivePatrimony,
  // useDeleteActivePatrimony,
};
