/* eslint-disable */
import React from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { RealEstateList } from './RealEstateList';
import { SocialSecurity } from './SocialSecurity';
import { FinancialPatrimony } from './FinancialPatrimony';
import { Rentirement } from './Rentirement';
import { useRetirementPatrimonies } from 'hooks/useActivePatrimony';
import { useSocialSecurity } from 'hooks/useSocialSecurity';
import {
  usePrivatePensions,
  useRetirementFinancialAssets,
} from 'hooks/usePrivatePensions';

import { useWealthGrowth } from 'hooks/usePatrimony';

export const RetirementMapTable = ({ currentPage }) => {
  const pages = {
    socialSecurity: <SocialSecurityListWrapper />,
    rent: <RealEstateListWrapper />,
    financialPatrimony: <FinancialPatrimonyWrapper />,
  };
  return pages[currentPage] || <></>;
};

const FinancialPatrimonyWrapper = () => {
  const {
    retirementFinancialAssets,
    reloadRetirementFinancialAssets,
    loading,
  } = useRetirementFinancialAssets();

  const {
    privatePensions,
    loading: privatePensionsLoading,
  } = usePrivatePensions();

  const { loading: wealthGrowthLoading, wealthGrowth } = useWealthGrowth();
  if (loading || privatePensionsLoading || wealthGrowthLoading) {
    return <LoadingTable />;
  }

  return (
    !loading && (
      <>
        <FinancialPatrimony
          privatePensions={privatePensions}
          reloadRetirementFinancialAssets={reloadRetirementFinancialAssets}
        />
        <Rentirement
          rentirement={retirementFinancialAssets}
          wealthGrowth={wealthGrowth}
        />
      </>
    )
  );
};

const SocialSecurityListWrapper = () => {
  const { items, loading, reloadSocialSecurity } = useSocialSecurity();

  if (loading) {
    return <LoadingTable />;
  }
  return (
    !loading && (
      <SocialSecurity
        items={items}
        reloadSocialSecurity={reloadSocialSecurity}
      />
    )
  );
};
const RealEstateListWrapper = () => {
  const { items, loading, reloadPatrimonies } = useRetirementPatrimonies();

  if (loading) {
    return <LoadingTable />;
  }

  return (
    !loading && (
      <RealEstateList
        patrimonies={items}
        reloadPatrimonies={reloadPatrimonies}
      />
    )
  );
};
