import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';
import Checkbox from 'components/atoms/Checkbox';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { DeleteEquityPassive } from 'components/molecules/PatrimonyMapModal/DeleteEquityPassive';
import { AddPassive } from 'components/molecules/PatrimonyMapModal/AddPassive';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { EditPassive } from 'components/molecules/PatrimonyMapModal/EditPassive';
import { getPassivePatrimonial } from 'utils/patrimony';
import toast from 'react-hot-toast';

export const EquityPassive = ({
  equityPassive,
  tooltipText,
  reloadPage,
  onCreate = () => { },
  onUpdate = () => { },
  onQuit = () => { },
  handleDeleteActive = () => { },
}) => {
  const columnOrder = [
    'name',
    'institution_label',
    'installment',
    'remainingInstallments',
    'rate',
    'inCash',
    'remainingValue',
    'discount',
    'settled_at',
  ];

  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [activeRowData, setActiveRowData] = useState(null);

  const [selectedItems, setSelectedItems] = useState(
    equityPassive.patrimonies.map(() => false)
  );

  const handleCheckboxChange = index => {
    const updatedSelectedItems = selectedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setSelectedItems(updatedSelectedItems);
  };

  const handleSelectAllChange = () => {
    const allSelected = selectedItems.every(item => item);
    const updatedSelectedItems = selectedItems.map(() => !allSelected);
    setSelectedItems(updatedSelectedItems);
  };
  const handleDeleteClick = () => {
    const selectedIds = selectedItems
      .map((isSelected, index) =>
        isSelected ? equityPassive.patrimonies[index]?.id : null
      )
      .filter(id => id !== null);

    if (selectedIds.length > 0) {
      setActiveRowData(selectedIds);
      setShowDelete(true);
    } else {
      toast.error('Selecione pelo menos um item!');
    }
  };

  return (
    <Card.Container className="spending-table">
      <Table.TitleInformation
        nameTable="Passivos Patrimoniais"
        className=" debts patrimony red font-regular "
        tooltipText={tooltipText}
        value={equityPassive.totalFormatted?.formatted}
        percentage={equityPassive.percentage?.formatted}
      />
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title debts">
              <Table.Th className="budget-table-title checkbox first-table-border">
                <Checkbox
                  checked={
                    selectedItems.length > 0 &&
                    selectedItems.every(item => item)
                  }
                  onChange={handleSelectAllChange}
                />
              </Table.Th>
              {getPassivePatrimonial().map((item, index) => (
                <Table.Th className="budget-table-title" key={index}>
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {equityPassive.patrimonies.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                <Table.Td className="receipt-table-body checkbox">
                  <Checkbox
                    checked={selectedItems[rowIndex]}
                    onChange={() => handleCheckboxChange(rowIndex)}
                  />
                </Table.Td>
                {columnOrder.map((item, index) => {
                  let currentItem = column[item];
                  if (item === 'settled_at') {
                    return (
                      <Table.Td className="receipt-table-body " key={index}>
                        <Checkbox
                          checked={column.settled_at !== null}
                          className="circular"
                          onClick={() => {
                            onUpdate(column?.id, {
                              ...column,
                              discount: column?.discount?.raw === null ? 0 : column?.discount?.raw,
                              inCash: column?.inCash?.raw === null ? 0 : column?.inCash?.raw,
                              installment: column?.installment?.raw === null ? 0 : column?.installment?.raw,
                              remainingValue: column?.remainingValue?.raw === null ? 0 : column?.remainingValue?.raw,
                              remainingInstallments: column?.remainingInstallments === null ? 0 : column?.remainingInstallments,
                              rate: column?.rate?.raw === null ? 0 : column?.rate?.raw,
                              settled_at: column?.settled_at === null ? new Date() : null
                            })
                          }}
                        />
                      </Table.Td>
                    );
                  }

                  if (
                    [
                      'rate',
                      'inCash',
                      'remainingValue',
                      'discount',
                      'percentage',
                      'totalFormatted',
                      'installment',
                    ].includes(item)
                  ) {
                    currentItem = currentItem?.formatted;
                  }

                  return (
                    <Table.Td className="receipt-table-body" key={index}>
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td>
                  <PencilIcon
                    className="category-title"
                    onClick={() => {
                      setActiveRowData(column);
                      setShowEdit(true);
                    }}
                  />
                  {showEdit && (
                    <EditPassive
                      showModal={showEdit}
                      setShowModal={setShowEdit}
                      theme="edit"
                      onConfirm={reloadPage}
                      onUpdate={onUpdate}
                      activeProps={activeRowData}
                      onQuit={onQuit}
                      type="patrimonial-passive"
                    />
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
      <Table.GroupIcons>
        <AddIcon
          className="receipt-table"
          onClick={() => {
            setShowAdd(true);
          }}
        />
        {showAdd && (
          <AddPassive
            showModal={showAdd}
            setShowModal={setShowAdd}
            theme="patrimony"
            onConfirm={reloadPage}
            onCreate={onCreate}
            type="patrimonial-passive"
          />
        )}
        <TrashIcon className="receipt-table" onClick={handleDeleteClick} />
        {showDelete && (
          <DeleteEquityPassive
            showModal={showDelete}
            setShowModal={setShowDelete}
            selectedItem={activeRowData}
            onConfirm={() => handleDeleteActive(activeRowData)}
            theme="debts"
          />
        )}
      </Table.GroupIcons>
    </Card.Container>
  );
};
