import React from 'react';
import { Container, Title, ScrollContainer, LoadingContainer } from './styles';
import { H4 } from 'components/atoms/Headings/H4';
import GoIcon from 'components/atoms/icons/GoIcon';
import { Table } from 'components/templates/Tables';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { useHistory } from 'react-router-dom';
import { generateClassName } from 'utils/text';

export const InvestmentPanel = ({
  data,
  loading = true
}) => {
  const history = useHistory();
  const head = [
    'Estratégias',
    'Valores Atuais',
    'Participação Atual',
    'Alocação Estratégica',
    'Full Alocation',
    'Valores Finais',
    'Próximas Movimentações',
  ]

  const tdsOrder = [
    'current_value',
    'current_participation_percentage',
    'defined_elp_percentage',
    'full_allocation_percentage',
    'final_value',
    'next_movement_value'
  ]

  if (loading) {
    return (
      <Container>
        <Title>
          <GenericLoading className="investment-panel-title" />
          <GenericLoading className="investment-panel-go-icon" />
        </Title>
        <ScrollContainer className="loading">
          <LoadingContainer>
            <GenericLoading className="investment-panel-table" />
          </LoadingContainer>
        </ScrollContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>
        <H4 className="investment-panel-title">Painel de investimentos</H4>
        <GoIcon
          className="investment-panel-title"
          onClick={() => history.push('/finances/patrimony/map')}
        />
      </Title>
      <ScrollContainer>
        <Table.Container className="responsive investment-panel">
          <Table.TableBasic className="investment-panel">
            <Table.Thead className="investment-panel">
              <Table.Tr className="investment-panel-title">
                {head.map((title, index) => (
                  <Table.Th
                    key={index}
                    className={generateClassName({
                      'investment-panel': true,
                      'first': index === 0,
                      'last': index === head.length - 1
                    })}
                  >
                    {title}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody className="investment-panel">
              {data?.map((value, index) => (
                <Table.Tr key={`${value}-${index}`} className="investment-panel-body">
                  <Table.Td
                    key={`${value}-${value['fund']}-${index}`}
                    className={`investment-panel first`}
                  >
                    {value['fund']}
                  </Table.Td>
                  {tdsOrder.map((row, index) => (
                    <Table.Td
                      key={`${value}-${row}-${index}`}
                      className={generateClassName({
                        'investment-panel': true,
                        'default_next_movement_value': row === 'next_movement_value',
                        'negative-value': row === 'next_movement_value' && value[row]?.raw < 0,
                      })}
                    >
                      {value[row]?.formatted}
                    </Table.Td>
                  ))}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
      </ScrollContainer>
    </Container>
  );
};
