import React, { useState } from 'react';
import { DreamCard } from 'components/templates/DreamCard';
import { GenericDreamModal } from 'components/molecules/GenericDreamModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import { getImageGoals } from 'utils/goals';

export const GenericDream = ({
  dreamProps,
  onToggleDreamStatus,
  onCreateGoal = () => { },
  onUpdateGoal = () => { },
  reloadPage,
  onDeleteGoal = () => { },
  uploadFile = () => { },
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleModal = () => setShowModal(prev => !prev);
  const toggleDeleteModal = () => setShowDeleteModal(prev => !prev);

  const handleDelete = () => {
    onDeleteGoal(dreamProps?.id);
    toggleDeleteModal();
  };

  const backGroundImage = dreamProps?.url || getImageGoals() || '';

  const dreamBody = {
    realized: (
      <>
        <DreamCard.RootContainer
          className="goal-panel realized-generic-dream"
          backgroundImage={backGroundImage}
        >
          <DreamCard.Icon
            icon="round-verified"
            className="realized-generic-dream"
          />
          <DreamCard.Text
            text={dreamProps?.name}
            className="goals-panel generic-dream-realized"
          />
          <DreamCard.GroupItens className="on-center">
            <DreamCard.Icon
              icon="trash"
              className="delete-generic-dream"
              onClick={toggleDeleteModal}
            />
            <DreamCard.Icon
              icon="pencil"
              className="financial-independence"
              onClick={toggleModal}
            />
            <DreamCard.Icon
              icon="verified"
              className="realized-dream"
              onClick={() =>
                onToggleDreamStatus(dreamProps.id, dreamProps.done_at)
              }
            />
          </DreamCard.GroupItens>
        </DreamCard.RootContainer>
      </>
    ),
    unrealized: (
      <DreamCard.RootContainer
        className="goal-panel generic-dream"
        backgroundImage={backGroundImage}
      >
        <DreamCard.GroupItens className="generic-dream-title spaced">
          <DreamCard.Text
            text={dreamProps.name}
            className="generic-dream-title"
          />
          <DreamCard.GroupItens className='icons'>
            <DreamCard.Icon
              icon="trash"
              className="delete-generic-dream"
              onClick={toggleDeleteModal}
            />
            <DreamCard.Icon
              icon="pencil"
              className="financial-independence"
              onClick={toggleModal}
            />
            <DreamCard.Icon
              icon="verified"
              className="financial-independence"
              onClick={() =>
                onToggleDreamStatus(dreamProps.id, dreamProps.done_at)
              }
            />
          </DreamCard.GroupItens>
        </DreamCard.GroupItens>
        <DreamCard.Text
          text={dreamProps?.when?.formatted}
          className="generic-dream-mim time-to"
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text="M.I.M"
            className="generic-dream-mim mim"
          />
          <DreamCard.Text
            text={dreamProps.ifp.formatted}
            className="generic-dream-mim mim"
          />
        </DreamCard.GroupItens>

        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text={dreamProps.saved_money.formatted}
            className="generic-dream-progress-information first"
          />
          <DreamCard.Text
            text={dreamProps.value.formatted}
            className="generic-dream-progress-information first"
          />
        </DreamCard.GroupItens>
        <DreamCard.ProgressBar
          className="goals-panel dream-panel"
          progress={dreamProps.total_invested_percentage.raw}
        />
        <DreamCard.GroupItens className="spaced">
          <DreamCard.Text
            text="Total investido"
            className="generic-dream-progress-information"
          />
          <DreamCard.Text
            text={dreamProps.total_invested_percentage.formatted}
            className="generic-dream-progress-information"
          />
        </DreamCard.GroupItens>
      </DreamCard.RootContainer>
    ),
    unrealized_without_defined_value: (
      <DreamCard.RootContainer
        className="goal-panel generic-dream"
        backgroundImage={backGroundImage}
      >
        <DreamCard.GroupItens className="generic-dream-title spaced">
          <DreamCard.Text
            text={dreamProps.name}
            className="generic-dream-title"
          />
          <DreamCard.GroupItens className='icons'>
            <DreamCard.Icon
              icon="trash"
              className="delete-generic-dream"
              onClick={toggleDeleteModal}
            />
            <DreamCard.Icon
              icon="pencil"
              className="financial-independence"
              onClick={toggleModal}
            />
            <DreamCard.Icon
              icon="verified"
              className="financial-independence"
              onClick={() =>
                onToggleDreamStatus(dreamProps.id, dreamProps.done_at)
              }
            />
          </DreamCard.GroupItens>
        </DreamCard.GroupItens>
        <DreamCard.Text
          text={dreamProps?.when?.formatted}
          className="generic-dream-mim time-to"
        />
        <DreamCard.Text
          text="Sem valor definido"
          className="generic-dream-mim"
        />
      </DreamCard.RootContainer>
    ),
  };

  const getGoalPage = (goal) => {
    if (goal?.done_at) {
      return 'realized';
    }
    if (goal?.no_defined_value) {
      return 'unrealized_without_defined_value';
    }
    return 'unrealized';
  }

  return (
    <>
      {dreamBody[getGoalPage(dreamProps)]}
      {showModal && (
        <GenericDreamModal
          showModal={showModal}
          setShowModal={setShowModal}
          dreamProps={dreamProps}
          action="edit"
          onCreateGoal={onCreateGoal}
          onUpdateGoal={onUpdateGoal}
          reloadPage={reloadPage}
          uploadFile={uploadFile}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title="Excluir sonho"
          text="Excluir sonho?"
          highlightedText="Esta ação é permanente e não poderá ser desfeita"
          onConfirm={handleDelete}
          cancelButtonText="Cancelar"
          confirmButtonText="Excluir sonho"
          confirmButtonColor='red'
          theme="debts"
        />
      )}
    </>
  );
};
