import { useState, useEffect, useRef, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const useNewRelease = currentPage => {
  const { id: userId } = useSelector(state => state.user.profile);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const isMounted = useRef(false);

  const fetchCategories = useCallback(async () => {
    if (!userId || !isMounted.current) return;

    setLoading(true);
    try {
      const { data: fetchedCategories } = await api.get(
        `/users/${userId}/categories`,
        {
          params: {
            type: currentPage === 'receipt' ? 'receipts' : 'spendings',
          },
        }
      );

      if (isMounted.current) {
        setCategories(
          fetchedCategories.map(category => ({
            value: category?.name,
            label: category?.name.toUpperCase(),
            id: category?.id,
            ...category,
          }))
        );
      }
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      toast.error('Erro ao buscar categorias.');
    } finally {
      if (isMounted.current) setLoading(false);
    }
  }, [userId, currentPage]);

  useEffect(() => {
    isMounted.current = true;

    fetchCategories();

    return () => {
      isMounted.current = false;
    };
  }, [fetchCategories]);

  const createEntry = async data => {
    setLoading(true);
    try {
      await api.post(`/users/${userId}/v2/installments`, data);
    } catch (error) {
      toast.error('Ocorreu um erro ao criar a entrada.');
    } finally {
      setLoading(false);
    }
  };

  const createSpendingOrReceipt = async data => {
    setLoading(true);
    try {
      await api.post(`/users/${userId}/items/${data.item_id}/entries`, data);
    } catch (error) {
      toast.error('Ocorreu um erro ao criar a transação.');
    } finally {
      setLoading(false);
    }
  };

  const createInvestment = async data => {
    setLoading(true);
    try {
      await api.post(`/investments/${userId}`, data);
    } catch (error) {
      toast.error('Ocorreu um erro ao criar o investimento.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    fetchCategories,
    categories,
    createEntry,
    createSpendingOrReceipt,
    createInvestment,
  };
};

export default useNewRelease;
