import styled from 'styled-components';

export const StyledIcon = styled.svg`
  width: 32px;
  height: 22px;


  &.selected-patrimony path {
    fill: ${({ theme }) => theme.white};
  }

  &.modal-upload {
    &.line-icon {
      width: 20px;
      height: 20px;
      path {
        stroke: ${({ theme }) => theme.graySilver};
      }
    }
  }
`;
