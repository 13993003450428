const { format } = require('date-fns');
export const getEmpytSocialSecurity = () => {
  const today = new Date();
  const formattedToday = format(today, 'dd/MM/yyyy');
  return {
    header: ['Dados', 'Prev 01', 'Prev 02'],
    socialSecurity: [
      {
        analysis_date: 'Data da Análise',
        expected_retirement_date: 'Data Prevista da Aposentadoria',
        investment_value: 'Valor à Investir (INSS + I.R)',
        monthly_contribution: 'Aporte Mensal',
        initial_current_monthly_income: 'R.M.I atual',
        retirement_income: 'Renda na Aposentadoria',
      },
      {
        id: -1,
        name: 'Prev 01',
        analysis_date: {
          formatted: formattedToday,
          raw: today.toISOString(),
        },
        expected_retirement_date: {
          formatted: formattedToday,
          raw: today.toISOString(),
        },
        investment_value: {
          formatted: 'R$ 0',
          raw: 0,
        },
        monthly_contribution: {
          formatted: 'R$ 0',
          raw: 0,
        },
        initial_current_monthly_income: {
          formatted: 'R$ 0',
          raw: 0,
        },
        retirement_income: {
          formatted: 'R$ 0',
          raw: 0,
        },
        position: 1,
      },
      {
        id: -1,
        name: 'Prev 02',
        analysis_date: {
          formatted: formattedToday,
          raw: today.toISOString(),
        },
        expected_retirement_date: {
          formatted: formattedToday,
          raw: today.toISOString(),
        },
        investment_value: {
          formatted: 'R$ 0',
          raw: 0,
        },
        monthly_contribution: {
          formatted: 'R$ 0',
          raw: 0,
        },
        initial_current_monthly_income: {
          formatted: 'R$ 0',
          raw: 0,
        },
        retirement_income: {
          formatted: 'R$ 0',
          raw: 0,
        },
        position: 2,
      },
    ],
  };
};
