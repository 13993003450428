export const getRealEstateList = () => {
  return {
    total: 'R$ 3000',
    percentage: '500 %',
    header: [
      'Imóveis',
      'Valor de Mercado',
      'Renda (Aluguel)',
      'Despesas mensais',
      'Receita Líquida',
      'Rentabilidade',
      'Recomendação',
    ],
    body: [
      {
        id: 1,
        properties: 'Imóvel 01',
        marketValue: 'R$ 0',
        rent: 'R$ 0',
        monthlyExpenses: 'R$ 0',
        netRevenue: 'R$ 0',
        profitability: 'R$ 0',
        recommendation: 'VENDER',
      },
      {
        id: 2,
        properties: 'Imóvel 02',
        marketValue: 'R$ 0',
        rent: 'R$ 0',
        monthlyExpenses: 'R$ 0',
        netRevenue: 'R$ 0',
        profitability: 'R$ 0',
        recommendation: 'ALUGAR',
      },
      {
        id: 3,
        properties: 'Imóvel 03',
        marketValue: 'R$ 0',
        rent: 'R$ 0',
        monthlyExpenses: 'R$ 0',
        netRevenue: 'R$ 0',
        profitability: 'R$ 0',
        recommendation: 'MANTER',
      },
    ],
  };
};
export const getSocialSecurity = () => {
  return {
    header: ['Dados', 'Nome 01', 'Nome 02'],
    body: [
      {
        id: 1,
        data: 'Data de Análise',
        name01: 'dd/mm/aa',
        name02: 'dd/mm/aa',
      },
      {
        id: 2,
        data: 'Data Prevista de Aposentadoria',
        name01: 'dd/mm/aa',
        name02: 'dd/mm/aa',
      },
      {
        id: 3,
        data: 'Valor à Investir (INSS + I.R)',
        name01: 'R$ 0',
        name02: 'R$ 0',
      },
      {
        id: 4,
        data: 'Aporte mensal',
        name01: 'R$ 0',
        name02: 'R$ 0',
      },

      {
        id: 5,
        data: 'R.M.I Atual',
        name01: 'R$ 0',
        name02: 'R$ 0',
      },
      {
        id: 6,
        data: 'Renda na Aposentadoria',
        name01: 'R$ 0',
        name02: 'R$ 0',
      },
    ],
  };
};

export const getFinancialPatrimony = () => {
  return {
    tableCount: 3,
    tables: [
      {
        title: 'Tabela 1',
        header: ['Dados 01', 'Plano atual'],
        recom: 'RESGATAR',
        body: [
          {
            id: 1,
            data: 'Nome Fundo 1',
            plan: 'Plano A',
          },
          {
            id: 2,
            data: 'CNPJ 1',
            plan: 'Plano A',
          },
          {
            id: 3,
            data: 'Montante Atual 1',
            plan: '1000',
          },
          {
            id: 4,
            data: 'Contingente Mensal 1',
            plan: '200',
          },
          {
            id: 5,
            data: 'COP 1',
            plan: true,
          },
          {
            id: 6,
            data: 'Tipo de Previdência 1',
            plan: 'Privada',
          },
          {
            id: 7,
            data: 'Benefício IR 1',
            plan: 'Sim',
          },
          {
            id: 8,
            data: 'Rentabilidade Mensal 1',
            plan: '0.5%',
          },
        ],
      },
      {
        title: 'Tabela 2',
        header: ['Dados 02', 'Plano atual'],
        recom: 'Manter',
        body: [
          {
            id: 1,
            data: 'Nome Fundo 2',
            plan: 'Plano B',
          },
          {
            id: 2,
            data: 'CNPJ 2',
            plan: 'Plano B',
          },
          {
            id: 3,
            data: 'Montante Atual 2',
            plan: '2000',
          },
          {
            id: 4,
            data: 'Contingente Mensal 2',
            plan: '300',
          },
          {
            id: 5,
            data: 'COP 2',
            plan: false,
          },
          {
            id: 6,
            data: 'Tipo de Previdência 2',
            plan: 'Pública',
          },
          {
            id: 7,
            data: 'Benefício IR 2',
            plan: 'Não',
          },
          {
            id: 8,
            data: 'Rentabilidade Mensal 2',
            plan: '0.4%',
          },
        ],
      },
      {
        title: 'Tabela 3',
        header: ['Dados 03', 'Plano atual'],
        recom: 'Manter',
        body: [
          {
            id: 1,
            data: 'Nome Fundo 3',
            plan: 'Plano C',
          },
          {
            id: 2,
            data: 'CNPJ 3',
            plan: 'Plano C',
          },
          {
            id: 3,
            data: 'Montante Atual 3',
            plan: '3000',
          },
          {
            id: 4,
            data: 'Contingente Mensal 3',
            plan: '400',
          },
          {
            id: 5,
            data: 'COP 3',
            plan: true,
          },
          {
            id: 6,
            data: 'Tipo de Previdência 3',
            plan: 'Complementar',
          },
          {
            id: 7,
            data: 'Benefício IR 3',
            plan: 'Sim',
          },
          {
            id: 8,
            data: 'Rentabilidade Mensal 3',
            plan: '0.3%',
          },
        ],
      },
    ],
  };
};

export const getRetirement = () => {
  return {
    header: ['Dados do Patrimônio Financeiro', ' '],
    body: [
      {
        id: 1,
        data: 'Patrimônio Atual',
        value: 'R$ 0',
      },
      {
        id: 2,
        data: 'Renda Atual',
        value: 'R$ 0',
      },
      {
        id: 3,
        data: 'Data da Independência Financeira',
        value: '0',
      },
      {
        id: 4,
        data: 'Patrimônio da Independência Financeira',
        value: 'R$ 0',
      },
      {
        id: 5,
        data: 'Renda na Independência Financeira',
        value: 'R$ 0',
      },
    ],
  };
};
export const getGraphData = () => {
  return {
    labels: ['2024', '2025', '2030', '2040', '2050', '2060'],
    datasets: [
      {
        label: 'Patrimônio Imobilizado Projetado',
        data: [50000, 100000, 150000, 300000, 500000, 1000000],
      },
      {
        label: 'Patrimônio Financeiro Projetado',
        data: [30000, 70000, 120000, 250000, 400000, 800000],
      },
      {
        label: 'Patrimônio Imobilizado Alcançado',
        data: [15000, 50000, 90000, 200000, 300000, 600000],
      },
      {
        label: 'Patrimônio Financeiro Alcançado',
        data: [20000, 60000, 110000, 220000, 350000, 700000],
      },
      {
        label: 'Curva Projetada',
        data: [60000, 110000, 160000, 310000, 510000, 1010000],
      },
      {
        label: 'Curva Estimada',
        data: [50000, 100000, 150000, 300000, 500000, 1000000],
      },
    ],
  };
};
export const getCompositionData = () => {
  return {
    total: '100%',
    financialPatrimonyValue: 30,
    rentValue: 20,
    socialSecurityValue: 50,
  };
};
export const getNavegationCardRetirement = () => {
  return {
    activeValue: 'Em análise',
    rentValue: 'R$ 0',
    financialPatrimony: 'R$ 0',
    percentValue: '0.0%',
  };
};
