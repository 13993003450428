import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import NavegationButton from '../NavegationButtons';
import toast from 'react-hot-toast';
import {
  receiptSchema,
  debtsSchema,
  spendingSchema,
  investmentSchema,
} from 'utils/validator';
import useNewRelease from 'hooks/useNewRealease';
import { LoadingNewRealease } from '../LoadingNewRealease';
import { useItemsList } from 'hooks/useItems';

export const AddRealease = ({
  showModal = false,
  setShowModal = () => {},
  title = 'Novo Lançamento',
  icon = 'add',
  theme = 'edit',
}) => {
  const [currentPage, setCurrentPage] = useState('receipt');

  const {
    loading,
    fetchCategories,
    categories,
    createEntry,
    createSpendingOrReceipt,
    createInvestment,
  } = useNewRelease(currentPage);

  const { fetchItemsListByCategoryId, items } = useItemsList();

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [value, setValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [recurrent, setRecurrent] = useState(false);
  const [totalInstallment, setTotalInstallment] = useState('');

  useEffect(() => {
    setSelectCategory([]);
    setSelectItem([]);
    setValue('');
    setStartDate('');
    setEndDate('');
    setDescription('');
    setRecurrent(false);
    setTotalInstallment('');
  }, [currentPage]);

  const handleChange = setter => value => {
    setter(value);
  };

  useEffect(() => {
    if (selectCategory?.id) {
      fetchItemsListByCategoryId(selectCategory.id);
    }
  }, [selectCategory, fetchItemsListByCategoryId]);

  useEffect(() => {
    const formattedItems = items.map(item => ({
      id: item.id,
      value: item.value,
      label: item.name,
    }));
    setItemList(formattedItems);
  }, [items]);

  const additionalSettings = {
    receipt: (
      <>
        <Modal.Dropdown
          className="generic-dream-modal first"
          selectOptions={categories}
          getOption={handleChange(setSelectCategory)}
          label="Classificação"
          placeholderSelect={selectCategory.label}
        />
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={itemList}
          getOption={handleChange(setSelectItem)}
          label="Nome do item"
          placeholderSelect={selectItem.label}
        />
        <Modal.DatePicker
          className="generic-dream-modal generic-dream-modal-datepicker after full-width  mt-32 "
          label="Data de Início"
          getInputValue={handleChange(setStartDate)}
          defaultValue={startDate}
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="Descrição"
          getInputValue={handleChange(setDescription)}
          defaultValue={description}
        />
        <Modal.GroupInputs show={true}>
          <Modal.Switch
            label="Recorrente"
            className="generic-dream-modal after"
            getSwitchValue={handleChange(setRecurrent)}
            defaultValue={recurrent}
          />
          <Modal.InputCurrency
            label="Valor"
            getInputValue={handleChange(setValue)}
            className="generic-dream-modal first half second-section"
            defaultValue={value}
          />
        </Modal.GroupInputs>
        {recurrent && (
          <Modal.DatePicker
            className="generic-dream-modal generic-dream-modal-datepicker midi-width after mt-32"
            label="Data do Término"
            getInputValue={handleChange(setEndDate)}
            defaultValue={endDate}
            left={true}
          />
        )}
      </>
    ),
    spendings: (
      <>
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={categories}
          getOption={handleChange(setSelectCategory)}
          label="Classificação"
          placeholderSelect={selectCategory.label}
        />
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={itemList}
          getOption={handleChange(setSelectItem)}
          label="Nome do item"
          placeholderSelect={selectItem.label}
        />
        <Modal.DatePicker
          className="generic-dream-modal generic-dream-modal-datepicker full-width after mt-32"
          label="Data de Início"
          getInputValue={handleChange(setStartDate)}
          defaultValue={startDate}
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="Descrição"
          getInputValue={handleChange(setDescription)}
          defaultValue={description}
        />
        <Modal.GroupInputs show={true}>
          <Modal.Switch
            label="Recorrente"
            className="generic-dream-modal after"
            getSwitchValue={handleChange(setRecurrent)}
            defaultValue={recurrent}
          />
          <Modal.InputCurrency
            label="Valor"
            getInputValue={handleChange(setValue)}
            className="generic-dream-modal first half second-section"
            defaultValue={value}
          />
        </Modal.GroupInputs>
        {recurrent && (
          <Modal.DatePicker
            className="generic-dream-modal generic-dream-modal-datepicker midi-width  "
            label="Data do Término"
            getInputValue={handleChange(setEndDate)}
            defaultValue={endDate}
            left={true}
          />
        )}
      </>
    ),
    installments: (
      <>
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={categories}
          getOption={handleChange(setSelectCategory)}
          label="Classificação"
          placeholderSelect={selectCategory.label}
        />
        <Modal.Dropdown
          className="generic-dream-modal after"
          selectOptions={itemList}
          getOption={handleChange(setSelectItem)}
          label="Nome do item"
          placeholderSelect={selectItem.label}
        />
        <Modal.DatePicker
          className="generic-dream-modal generic-dream-modal-datepicker full-width after mt-32"
          label="Data de Início"
          getInputValue={handleChange(setStartDate)}
          defaultValue={startDate}
        />
        <Modal.InputText
          className="generic-dream-modal after"
          label="Descrição"
          getInputValue={handleChange(setDescription)}
          defaultValue={description}
        />
        <Modal.GroupInputs show={true}>
          <Modal.InputCurrency
            label="Valor"
            getInputValue={handleChange(setValue)}
            className="generic-dream-modal first half first-section"
            defaultValue={value}
          />
          <Modal.InputText
            className="generic-dream-modal first half second-section"
            label="Parcelamento"
            getInputValue={handleChange(setTotalInstallment)}
            defaultValue={totalInstallment}
          />
        </Modal.GroupInputs>
      </>
    ),
    investment: (
      <>
        <Modal.InputCurrency
          label="Valor"
          getInputValue={handleChange(setValue)}
          className="generic-dream-modal after"
          defaultValue={value}
        />
        <Modal.DatePicker
          className="generic-dream-modal generic-dream-modal-datepicker full-width after mt-32"
          label="Data"
          getInputValue={handleChange(setStartDate)}
          defaultValue={startDate}
        />
      </>
    ),
  };

  const additionalSettingsContent = useMemo(() => {
    return additionalSettings[currentPage] || <></>;
  }, [currentPage, additionalSettings]);

  const validateForm = async () => {
    let schema;

    switch (currentPage) {
      case 'receipt':
        schema = receiptSchema;
        break;
      case 'spendings':
        schema = spendingSchema;
        break;
      case 'installments':
        schema = debtsSchema;
        break;
      case 'investment':
        schema = investmentSchema;
        break;
      default:
        schema = null;
    }

    if (!schema) {
      toast.error('Esquema de validação não encontrado.');
      return false;
    }
    try {
      await schema.validate(
        {
          selectCategory,
          selectItem,
          value,
          startDate,
          endDate,
          description,
          recurrent,
          totalInstallment,
        },
        { abortEarly: false }
      );
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          toast.error(error.message, {
            autoClose: 2000,
            position: 'top-right',
          });
        });
      }
      return false;
    }
  };
  const confirm = async () => {
    if (!(await validateForm())) return;
    try {
      let formattedData;
      if (currentPage === 'investment') {
        formattedData = {
          value,
          date_start: startDate,
        };
        await createInvestment(formattedData);
      } else if (currentPage === 'installments') {
        formattedData = {
          value,
          name: description,
          category_id: selectCategory.id,
          item_id: selectItem.id,
          currentInstallment: 1,
          totalInstallments: Number(totalInstallment),
          start: startDate,
        };
        await createEntry(formattedData);
      } else {
        formattedData = {
          item_id: selectItem.id,
          value,
          start: startDate,
          end: endDate,
          description,
          recurrent,
        };
        await createSpendingOrReceipt(formattedData);
      }
      toast.success('Lançamento criado com sucesso!');
      setSelectCategory([]);
      setSelectItem([]);
      setValue('');
      setStartDate('');
      setEndDate('');
      setDescription('');
      setRecurrent(false);
      setTotalInstallment('');
      fetchCategories();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="generic-dream "
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <NavegationButton
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fetchData={fetchCategories}
      />
      <Modal.BodyContainer className="new-realease">
        {loading ? <LoadingNewRealease /> : additionalSettingsContent}
      </Modal.BodyContainer>
      <Modal.FooterWithOneButton
        text="Salvar transação"
        className="generic-dream-modal new-realease"
        onClick={confirm}
      />
    </Modal.Container>
  );
};
