import { Button } from 'components/atoms/Button';
import { EditFinancialPatrimony } from 'components/molecules/RetirementMapModal/EditFinancialPatrimony';
import { EditRecom } from 'components/molecules/RetirementMapModal/EditRecom';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useEffect, useMemo, useState } from 'react';
import { CenteredDiv, ContainerRecommendation } from './styles';
import Switch from 'components/atoms/Switch';
import toast from 'react-hot-toast';
import { getCopByValue, getIrBenefitByValue, getPensionTypeByValue } from 'enums/privatePension';
import { UploadPrivatePensionFile } from 'components/molecules/RetirementMapModal/UploadPrivatePensionFile';
import {
  useUpdatePrivatePension,
  usePrivatePensions,
} from 'hooks/usePrivatePensions';

export const FinancialPatrimony = ({ privatePensions }) => {
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const maxTables = privatePensions?.length;
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [cop, setCop] = useState(
    Boolean(privatePensions[currentTableIndex]?.secondColumn?.body?.cop.raw)
  );

  const [irBenefit, setIrBenefit] = useState(
    Boolean(
      privatePensions[currentTableIndex]?.secondColumn?.body?.ir_benefit.raw
    )
  );
  const { updatePrivatePension } = useUpdatePrivatePension();
  const { reloadPrivatePensions } = usePrivatePensions();

  useEffect(() => {
    setIrBenefit(
      Boolean(
        privatePensions[currentTableIndex]?.secondColumn?.body?.ir_benefit.raw
      )
    );
    setCop(
      Boolean(privatePensions[currentTableIndex]?.secondColumn?.body?.cop.raw)
    );
  }, [currentTableIndex, privatePensions]);
  const handleNextTable = () => {
    if (currentTableIndex < maxTables - 1) {
      setCurrentTableIndex(currentTableIndex + 1);
    }
  };

  const handlePrevTable = () => {
    if (currentTableIndex > 0) {
      setCurrentTableIndex(currentTableIndex - 1);
    }
  };

  const [showEdit, setShowEdit] = useState(false);
  const [showEditRecom, setShowEditRecom] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleEditClick = item => {
    setSelectedItem({
      ...item,
      cop: getCopByValue(item.cop.raw),
      ir_benefit: getIrBenefitByValue(item.ir_benefit.raw),
      pension_type: getPensionTypeByValue(item.pension_type.raw),
    });

    setShowEdit(true);
  };
  const handleEditRecommendation = privatePension => {
    if (privatePension?.private_pension_id === -1) {
      return toast('Adicone uma previdencia privada primeiro!', {
        icon: '⚠️',
      });
    }
    setSelectedItem({
      private_pension_id: privatePension.private_pension_id,
      recommendation: privatePension.body.recommendation,
    });
    setShowEditRecom(true);
  };

  const handleFileUpload = privatePension => {
    if (privatePension?.private_pension_id === -1) {
      toast.error('Cadastre a previdência primeiro');
      return;
    }
    setSelectedItem({
      ...privatePension,
      cop: getCopByValue(privatePension.cop.raw),
      pension_type: getPensionTypeByValue(privatePension.pension_type.raw),
    });
    setShowUploadModal(true);
  };

  const handleEditCop = async privatePension => {
    await updatePrivatePension(privatePension.private_pension_id, {
      cop: !cop,
    });
    await reloadPrivatePensions();
  };

  const handleEditIrBenefit = async privatePension => {
    await updatePrivatePension(privatePension.private_pension_id, {
      ir_benefit: !irBenefit,
    });
    await reloadPrivatePensions();
  };
  const customHeight = useMemo(() => {
    if (!privatePensions[currentTableIndex]?.secondColumn?.body) return 0;
    const length = Object.values(
      privatePensions[currentTableIndex].secondColumn.body
    ).length;
    return (length - 1) * 44;
  }, [currentTableIndex, privatePensions]);

  const renderRecommendationButton = (column, privatePension) => (
    <ContainerRecommendation customHeight={customHeight}>
      <Button
        className="retirement-table"
        onClick={() => handleEditRecommendation(privatePension)}
      >
        {column?.formatted || column}
      </Button>
    </ContainerRecommendation>
  );

  const renderSwitch = (column, rowIndex, privatePension) => {
    if (typeof column?.raw !== 'boolean') return null;

    const isAdditionalSwitch = rowIndex > 5;
    const checkedState = isAdditionalSwitch ? irBenefit : cop;
    const setCheckedState = isAdditionalSwitch ? setIrBenefit : setCop;
    const handleEdit = isAdditionalSwitch ? handleEditIrBenefit : handleEditCop;
    const handleWarn = () => {
      if (privatePension?.private_pension_id === -1) {
        return toast('Adicone uma previdencia privada primeiro!', {
          icon: '⚠️',
        });
      }
    };
    return (
      <Switch
        className="no-margin"
        checked={checkedState}
        onChange={() =>
          privatePension.private_pension_id !== -1
            ? handleEdit(privatePension)
            : handleWarn()
        }
        getValue={setCheckedState}
      />
    );
  };

  const renderColumnContent = (column, index, rowIndex, privatePension) => {
    if (index === 2) return renderRecommendationButton(column, privatePension);
    if (typeof column?.raw === 'boolean')
      return renderSwitch(column, rowIndex, privatePension);
    return column?.formatted || column;
  };

  const PrivatePensionTable = ({
    privatePension,
    index,
    handleEditClick,
    handleFileUpload,
  }) => (
    <Table.Container className="responsive">
      <Table.TableBasic className="min-table">
        <Table.Thead className="retirement">
          <Table.Tr className="receipt-table-title receipts">
            <Table.Th className="budget-table-title first-table-border checkbox">
              {index === 1 ? (
                <CenteredDiv>
                  <div>{privatePension.header}</div>
                  <Table.ContentIcon
                    type="pencil-clip"
                    onEdit={() =>
                      handleEditClick({
                        ...privatePension.body,
                        private_pension_id: privatePension.private_pension_id,
                      })
                    }
                    onClip={() =>
                      handleFileUpload({
                        ...privatePension.body,
                        private_pension_id: privatePension.private_pension_id,
                      })
                    }
                  />
                </CenteredDiv>
              ) : index === 2 ? (
                <CenteredDiv>
                  <div>{privatePension.header}</div>
                  <Table.ContentIcon
                    type="pencil"
                    onEdit={() => handleEditRecommendation(privatePension)}
                  />
                </CenteredDiv>
              ) : (
                <div>{privatePension.header}</div>
              )}
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {Object.values(privatePension.body).map((column, rowIndex) => (
            <Table.Tr className="receipt-table-body" key={rowIndex}>
              <Table.Td
                className={`receipt-table-body ${
                  index === 0 ? 'first-table-border' : ''
                } checkbox ${index === 1 ? 'center' : ''} ${
                  index === 2 ? 'recommendation' : ''
                }`}
                first={index === 0}
              >
                {renderColumnContent(column, index, rowIndex, privatePension)}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table.TableBasic>
    </Table.Container>
  );

  return (
    <Card.Container className="spending-table no-footer">
      <Table.TitleSet
        nameTable="Previdência Privada"
        className="patrimony font-regular blue"
        onClickLeft={handlePrevTable}
        onClickRight={handleNextTable}
        currentTableIndex={currentTableIndex}
        maxTables={maxTables}
      />
      <Card.Container className="table-recom ">
        {privatePensions.length > 0 &&
          Object.values(privatePensions[currentTableIndex]).map(
            (privatePension, index) => (
              <Table.Container className="responsive">
                <PrivatePensionTable
                  privatePension={privatePension}
                  index={index}
                  handleEditClick={handleEditClick}
                  handleFileUpload={handleFileUpload}
                />
              </Table.Container>
            )
          )}
      </Card.Container>
      {showEdit && (
        <EditFinancialPatrimony
          showModal={showEdit}
          setShowModal={setShowEdit}
          theme="edit"
          selectedItem={selectedItem}
        />
      )}
      {showEditRecom && (
        <EditRecom
          showModal={showEditRecom}
          setShowModal={setShowEditRecom}
          privatePension={selectedItem}
          theme="edit"
        />
      )}
      {showUploadModal && (
        <UploadPrivatePensionFile
          showModal={showUploadModal}
          setShowModal={setShowUploadModal}
          privatePensionId={selectedItem.private_pension_id}
        />
      )}
    </Card.Container>
  );
};
