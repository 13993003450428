export const getEmpytRent = () => {
  return {
    overview: {
      total: {
        formatted: 'R$ 0',
        raw: 0,
      },
      progress: {
        formatted: '0%',
        raw: 0,
      },
    },
    rentPatrimoniesHeader: [
      'Imóveis',
      'Valor de Mercado',
      'Renda (Aluguel)',
      'Despesas Mensais',
      'Receita Líquida',
      'Rentabilidade',
      'Recomendação',
    ],
    rentPatrimonies: [
      {
        id: 4777,
        user_id: 441,
        name: 'teste',
        value: 0.0,
        type: 'patrimonial-active',
        classification: 'Imóvel de Investimento',
        created_at: '2025-02-03 08:31:27',
        updated_at: '2025-02-03 11:31:37',
        categorization: 'R. Fixa Pós',
        liquidity: 'Acima de 24 meses',
        sealable: true,
        private_pension: false,
        market_value: {
          formatted: 'R$ 0',
          raw: 0,
        },
        rent: {
          formatted: 'R$ 0',
          raw: 0,
        },
        monthly_expenses: {
          formatted: 'R$ 0',
          raw: 0,
        },
        net_revenue: {
          formatted: 'R$ 0',
          raw: 0,
        },
        profitability: {
          formatted: '0%',
          raw: 0,
        },
        recommendation: 'A definir',
      },
      {
        id: 3465,
        user_id: 441,
        name: 'nome do patrimônio',
        value: 4578.9,
        type: 'patrimonial-active',
        classification: 'Imóvel de Investimento',
        created_at: '2022-12-21 11:14:27',
        updated_at: '2025-02-03 11:31:46',
        categorization: null,
        liquidity: null,
        sealable: true,
        private_pension: false,
        market_value: {
          formatted: 'R$ 0',
          raw: 0,
        },
        rent: {
          formatted: 'R$ 0',
          raw: 0,
        },
        monthly_expenses: {
          formatted: 'R$ 0',
          raw: 0,
        },
        net_revenue: {
          formatted: 'R$ 0',
          raw: 0,
        },
        profitability: {
          formatted: '0%',
          raw: 0,
        },
        recommendation: 'A definir',
      },
    ],
  };
};
export const getEmpytRetirementAssets = () => {
  return {
    header: ['Descrição', 'Valor'],
    body: [
      {
        data: 'Patrimônio Atual',
        value: {
          raw: 0,
          formatted: 'R$ 0',
        },
      },
      {
        data: 'Renda Atual',
        value: {
          raw: 0,
          formatted: 'R$ 0',
        },
      },
      {
        data: 'Data Independência Financeira',
        value: {
          raw: '2025-07-01T03:00:00.000Z',
          formatted: '01/07/2025',
        },
      },
      {
        data: 'Patrimônio Independência Financeira',
        value: {
          raw: 0,
          formatted: 'R$ 0',
        },
      },
      {
        data: 'Renda Independência Financeira',
        value: {
          raw: 0,
          formatted: 'R$ 0',
        },
      },
    ],
  };
};
