import React from 'react';
import { GroupItensTitle } from './styles';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import AddIcon from 'components/atoms/icons/AddIcon';
import { P } from 'components/atoms/P';
import CloseModalIcon from 'components/atoms/icons/CloseModalIcon';
import FileIcon from 'components/atoms/icons/FileIcon';

export const SimpleModalTitleContent = ({
  title,
  className,
  onClose,
  type = 'edit',
  theme = '',
}) => {
  const icons = {
    file: <FileIcon className={`${className} ${theme}`} />,
    edit: <PencilIcon className={`${className} ${theme}`} />,
    add: <AddIcon className={`${className} ${theme}`} />,
    delete: <TrashIcon className={`${className} ${theme}`} />,
  };

  return (
    <>
      <GroupItensTitle className={className}>
        {icons[type]}
        <P className={`${className} ${theme}`}>{title}</P>
        <CloseModalIcon className={`${className} ${theme}`} onClick={onClose} />
      </GroupItensTitle>
    </>
  );
};
