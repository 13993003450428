import React from 'react';
import { TextContainer } from './styles';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import ClipIcon from 'components/atoms/icons/ClipIcon';

export const ContentIcon = ({
  item,
  type,
  onClip,
  onEdit
}) => {
  const icons = {
    pencil: <PencilIcon className="th" onClick={onEdit} />,
    clip: <ClipIcon className="th" onClick={onClip} />,
  };

  const iconTypes = type.split('-');

  return (
    <TextContainer className="th">
      {item}
      {iconTypes.map((iconType, index) =>
        icons[iconType]
          ? React.cloneElement(icons[iconType], { key: index })
          : null
      )}
    </TextContainer>
  );
};
