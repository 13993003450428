import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const GenericEditCategoryTableModal = ({
  showModal = false,
  setShowModal = () => { },
  selectedItem = null,
  onCancel = () => { },
  onConfirm = () => { },
  theme,
  classificationOptions = [],
}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [classification, setClassification] = useState(
    selectedItem?.classification
  );

  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };

  const cancel = () => {
    onCancel({
      name,
      classification,
      id: selectedItem?.categoryId
    });
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar categoria"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome do item"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationOptions || []}
          getDropdownValue={setClassification}
          label="Classificação"
          placeholderSelect={classification}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir categoria"
        cancelButtonText="Concluído"
        confirmButtonColor='red'
        cancelButtonColor='blue'
      />
    </Modal.Container>
  );
};
