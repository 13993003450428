import React from 'react';
import { Span } from 'components/atoms/Span';
import { IMG } from 'components/atoms/IMG';
import { H4 } from 'components/atoms/Headings/H4';
import { P } from 'components/atoms/P';
import { Button } from 'components/atoms/Button';
import {
  Container,
  ProfileContainer,
  ProfileDescriptionContainer,
  Wrapper,
} from './styles';
import ValidateRoles from 'components/organisms/ValidateRoles';

export const FilledSuitabilityProfile = ({
  updateProfile = () => { },
  profileName = 'Iniciante',
  profileDescription = 'Descrição do perfil',
  profileImage = '',
}) => {
  return (
    <Container>
      <Span className="empty-suitability-questionnaire">Perfil investidor</Span>
      <Wrapper>
        <ProfileContainer>
          <IMG
            src={profileImage}
            className="filled-suitability-questionnaire"
            alt="Imagem representativa do seu perfil de investidor"
          />
          <ProfileDescriptionContainer>
            <H4 className="filled-suitability-questionnaire">{profileName}</H4>
            <P
              className="filled-suitability-questionnaire"
              dangerouslySetInnerHTML={{ __html: profileDescription }}
            />
          </ProfileDescriptionContainer>
        </ProfileContainer>
      </Wrapper>
      <Wrapper className="button">
        <ValidateRoles role='only-client'>
          <Button
            className="filled-suitability-questionnaire"
            onClick={updateProfile}
          >
            Atualizar perfil
          </Button>
        </ValidateRoles>
      </Wrapper>
    </Container>
  );
};
