import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  &.default-cursor {
    cursor: default;
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &.default-cursor {
    cursor: default;
  }
`;

export const StyledCheckbox = styled.div`
  display: inline-flex; 
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${props => props.checked ? props.theme.blueRoyal : props.theme.grayPale};
  border-radius: 5px;

  &.circular {
    border-radius:  50%;
  }

  svg {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;