import styled from "styled-components";

export const Container = styled.div`
    margin-top: 12px;
`;

export const ProfileContainer = styled.div`
    display: flex;
    background-color: ${props => props.theme.white};
    border-radius: 16px;
    justify-self: center;
    align-self: center;
`;

export const ProfileDescriptionContainer = styled.div`
    padding: 8px;
    margin-left: 32px;
`;

export const Wrapper = styled.div`
    width: 100%;
    overflow: auto;
    margin-top: 18px;

    &.button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: min-content;
    }
`;