import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React from 'react';
import { LineGrafic } from 'components/atoms/LineGrafic';

export const Rentirement = ({ rentirement, wealthGrowth }) => {
  return (
    <Card.Container className="spending-table">
      <Table.TitleSet
        nameTable="Patrimônio Financeiro da Aposentadoria"
        className="patrimony none"
      />
      <Card.Container className="card-table-grafic no-footer">
        <Card.Container className="table-grafic">
          <Table.Container className="responsive">
            <Table.TableBasic className="grafic-table">
              <Table.Thead className="retirement">
                <Table.Tr className="receipt-table-title receipts">
                  {rentirement.header.map((item, index) => (
                    <Table.Th
                      className={`budget-table-title ${
                        index === 0 ? 'first-table-border checkbox' : ''
                      }`}
                      key={item}
                    >
                      {item}
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {rentirement.body.map((row, rowIndex) => (
                  <Table.Tr className="receipt-table-body" key={rowIndex}>
                    <Table.Td className=" receipt-table-body first-table-border checkbox left">
                      {row?.data}
                    </Table.Td>
                    <Table.Td className="receipt-table-body">
                      {row?.value.formatted || ''}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.TableBasic>
          </Table.Container>
        </Card.Container>
        <Card.Container className="grafic-table">
          <LineGrafic
            labels={Object.keys(wealthGrowth)}
            data={Object.values(wealthGrowth)}
          />
        </Card.Container>
      </Card.Container>
    </Card.Container>
  );
};
