import React, { useCallback, useMemo } from "react";
import { Container, QuestionOptionsContainer } from "./styles";
import { Span } from "components/atoms/Span";

export const SuitabilityQuiz = ({
    currentQuestion = {},
    answersGiven = [],
    setAnswersGiven = () => { },
    data = []
}) => {
    const questionId = data?.id;

    const isSelected = useCallback(
        (answerId) => answersGiven.some(answer => answer.questionId === questionId && answer.answerId === answerId),
        [answersGiven, questionId]
    );

    const handleAnswer = useCallback(
        (answerId) => {
            setAnswersGiven(prevAnswers => {
                const newAnswers = prevAnswers.filter(answer => answer.questionId !== questionId);
                newAnswers.push({ questionId, answerId });
                return newAnswers;
            });
        },
        [setAnswersGiven, questionId]
    );

    const answerOptions = useMemo(
        () =>
            data?.possible_answers?.map((question) => {
                const answerId = question?.id;
                return (
                    <QuestionOptionsContainer
                        key={answerId}
                        onClick={() => handleAnswer(answerId)}
                        className={isSelected(answerId) ? "selected" : ""}
                    >
                        <Span className="empty-suitability-questionnaire-option">
                            {question?.description}
                        </Span>
                    </QuestionOptionsContainer>
                );
            }),
        [data, handleAnswer, isSelected]
    );

    return (
        <Container>
            <Span className="empty-suitability-questionnaire-title-question">
                {data?.description}
            </Span>
            {answerOptions}
        </Container>
    );
};
