import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import {
  useCreateSocialSecurity,
  useUpdateSocialSecurity,
} from 'hooks/useSocialSecurity';
import { useRetirementOverview } from 'hooks/useRetirement';

export const EditSocialSecurity = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  theme = 'receipts',
  reloadSocialSecurity = () => {},
}) => {
  const { createSocialSecurity } = useCreateSocialSecurity();
  const { updateSocialSecurity } = useUpdateSocialSecurity(selectedItem.id);
  const { reloadRetirementOverview } = useRetirementOverview();
  const [name, setName] = useState(selectedItem?.name);
  const [analysisDate, setAnalysisDate] = useState(
    new Date(selectedItem?.analysis_date.raw)
  );
  const [expectedRetirementDate, setExpectedRetirementDate] = useState(
    new Date(selectedItem?.expected_retirement_date.raw)
  );
  const [investmentValue, setInvestmentValue] = useState(
    selectedItem?.investment_value.raw
  );
  const [monthlyContribution, setMonthlyContribution] = useState(
    selectedItem?.monthly_contribution.raw
  );
  const [
    initialCurrentMonthlyIncome,
    setInitialCurrentMonthlyIncome,
  ] = useState(selectedItem?.initial_current_monthly_income.raw);
  const [retirementIncome, setRetirementIncome] = useState(
    selectedItem?.retirement_income.raw
  );

  const confirm = async () => {
    if (selectedItem.id === -1) {
      const desiredPosition = selectedItem.position;
      await createSocialSecurity(
        {
          name,
          analysis_date: analysisDate,
          expected_retirement_date: expectedRetirementDate,
          investment_value: investmentValue,
          monthly_contribution: monthlyContribution,
          initial_current_monthly_income: initialCurrentMonthlyIncome,
          retirement_income: retirementIncome,
          position: desiredPosition,
        },
        setShowModal
      );
      reloadSocialSecurity();
      reloadRetirementOverview();
      return;
    }
    if (selectedItem.id !== -1) {
      const desiredPosition = selectedItem.position;
      await updateSocialSecurity(
        selectedItem.id,
        {
          name,
          analysis_date: analysisDate,
          expected_retirement_date: expectedRetirementDate,
          investment_value: investmentValue,
          monthly_contribution: monthlyContribution,
          initial_current_monthly_income: initialCurrentMonthlyIncome,
          retirement_income: retirementIncome,
          position: desiredPosition,
        },
        setShowModal
      );
      reloadSocialSecurity();
      reloadRetirementOverview();
      return;
    }
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Previdência Social"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer className="scrollable">
        <Modal.InputText
          className="generic-dream-modal"
          label="Nome "
          getInputValue={setName}
          defaultValue={name}
        />

        <Modal.DatePicker
          className="generic-dream-modal-datepicker after full-width"
          label="Data da Análise"
          defaultValue={analysisDate}
          getInputValue={setAnalysisDate}
        />
        <Modal.DatePicker
          className="generic-dream-modal-datepicker after full-width"
          label="Data Prevista de Aposentadoria"
          defaultValue={expectedRetirementDate}
          getInputValue={setExpectedRetirementDate}
        />
        <Modal.InputCurrency
          className="generic-dream-modal after"
          label="Valor à Investir "
          defaultValue={investmentValue}
          getInputValue={setInvestmentValue}
        />

        <Modal.InputCurrency
          className="generic-dream-modal after "
          label="Aporte Mensal "
          getInputValue={setMonthlyContribution}
          defaultValue={monthlyContribution}
        />
        <Modal.InputCurrency
          className="generic-dream-modal after"
          label="R.M.I Atual"
          getInputValue={setInitialCurrentMonthlyIncome}
          defaultValue={initialCurrentMonthlyIncome}
        />
        <Modal.InputCurrency
          className="generic-dream-modal after"
          label="Renda na Aposentadoria"
          getInputValue={setRetirementIncome}
          defaultValue={retirementIncome}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={() => setShowModal(false)}
        className={`simple-modal ${theme}`}
        confirmButtonText="Concluído"
        cancelButtonText="Cancelar"
        buttonOrder={['cancel', 'confirm']}
      />
    </Modal.Container>
  );
};
