import React, { useState } from 'react';
import { CardContainer, Home } from './styles';
import DirecionalButton from 'components/organisms/Direcional';
import { Active } from 'components/organisms/PatrimonyMapCard/Active';
import { Passive } from 'components/organisms/PatrimonyMapCard/Passive';
import { LiquidPatrimony } from 'components/organisms/PatrimonyMapCard/LiquidPatrimony';
import { CoverageIndex } from 'components/organisms/PatrimonyMapCard/CoverageIndex';
import { PatrimonyMapTable } from 'components/organisms/PatrimonyMapTable';
import { usePatrimony } from 'hooks/usePatrimony';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

export default function Map() {
  const {
    loading,
    overview,
    activePatrimonies,
    passivePatrimonies,
    createActive,
    updateActive,
    deleteActive,
    fetchData,
    createPassive,
    updatePassive,
    deletePassive,
    setActivePatrimonies,
  } = usePatrimony();

  const location = useLocation();
  const getStartPage = () => {
    const params = new URLSearchParams(location.search);
    const startIn = params.get('startIn');

    if (startIn === 'passive') {
      return 'passive';
    }

    return 'active';
  };
  const [currentPage, setCurrentPage] = useState(getStartPage());

  const activeVaule = overview?.total_active;
  const passiveVaule = overview?.passive_patrimonies;
  const liquidyValue = overview?.liquid_patrimony;
  const indexValue = overview?.coverage_index;

  const textEquity =
    'São dívidas que tem algum bem ou propriedade como garantia. Ex.: Financiamentos de imóvel ou veículo e consórcios';
  const textFinancial =
    'É a parte do seu patrimônio que está na forma de investimentos financeiros.';
  const textMaterial =
    'São os bens e propriedades que podem ser vendidos e possuem valor mensurável. Ex.: Imóvel e veículo';
  const textNotEquity =
    'São dívidas que não possuem nenhum bem ou propriedade como garantia. Ex.: empréstimos e consignados';
  const textCoverage =
    'O índice de cobertura é a razão entre as reservas financeiras de liquidez sobre as despesas mensais. Ele mede a quantidade de tempo em que será possível manter o custo de vida familiar com os ativos de alta liquidez';
  const textPatrimony =
    'Resultado do total de ativos(bens, dinheiro investido, imóveis, etc) de uma pessoa ou família, descontados os passivos(dívidas, empréstimos, financiamentos,e outras obrigações).';

  const [isProcessing, setIsProcessing] = useState(false);
  const [privateProcessing, setPrivateProcessing] = useState(false);

  const handleToggleStatus = async (id, currentSealable) => {
    if (isProcessing) return;

    const toastLoading = toast.loading('Atualizando Ativo...');
    setIsProcessing(true);

    try {
      setActivePatrimonies(prev => {
        if (typeof prev !== 'object' || prev === null) return prev;
        const updatedPatrimonies = { ...prev };
        if (updatedPatrimonies[id]) {
          updatedPatrimonies[id].sealable = !currentSealable;
        }
        return updatedPatrimonies;
      });

      const updatedData = {
        sealable: !currentSealable,
      };

      await updateActive(id, updatedData);
      toast.success('Ativo atualizado com sucesso!', {
        id: toastLoading,
      });
    } catch (error) {
      console.error(error);
      toast.error('Erro ao atualizar o ativo.', {
        id: toastLoading,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleToggleStatusPension = async (id, currentPension) => {
    if (privateProcessing) return;

    const toastLoading = toast.loading('Atualizando Ativo...');
    setPrivateProcessing(true);

    try {
      setActivePatrimonies(prev => {
        if (typeof prev !== 'object' || prev === null) return prev;

        const updatedPatrimonies = { ...prev };
        if (updatedPatrimonies[id]) {
          updatedPatrimonies[id].private_pension = !currentPension;
        }
        return updatedPatrimonies;
      });

      const updatedData = {
        privatePension: !currentPension,
      };

      await updateActive(id, updatedData);

      toast.success('Ativo atualizado com sucesso!', {
        id: toastLoading,
      });
    } catch (error) {
      console.error(error);
      toast.error('Erro ao atualizar o ativo.', {
        id: toastLoading,
      });
    } finally {
      setPrivateProcessing(false);
    }
  };

  const handleCreatePassive = async data => {
    try {
      const response = await createPassive(data);
      await fetchData();
      toast.success('Passivo criado!');
      return response;
    } catch (error) {
      toast.error('Erro ao criar seu passivo');
      throw error;
    }
  };

  const handleUpdatePassive = async (id, data) => {
    try {
      const response = await updatePassive(id, data);
      await fetchData();
      toast.success('Passivo editado!');
      return response;
    } catch (error) {
      toast.error('Erro ao  atualizar seu passivo');
      throw error;
    }
  };

  const handleDeletePassive = async ids => {
    const toastLoading = toast.loading('Excluindo passivo...');

    try {
      await Promise.all(ids.map(id => deletePassive(id)));
      await fetchData();

      toast.success('Passivo excluído com sucesso!', {
        id: toastLoading,
      });
    } catch (error) {
      console.error('Erro ao excluir passivo:', error);
      toast.error('Erro ao excluir passivo.', {
        id: toastLoading,
      });
    }
  };

  const handleCreateActive = async data => {
    try {
      const response = await createActive(data);
      await fetchData();
      toast.success('Ativo criado!');
      return response;
    } catch (error) {
      toast.error('Erro ao criar seu ativo');
      throw error;
    }
  };

  const handleUpdateActive = async (id, data) => {
    try {
      const response = await updateActive(id, data);
      await fetchData();
      toast.success('Ativo editado!');
      return response;
    } catch (error) {
      toast.error('Erro ao editar seu ativo');
      throw error;
    }
  };

  const handleDeleteActive = async ids => {
    const toastLoading = toast.loading('Excluindo ativos...');

    try {
      await Promise.all(ids.map(id => deleteActive(id)));
      await fetchData();

      toast.success('Ativos excluídos com sucesso!', {
        id: toastLoading,
      });
    } catch (error) {
      console.error('Erro ao excluir ativos:', error);
      toast.error('Erro ao excluir ativos.', {
        id: toastLoading,
      });
    }
  };
  const onQuit = async (id, data, isSettled) => {
    try {
      const updatedData = {
        ...data,
        settled_at: isSettled ? null : new Date().toISOString(),
      };

      await updatePassive(id, updatedData);
      await fetchData();
    } catch (error) {
      console.error(error);
      toast.error('Erro ao processar a quitação do passivo financeiro.');
    }
  };

  return (
    <>
      <Home>
        <CardContainer>
          <Active
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalValue={activeVaule}
            loading={loading}
          />
          <Passive
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalValue={passiveVaule}
            loading={loading}
          />
          <LiquidPatrimony
            totalValue={liquidyValue}
            loading={loading}
            text={textPatrimony}
          />
          <CoverageIndex
            totalValue={indexValue}
            text={textCoverage}
            loading={loading}
          />
        </CardContainer>

        <PatrimonyMapTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          materialAssets={activePatrimonies.activePatrimonial}
          financialAssets={activePatrimonies.activeFinancial}
          equityPassive={passivePatrimonies.passivePatrimonial}
          noEquityPassive={passivePatrimonies.passiveFinancial}
          textEquity={textEquity}
          textFinancial={textFinancial}
          textMaterial={textMaterial}
          textNotEquity={textNotEquity}
          loading={loading}
          reloadPage={fetchData}
          onCreateActive={handleCreateActive}
          onUpdateActive={handleUpdateActive}
          handleToggleStatus={handleToggleStatus}
          handleDeleteActive={handleDeleteActive}
          onCreatePassive={handleCreatePassive}
          onUpdatePassive={handleUpdatePassive}
          handleToggleStatusPension={handleToggleStatusPension}
          handleDeletePassive={handleDeletePassive}
          onQuit={onQuit}
          isProcessing={isProcessing}
          privateProcessing={privateProcessing}
        />
        <DirecionalButton />
      </Home>
    </>
  );
}
