import styled from 'styled-components';

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

  &.retirement {
    background-color: ${({ theme }) => theme.blueRoyal};
    tr:first-child th:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 4px;
      xborder-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;
