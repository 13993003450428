export const getEmpytRetirementOverview = () => {
  return {
    social_security: {
      label: 'Renda Prevista',
      value: {
        formatted: 'Em Análise',
        raw: 'em_analise',
      },
    },
    rental: {
      label: 'Recebimento médio mensal',
      value: {
        raw: 0,
        formatted: 'R$ 0',
      },
    },
    private_pension_rent: {
      label: 'Recebimento médio mensal',
      value: {
        raw: 0,
        formatted: 'R$ 0',
      },
    },
    progress: {
      value: {
        raw: 0,
        formatted: '0%',
      },
    },
  };
};
