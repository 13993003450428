import React, { useEffect, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { getMonthNumber, getMonths, monthNumberToName } from 'utils/date';

function getInitialMaturity(selectedItem, type) {
  if (type === 'edit' && selectedItem.classification.toLocaleLowerCase() === 'eventual comprometido') {
    return monthNumberToName(selectedItem?.maturity || new Date().getMonth() + 1);
  }

  return selectedItem?.maturity
}

export const EditRevenueItemModal = ({
  showModal = false,
  setShowModal = () => { },
  icon = 'edit',
  title = 'Editar item',
  selectedItem,
  onConfirm = () => { },
  onCancel = () => { },
  theme = 'edit',
  classificationOptions = [],
  categoryClassification,
  selectedCategory = null,
}) => {
  const [maturity, setMaturity] = useState(getInitialMaturity(selectedItem, icon));
  const [recurrent, setRecurrent] = useState(selectedItem?.recurrent);
  const [name, setName] = useState(selectedItem?.name);
  const [classification, setClassification] = useState(selectedItem?.classification);
  const [value, setValue] = useState(selectedItem?.value || 0);

  const additionalSettings = {
    'mensal comprometido': (
      <>
        <Modal.InputNumber
          className="simple-modal after min-width lowest-input"
          placeholder=""
          label="Vencimento"
          getInputValue={setMaturity}
          defaultValue={maturity}
          dayOfMonth={true}
        />
        <Modal.InputCurrency
          className="simple-modal after min-width"
          placeholder=""
          label="Valor mensal"
          getInputValue={setValue}
          defaultValue={value}
        />
        <Modal.Switch
          label="Recorrente"
          className="simple-modal after"
          getSwitchValue={setRecurrent}
          defaultValue={recurrent}
        />
      </>
    ),
    'mensal flexível': (
      <Modal.InputCurrency
        className="simple-modal after only-it"
        placeholder=""
        label="Valor mensal"
        getInputValue={setValue}
        defaultValue={value}
      />
    ),
    'eventual comprometido': (
      <>
        <Modal.Dropdown
          className="simple-modal at-end min-size eventual-committed-maturity"
          placeholder=""
          label="Vencimento"
          getDropdownValue={setMaturity}
          selectOptions={getMonths()}
          placeholderSelect={maturity}
        />
        <Modal.InputCurrency
          className="simple-modal after min-width eventual-committed-annual-value"
          placeholder=""
          label="Valor anual"
          getInputValue={setValue}
          defaultValue={value}
        />
      </>
    ),
    'eventual flexível': (
      <Modal.InputCurrency
        className="simple-modal after only-it"
        placeholder=""
        label="Valor anual"
        getInputValue={setValue}
        defaultValue={value}
      />
    )
  };

  const returnAdditionalSettings = () => {
    let base = String(classification).toLocaleLowerCase();
    return additionalSettings[base] || <></>;
  };

  const cancel = () => {
    const getFormattedMaturity = () => {
      if (classification === 'Eventual Comprometido') {
        return getMonthNumber(maturity)
      }

      if (['Eventual Flexível', 'Mensal Flexível'].includes(classification)) {
        return null
      }

      return Number(maturity)
    }

    const data = {
      id: selectedItem?.recipeId,
      name,
      classification,
      value,
      maturity: getFormattedMaturity(),
      when: getFormattedMaturity(),
      recurrent: recurrent,
      categoryId: selectedCategory
    }

    onCancel(data);
  }

  const changeClassification = (value) => {
    setClassification(value);
    setMaturity(null);
  }

  const confirm = () => {
    onConfirm(selectedItem.recipeId)
  }

  useEffect(() => {
    if (classification !== selectedItem?.classification) {
      setMaturity(null);
      setRecurrent(false);
    }
    // eslint-disable-next-line
  }, [classification])

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          placeholder=""
          label="Nome do item"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={classificationOptions}
          getDropdownValue={changeClassification}
          label="Classificação"
          placeholderSelect={classification}
        />
        <Modal.GroupInputs show={classification !== ''}>
          {returnAdditionalSettings()}
        </Modal.GroupInputs>
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onCancel={() => cancel()}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir item"
        cancelButtonText="Concluído"
        onConfirm={() => confirm()}
        confirmButtonColor='red'
        cancelButtonColor='blue'
      />
    </Modal.Container>
  );
};
