import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background-color: ${props => props.theme.white};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    padding: 45px;
`;

export const ScrollContainer = styled.div`
    height: max-content;
    gap: 32px;
    margin: 32px 0;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: min-content;
    overflow: auto;

    &.button {
        min-width: 0;
    }
`;

export const TitleContainer = styled.div`
    height: min-content;
`;

export const IntroductionContainer = styled.div`
    border: 1px solid ${props => props.theme.lilca};
    border-radius: 16px;
    width: 612px;
    height: max-content;
    padding: 54px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
`;
